/**
 * configCtrl - controller
 */
function configCtrl($rootScope, $scope, $cookies, $uibModal) {

    // This will hold loggedIn user information & would be available globally.
    if ($cookies.get("authUser")) {
        $scope.Authuser = JSON.parse($cookies.get("authUser"));
    }


   

    $rootScope.BusinessUnitProcessingDate = function (id) {
        
        $rootScope.showLoader(true);

        $uibModal.open({
            templateUrl: "modules/resources/templates/payment_date_modal_template.html",
            controller: [
                '$scope', '$uibModalInstance', 'payment_date', 'commonFactory', 'configFactory', 'Notification','apiUrl','$http',
                function (
                    $scope, $uibModalInstance, payment_date, commonFactory, configFactory, Notification,apiUrl, $http
                ){

                    $rootScope.showLoader(false);
                    $scope.uibModalInstanceBusinessUnitProcessingDate = $uibModalInstance;
                    $scope.constants = configFactory.configuration.payment_date;
                    $scope.configuration = {};
                    $scope.configuration.payment_date =  moment().format('MM/DD/YYYY');
                    commonFactory.get('/api/businessunit/' + id + '/edit')
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200 && response.result && response.result.business_unit_detail) {
                            $scope.business_unit_detail =  response.result.business_unit_detail;
                            if( response.result.business_unit_detail.pay_date){
                            $scope.configuration.payment_date = response.result.business_unit_detail.pay_date
                            }else{
                            // $scope.configuration.payment_date = response.result.business_unit_detail.pay_date
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
                    // Set the blank object
                   
                    // $scope.payment_date = payment_date[$scope.constants.key];
                    $scope.modalClose = function () {
                        $uibModalInstance.close();
                    }
                    // Save the Changes of Process Date
                    $scope.saveProcessDate = function (valid) {
                    $scope.formData = {};
                    // $scope.formData[$scope.constants.key] = $scope.payment_date;
                    console.log($scope.configuration.payment_date);
                    $scope.formData[$scope.constants.key] = moment($scope.configuration.payment_date).format('MM/DD/YYYY');

                    if (valid) {

                        $rootScope.showLoader(true);
                        
                        // var data = { configuration : $scope.formData };
                        
                        commonFactory.put('/api/businessunit/' + id, $scope.formData)
                        .success(function(response)  {

                            $rootScope.showLoader(false);
                            $uibModalInstance.close();
                           
                            $rootScope.BusinessUnitList();
                            Notification.success( "Your configuration has been saved.");
                           

                        }, function (error) {
                            Notification.error( "Something went wrong.");
                            $rootScope.showLoader(false);

                        });
                    }

                };
            }],
            resolve: {
                payment_date: ['configFactory', function (configFactory) {
                    var data = [configFactory.configuration.payment_date.key];
                    return configFactory.getConfiguration({ keys: data }).then(function (response) {
                        return response.data.configurations;
                    });
                }]
            },
            keyboard: false,
            backdrop: false
        });
    };
    $rootScope.BusinessUnitList = function(){
        $rootScope.showLoader(true);
        $uibModal.open({
            templateUrl: "modules/resources/templates/business_unit_list.html",
            controller: ['$scope', '$uibModalInstance', 'Notification','apiUrl','$http', function ($scope, $uibModalInstance, Notification, apiUrl, $http) {

               
                $scope.uibModalInstance = $uibModalInstance;
               if($rootScope.saveProcessDateUpdate){
                $uibModalInstance.close();
                $rootScope.saveProcessDateUpdate = false ;
               }

                $http.get(apiUrl+"/api/getBusinessUnit").success(function(response){
                    $rootScope.showLoader(false);
                    console.log(response);
                    $scope.BusinessUnitsList = response.result.data;  
                });

            }],
           
            keyboard: false,
            backdrop: false,
           
        });
    }
    $scope.paymentPasswordSetting = function(){
        $rootScope.showLoader(true);

        $uibModal.open({
            templateUrl: "modules/resources/templates/payment_password_template.html",
            controller: [
                '$scope', '$uibModalInstance', 'configFactory', 'Notification',
                function ($scope, $uibModalInstance, configFactory, Notification
                ){

                    $rootScope.showLoader(false);
                    $scope.uibModalInstance = $uibModalInstance;

                    $scope.formData = {};
                    $scope.savePassword = function (valid) {

                        if (valid) {

                            $rootScope.showLoader(true);

                            configFactory.updatepaymentPassword($scope.formData).then(function (response) {

                                $rootScope.showLoader(false);
                                if(response.data.error == true){
                                    Notification.error(response.data.result.message || "Something went wrong.");
                                }else{
                                    $uibModalInstance.close(response);
                                    Notification.success(response.data.message || "Your password has been updated.");
                                }

                            }, function (error) {
                                Notification.error(error.data.message || "Something went wrong.");
                                $rootScope.showLoader(false);
                            });
                        }

                    };

            }],
            resolve: {
                // payment_date: ['configFactory', function (configFactory) {
                //     var data = [configFactory.configuration.payment_password.key];
                //     return configFactory.getConfiguration({ keys: data }).then(function (response) {
                //         return response.data.configurations;
                //     });
                // }]
            },
            keyboard: false,
            backdrop: false
        });
    };

    $scope.settingRoutingNumber = function () {

        $rootScope.showLoader(true);

        $uibModal.open({
            templateUrl: "modules/resources/templates/routing_number_template.html",
            controller: [
                '$scope', '$uibModalInstance', 'configData', 'configFactory', 'Notification','SweetAlert','commonFactory',
                function (
                    $scope, $uibModalInstance, configData, configFactory, Notification,SweetAlert, commonFactory
                ){

                    $rootScope.showLoader(false);
                    $scope.uibModalInstance = $uibModalInstance;
                    $scope.account_number_locked = true;
                    $scope.constants = configFactory.configuration.routing_number;

                    $scope.formData = {};
                    $scope.formData = configData;
                    $scope.formData.pay_stub = configData.pay_stub == 'Yes' ? true : false;

                    $scope.updateRoutingNumber = function (valid) {

                        if (valid) {
                            $rootScope.showLoader(true);
                            var data = { configuration : $scope.formData };
                            data.configuration.pay_stub = $scope.formData.pay_stub == true ? 'Yes':'No';
                            configFactory.saveConfiguration(data).then(function (response) {

                                $rootScope.showLoader(false);
                                $uibModalInstance.close(response);
                                Notification.success(response.data.message || "Your Routing Number has been udated.");

                            }, function (error) {
                                Notification.error(error.data.message || "Something went wrong.");
                                $rootScope.showLoader(false);

                            });
                        }

                    };

                    $scope.unlockAccountNumber = function(){

                        // If already unlock then, just lock that.
                        if(!$scope.account_number_locked){
                            $scope.account_number_locked = true;
                            return;
                        }

                        SweetAlert.swal({
                            title: "Unlock Account Number",
                            text: "Please enter password to change the acount number and routing number.",
                            type: "input",
                            inputType: "password",
                            showCancelButton: true,
                            closeOnConfirm: true
                            // inputPlaceholder: "password"
                        }, function(inputValue) {
                            if (inputValue === false) return false;
                            if (inputValue === "") {
                                swal.showInputError("You need to write something!");
                                return false
                            }
                            else {
                                $rootScope.showLoader(true);
                                commonFactory.post('/api/unlock-check-number', { 'password': inputValue })
                                    .success(function(response) {
                                        if (response['result']) {
                                            if (response['result']['auth']) {
                                                // SweetAlert.swal("Nice !", "You are authenticated user !", "success");
                                                $scope.account_number_locked = false;
                                                $rootScope.showLoader(false);
                                            } else {
                                                $rootScope.showLoader(false);
                                                SweetAlert.swal("Error !", "Wrong Password!", "error");
                                            }
                                        } else {
                                            $rootScope.showLoader(false);
                                            SweetAlert.swal("Oops !", "Something went wrong!", "error");
                                        }
                                    })
                                    .error(function(err) {
                                        $rootScope.showLoader(false);
                                        SweetAlert.swal("Oops !", "Something went wrong!", "error");
                                    });

                            }
                            // swal("Nice!", "You wrote: " + inputValue, "success");
                        });
                    };

            }],
            resolve: {
                configData: ['configFactory', function (configFactory) {
                    var data = [configFactory.configuration.routing_number.key, configFactory.configuration.account_number.key,'pay_stub'];
                    return configFactory.getConfiguration({ keys: data }).then(function (response) {
                        return response.data.configurations;
                    });
                }],
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            },
            keyboard: false,
            backdrop: false
        });
    };

    $scope.cashDoneForDay = function () {

        $rootScope.showLoader(true);

        $uibModal.open({
            templateUrl: "modules/resources/templates/cash_done_for_day.html",
            controller: [
                '$scope', '$uibModalInstance', 'configurations', 'configFactory', 'Notification',
                function (
                    $scope, $uibModalInstance, configurations, configFactory, Notification
                ){


                    $rootScope.showLoader(false);
                    $scope.uibModalInstance = $uibModalInstance;

                    // Set the blank object
                    $scope.configuration = configurations;

                    // Save the Changes of Close Date
                    $scope.saveCashCloseDate = function (valid) {

                        $scope.formData = {};
                        $scope.formData['cash_close_date'] = moment($scope.configuration.cash_close_date).format('MM/DD/YYYY');

                        if (valid) {

                            $rootScope.showLoader(true);

                            var data = { configuration : $scope.formData };

                            configFactory.saveConfiguration(data).then(function (response) {

                                $rootScope.showLoader(false);
                                $uibModalInstance.close(response);
                                Notification.success("Close date has been set.");

                            }, function (error) {
                                Notification.error(error.data.message || "Something went wrong.");
                                $rootScope.showLoader(false);
                            });
                        }

                    };
            }],
            resolve: {
                configurations: ['configFactory', function (configFactory) {
                    var data = [configFactory.configuration.cash_close_date.key];
                    return configFactory.getConfiguration({ keys: data }).then(function (response) {
                        return response.data.configurations;
                    });
                }]
            },
            keyboard: false,
            backdrop: false
        });
    };

    $scope.cashReceiptJournalForDay = function(){
        $rootScope.showLoader(true);

        $uibModal.open({
            templateUrl: "modules/resources/templates/cash_journal_date_report.html",
            controller: ['$scope', '$uibModalInstance', 'Notification','apiUrl','$window','$http', function ($scope, $uibModalInstance, Notification,apiUrl,$window,$http) {


                $rootScope.showLoader(false);
                $scope.uibModalInstance = $uibModalInstance;

                // Set the blank object
                $scope.configuration = {
                    cash_report_from_date : moment(),
                    cash_report_to_date : moment(),
                    report_date_type:'cashed_date',
                    paidBy: []
                };

                // fetch applied by user
                $scope.loadPaidByOptions = function (val) {
                    return $http.post(apiUrl + '/api/search-applied-by-user', {query_string: val})
                        .then(function (response) {
                            if (response.data && response.data.status === 200) {
                               return response.data.result.map(function(item) {
                                   return item;
                                });
                                
                            } 
                        })
                        .catch(function (error) {
                            console.error('Error fetching paid by options:', error);
                            return [];
                        });
                };
                
                $scope.randomPayer = [];
                // Save the Changes of Close Date
                $scope.saveCashCloseDate = function (valid) {
                   
                    var fromDate = moment($scope.configuration.cash_report_from_date).format('YYYY-MM-DD');
                    var toDate = moment($scope.configuration.cash_report_to_date).format('YYYY-MM-DD');
                    var report_date_type = $scope.configuration.report_date_type;
                    $scope.paidById = [];
                    
                    $scope.configuration.paidBy.map(function(item) {
                        if(typeof item.id === 'number'){
                           $scope.paidById.push(item.id); 
                        }else{
                            $scope.randomPayer.push(item.name);
                        }
                        return item.id;
                    });

                    var paidBy = $scope.paidById;
                    var randomPayer = $scope.randomPayer;
                
                    $window.open(apiUrl+'/api/cash-receipt-journal-report?from='+fromDate+"&to="+toDate+"&report_date_type="+report_date_type+'&applied_by=' + paidBy,'_blank');

                };

            }],
            keyboard: false,
            backdrop: false,
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        }
                    ]);
                }
            }
        });
    };

    $scope.privateInfoPasswordSetting = function(){
        $rootScope.showLoader(true);

        $uibModal.open({
            templateUrl: "modules/resources/templates/change_private_info_password.html",
            controller: [
                '$scope', '$uibModalInstance', 'commonFactory', 'Notification',
                function ($scope, $uibModalInstance, commonFactory, Notification
                ){

                    $rootScope.showLoader(false);
                    $scope.uibModalInstance = $uibModalInstance;

                    $scope.formData = {};
                    $scope.savePassword = function (valid) {

                        if (valid) {

                            $rootScope.showLoader(true);

                            commonFactory.post('/api/config/update-private-info-password',$scope.formData).then(function (response) {

                                $rootScope.showLoader(false);
                                if(response.data.error == true){
                                    Notification.error(response.data.result.message || "Something went wrong.");
                                }else{
                                    $uibModalInstance.close(response);
                                    Notification.success(response.data.message || "Your password has been updated.");
                                }

                            }, function (error) {
                                Notification.error(error.data.message || "Something went wrong.");
                                $rootScope.showLoader(false);
                            });
                        }

                    };

            }],
            resolve: {
            },
            keyboard: false,
            backdrop: false
        });
    };

    $scope.settingMobileNumber = function(){     
        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: "modules/resources/templates/add_mobile_number.html",
            controller: ['$scope', '$uibModalInstance', 'configFactory', 'Notification','mobile_numbers',
                function ($scope, $uibModalInstance, configFactory, Notification,mobile_numbers
                ){ 
                    $scope.mobile_number_fields = [{}];
                    $scope.isSubmitted = false;

                    if(mobile_numbers){
                        $scope.mobile_numbers = mobile_numbers.notification_mobile_numbers.split(',');
                        $scope.mobile_number_fields = angular.copy($scope.mobile_numbers);
                    }else{
                         $scope.mobile_numbers = [];
                    }

                    $scope.closeModal = function(){

                        $uibModalInstance.close();
                    
                    }
                    $scope.add_mobile_number = function(){

                        $scope.mobile_number_fields.push({});

                    }
                    $scope.remove_mobile_number = function(index){
                        
                        $scope.mobile_number_fields.splice(index, 1);
                        var i = 0; 
                        var reindex_mobile_numbers = {};
                        angular.forEach($scope.mobile_numbers,function(value, key){
                            if(key != index){  
                                reindex_mobile_numbers[i] = value;
                                i++;
                            }
                            
                        });
                        $scope.mobile_numbers = reindex_mobile_numbers;
                    
                    }
                    $scope.save = function(isValid){   
                        $scope.isSubmitted = true;
                        if(isValid){
                            $rootScope.showLoader(true);
                            configFactory.saveMobileNumbers($scope.mobile_numbers).then(function (response) {
                                $rootScope.showLoader(false);
                                if(response.error == true){
                                    Notification.error(response.data.result.message);
                                }else{
                                    Notification.success(response.data.result.message);
                                    $uibModalInstance.close();
                                }

                            },function (error) {
                                Notification.error(error || "Something went wrong.");
                                $rootScope.showLoader(false);
                            });

                        }
                    
                    }
 

                }],
                resolve: {
                    mobile_numbers: ['configFactory', function (configFactory) {
                        
                        return configFactory.getConfiguration({ keys: ['notification_mobile_numbers'] }).then(function (response) {
                            return response.data.configurations;
                        });
                    }]
                },
                keyboard: false,
                backdrop: false
            });

        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    };

    $scope.downloadAutoInvoicePayments = function(){
        $rootScope.showLoader(true);
        $uibModal.open({
            templateUrl: "modules/resources/templates/download_auto_invoice_payments.html",
            controller: ['$scope', '$uibModalInstance', 'Notification','apiUrl','$http', function ($scope, $uibModalInstance, Notification, apiUrl, $http) {

                $rootScope.showLoader(false);
                $scope.uibModalInstance = $uibModalInstance;
                $scope.url = apiUrl;
                $scope.links = [];

                // Set the blank object
                $scope.configuration = {
                    invoice_payments_from_date : moment(),
                    invoice_payments_to_date : moment(),
                    report_date_type:'cashed_date'

                };

                $scope.getAutoInvoicePayments = function (valid) {
                    $rootScope.showLoader(true);
                    $scope.links = [];
                    var fromDate = moment($scope.configuration.invoice_payments_from_date).format('YYYY-MM-DD');
                    var toDate = moment($scope.configuration.invoice_payments_to_date).format('YYYY-MM-DD');
                    var report_date_type = $scope.configuration.report_date_type;

                    $http.post(apiUrl+"/api/get-auto-invoice-payment-links",{fromDate : fromDate, toDate : toDate,report_date_type:report_date_type}).success(function(res){
                        $rootScope.showLoader(false);
                        if(res.error){
                            Notification.error(res.result.message);
                        }else{
                            $scope.links = res.result.links;
                        }
                    });
                };
                $scope.getAutoInvoicePayments();
                $scope.addEditNote = function(noteId){
                    $rootScope.showLoader(true);
                    modalInstance = $uibModal.open({
                        template:`
                                <div class="modal-header"> 
                                    <h4>Add/Edit Note for <a href="javascript:void(0);">{{linkData.filename}}</a> file <a class="pull-right" ng-click="uibModalInstance.dismiss('cancel');"><i class="fa fa-times"></i></a></h4>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-sm-12 "> 
                                            <textarea placeholder="Write here..!" class="form-control" name="note" ng-model="note"  style="height: 100px!important"></textarea>
                                        </div>
                                    </div> 
                                </div>
                                <div class="modal-footer text-right">
                                    <button type="button" class="btn btn-sm btn-default m-t-n-xs" ng-click="uibModalInstance.dismiss('cancel');">Cancel</button> &nbsp;
                                    <button class="btn btn-sm btn-primary m-t-n-xs" ng-click="save()"><strong>Save</strong></button>
                                </div> `,
                        controller: ['$scope', '$uibModalInstance', 'Notification','apiUrl','$http', 'noteId', function ($scope, $uibModalInstance, Notification, apiUrl, $http, noteId) {

                            $scope.uibModalInstance = $uibModalInstance;
                            $scope.note = '';

                            $http.post(apiUrl+"/api/get-auto-invoice-payment-note",{ id : noteId}).success(function(res){
                                $rootScope.showLoader(false);
                                if(res.error){
                                    Notification.error(res.result.message);
                                }else{
                                    $scope.linkData = res.result.data;
                                    $scope.note = $scope.linkData.note;
                                }
                            }); 
                            $scope.save = function(){
                                $rootScope.showLoader(true);
                                $http.post(apiUrl+"/api/save-auto-invoice-payment-note",{ id : $scope.linkData.id, note : $scope.note}).success(function(res){
                                    $rootScope.showLoader(false);
                                    if(res.error){
                                        Notification.error(res.result.message);
                                    }else{
                                        Notification.success(res.result.message);
                                        $uibModalInstance.close(true);
                                    }
                                }); 
                            }

                        }],
                        keyboard: false,
                        backdrop: false,
                        resolve : {
                            noteId : noteId
                        }
                    });

                    modalInstance.result.then(function (isChange) {
                
                        if(isChange){ 
                            $scope.getAutoInvoicePayments();      
                        }

                    });  
                }

            }],
            keyboard: false,
            backdrop: false,
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                }
            }
        });
    };

    $scope.changeElawCredentials = function(){
        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: "modules/resources/templates/change_elaw_credentials.html",
            controller: ['$scope', '$uibModalInstance', 'Notification', 'apiUrl', '$http', 'representativeFactory', 'configFactory', 'username', 'password', function ($scope, $uibModalInstance, Notification, apiUrl, $http, representativeFactory, configFactory, username, password) {

                $rootScope.showLoader(false);
                $scope.uibModalInstance = $uibModalInstance;
                $scope.validations = representativeFactory.validations;
                $scope.formData = {elaw_username : username.elaw_username, elaw_password : password.elaw_password};
                
                $scope.saveCredentials = function(isValid){   
                        
                    if(isValid){

                        $rootScope.showLoader(true);
                        configFactory.updateElawCredentials($scope.formData).then(function (response) {
                            
                            $rootScope.showLoader(false);
                            if(response.data.error == true){
                                Notification.error(response.data.result.message);
                            }else{
                                Notification.success(response.data.result.message);
                                $uibModalInstance.close();
                            }

                        },function (error) {
                            Notification.error(error || "Something went wrong.");
                            $rootScope.showLoader(false);
                        });

                    }
                    
                }
            }],
            resolve: {
                username: ['configFactory', function (configFactory) {
                    
                    return configFactory.getConfiguration({ keys: ['elaw_username'] }).then(function (response) {
                        return response.data.configurations;
                    });
                }],
                password: ['configFactory', function (configFactory) {
                    
                    return configFactory.getConfiguration({ keys: ['elaw_password'] }).then(function (response) {
                        return response.data.configurations;
                    });
                }],
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            },
            keyboard: false,
            backdrop: false
        });

        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    };

    $scope.manageTechniciansContacts = function(){
        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: "modules/resources/templates/manage_technicians_contacts.html",
            controller: ['$scope', '$uibModalInstance', 'Notification', 'apiUrl', '$http', 'configFactory', 'technician_cell', 'technician_email', 'exhibit_technician_cell', 'exhibit_technician_email', function ($scope, $uibModalInstance, Notification, apiUrl, $http, configFactory, technician_cell, technician_email, exhibit_technician_cell, exhibit_technician_email) {

                $rootScope.showLoader(false);
                $scope.isSubmitted = false;
                $scope.uibModalInstance = $uibModalInstance;
                $scope.formData = {
                        technician_cell : technician_cell.technician_cell, 
                        technician_email : technician_email.technician_email,
                        exhibit_technician_cell : exhibit_technician_cell.exhibit_technician_cell,
                        exhibit_technician_email : exhibit_technician_email.exhibit_technician_email
                    };
                
                $scope.saveContacts = function(isValid){
                    $scope.isSubmitted = true;
                    if(isValid){
                        $rootScope.showLoader(true);
                        configFactory.updateTechniciansContacts($scope.formData).then(function (response) {
                            
                            $rootScope.showLoader(false);
                            if(response.data.error == true){
                                Notification.error(response.data.result.message);
                            }else{
                                Notification.success(response.data.result.message);
                                $uibModalInstance.close();
                            }

                        },function (error) {
                            Notification.error(error || "Something went wrong.");
                            $rootScope.showLoader(false);
                        });

                    }
                }
            }],
            resolve: {
                technician_cell: ['configFactory', function (configFactory) {
                    
                    return configFactory.getConfiguration({ keys: ['technician_cell'] }).then(function (response) {
                        return response.data.configurations;
                    });
                }],
                technician_email: ['configFactory', function (configFactory) {
                    
                    return configFactory.getConfiguration({ keys: ['technician_email'] }).then(function (response) {
                        return response.data.configurations;
                    });
                }],
                exhibit_technician_cell: ['configFactory', function (configFactory) {
                    
                    return configFactory.getConfiguration({ keys: ['exhibit_technician_cell'] }).then(function (response) {
                        return response.data.configurations;
                    });
                }],
                exhibit_technician_email: ['configFactory', function (configFactory) {
                    
                    return configFactory.getConfiguration({ keys: ['exhibit_technician_email'] }).then(function (response) {
                        return response.data.configurations;
                    });
                }],
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            },
            keyboard: false,
            backdrop: false
        });

        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    };

    $scope.manageFailedPasswordNotification = function(){
        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: "modules/resources/templates/manage_failed_password_notification.html",
            controller: ['$scope', '$uibModalInstance', 'Notification', 'apiUrl', '$http', 'configFactory', 'failed_password_notification_email', function ($scope, $uibModalInstance, Notification, apiUrl, $http, configFactory, failed_password_notification_email) {

                $rootScope.showLoader(false);
                $scope.isSubmitted = false;
                $scope.uibModalInstance = $uibModalInstance;
                $scope.formData = {
                    failed_password_notification_email : failed_password_notification_email
                };
                
                $scope.save = function(isValid){
                    $scope.isSubmitted = true;
                    if(isValid){
                        $rootScope.showLoader(true);
                        configFactory.updateFailedPasswordNotificationEmail($scope.formData).then(function (response) {
                            
                            $rootScope.showLoader(false);
                            if(response.data.error == true){
                                Notification.error(response.data.result.message);
                            }else{
                                Notification.success(response.data.result.message);
                                $uibModalInstance.close();
                            }

                        },function (error) {
                            Notification.error(error || "Something went wrong.");
                            $rootScope.showLoader(false);
                        });

                    }
                }
            }],
            resolve: {
                failed_password_notification_email : ['configFactory', function (configFactory) {                
                    return configFactory.getConfiguration({ keys: ['failed_password_notification_email'] }).then(function (response) {
                        return response.data.configurations.failed_password_notification_email;
                    });
                }]
            },
            keyboard: false,
            backdrop: false
        });

        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    };

    $scope.manageArReportCronEmail = function(){
        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: "modules/resources/templates/manage_ar_report_cron_email.html",
            controller: ['$scope', '$uibModalInstance', 'Notification', 'apiUrl', '$http', 'configFactory', 'ar_report_cron_user_email', function ($scope, $uibModalInstance, Notification, apiUrl, $http, configFactory, ar_report_cron_user_email) {

                $rootScope.showLoader(false);
                $scope.isSubmitted = false;
                $scope.uibModalInstance = $uibModalInstance;
                $scope.formData = {
                    ar_report_cron_user_email : ar_report_cron_user_email
                };
                
                $scope.save = function(isValid){
                    $scope.isSubmitted = true;
                    if(isValid){
                        $rootScope.showLoader(true);
                        configFactory.updateArCronEmail($scope.formData).then(function (response) {
                            
                            $rootScope.showLoader(false);
                            if(response.data.error == true){
                                Notification.error(response.data.result.message);
                            }else{
                                Notification.success(response.data.result.message);
                                $uibModalInstance.close();
                            }

                        },function (error) {
                            Notification.error(error || "Something went wrong.");
                            $rootScope.showLoader(false);
                        });

                    }
                }
            }],
            resolve: {
                ar_report_cron_user_email : ['configFactory', function (configFactory) {                
                    return configFactory.getConfiguration({ keys: ['ar_report_cron_user_email'] }).then(function (response) {
                        return response.data.configurations.ar_report_cron_user_email;
                    });
                }]
            },
            keyboard: false,
            backdrop: false
        });

        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    };

    $scope.manageSalesManagerEmail = function(){
        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: "modules/resources/templates/manage_sales_manager_email.html",
            controller: ['$scope', '$uibModalInstance', 'Notification', 'apiUrl', '$http', 'configFactory', 'sales_manager_email', function ($scope, $uibModalInstance, Notification, apiUrl, $http, configFactory, sales_manager_email) {

                $rootScope.showLoader(false);
                $scope.isSubmitted = false;
                $scope.uibModalInstance = $uibModalInstance;
                $scope.formData = {
                    sales_manager_email : sales_manager_email
                };
                
                $scope.save = function(isValid){
                    $scope.isSubmitted = true;
                    if(isValid){
                        $rootScope.showLoader(true);
                        configFactory.updateSalesManagerEmail($scope.formData).then(function (response) {
                            
                            $rootScope.showLoader(false);
                            if(response.data.error == true){
                                Notification.error(response.data.result.message);
                            }else{
                                Notification.success(response.data.result.message);
                                $uibModalInstance.close();
                            }

                        },function (error) {
                            Notification.error(error || "Something went wrong.");
                            $rootScope.showLoader(false);
                        });

                    }
                }
            }],
            resolve: {
                sales_manager_email : ['configFactory', function (configFactory) {                
                    return configFactory.getConfiguration({ keys: ['sales_manager_email'] }).then(function (response) {
                        return response.data.configurations.sales_manager_email;
                    });
                }]
            },
            keyboard: false,
            backdrop: false
        });

        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    };
    $scope.manageZoomMasterEmail = function(){
        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: "modules/resources/templates/manage_zoom_master_email.html",
            controller: ['$scope', '$uibModalInstance', 'Notification', 'apiUrl', '$http', 'configFactory', 'zoom_master_account', function ($scope, $uibModalInstance, Notification, apiUrl, $http, configFactory, zoom_master_account) {

                $rootScope.showLoader(false);
                $scope.isSubmitted = false;
                $scope.uibModalInstance = $uibModalInstance;
                $scope.formData = {
                    zoom_master_account : zoom_master_account
                };
                
                $scope.save = function(isValid){
                    $scope.isSubmitted = true;
                    if(isValid){
                        $rootScope.showLoader(true);
                        configFactory.updateZoomMasterEmail($scope.formData).then(function (response) {
                            $rootScope.showLoader(false);
                            if(response.data.error == true){
                                Notification.error(response.data.result.message);
                            }else{
                                Notification.success(response.data.result.message);
                                $uibModalInstance.close();
                            }

                        },function (error) {
                            Notification.error(error || "Something went wrong.");
                            $rootScope.showLoader(false);
                        });

                    }
                }
            }],
            resolve: {
                zoom_master_account : ['configFactory', function (configFactory) {                
                    return configFactory.getConfiguration({ keys: ['zoom_master_account'] }).then(function (response) {
                        return response.data.configurations.zoom_master_account;
                    });
                }]
            },
            keyboard: false,
            backdrop: false
        });

        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    };
    $scope.generateOktaToken = function (){
        swal({
            title: "Are you sure ?",
            html:true,
            text: "<small>This will generate a new Okta token - and will Invalidate the old one. <br> You will need record the new key, it will not be shown more than once. <br> You must re-enter your login password to generate the API key.</small>",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#09375f",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            closeOnConfirm: true,
            closeOnCancel: true
        },
        function (isConfirm) {
            if(isConfirm){
                $rootScope.showLoader(true);
                var modalInstance = $uibModal.open({
                    templateUrl: "modules/resources/templates/get_okta_api_token.html",
                    controller: ['$scope', '$uibModalInstance', 'Notification', 'apiUrl', '$http', 'configFactory', function ($scope, $uibModalInstance, Notification, apiUrl, $http, configFactory) {
        
                        $rootScope.showLoader(false);
                        $scope.isSubmitted = false;
                        $scope.uibModalInstance = $uibModalInstance;

                        
                        $scope.save = function(isValid){
                            $scope.isSubmitted = true;
                            if(isValid){
                                $rootScope.showLoader(true);
                               
                                configFactory.getOktaApiToken($scope.formData).then(function (response) {
                                    $rootScope.showLoader(false);
                                    if(response.data.error == true){
                                        Notification.error(response.data.result.message);
                                    }else{
                                        Notification.success(response.data.result.message);
                                        $('#okta_api_token').text(response.data.result.token)
                                        $('#api_token_div').show()
                                       // $uibModalInstance.close();
                                    }
        
                                },function (error) {
                                    Notification.error(error || "Something went wrong.");
                                    $rootScope.showLoader(false);
                                });
        
                            }
                        }
                    }],
                    keyboard: false,
                    backdrop: false
                });
        
            }
        });
    }
    $scope.manageCostPercentage = function(){
        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: "modules/resources/templates/manage_cost_percentage.html",
            controller: ['$scope', '$uibModalInstance', 'Notification', 'apiUrl', '$http', 'configFactory', 'cost_percentage', function ($scope, $uibModalInstance, Notification, apiUrl, $http, configFactory, cost_percentage) {

                $rootScope.showLoader(false);
                $scope.isSubmitted = false;
                $scope.uibModalInstance = $uibModalInstance;
                $scope.formData = {
                    cost_percentage : cost_percentage
                };
                
                $scope.save = function(isValid){
                    $scope.isSubmitted = true;
                    if(isValid){
                        $rootScope.showLoader(true);
                        configFactory.updateCostPercentage($scope.formData).then(function (response) {
                            $rootScope.showLoader(false);
                            if(response.data.error == true){
                                Notification.error(response.data.result.message);
                            }else{
                                Notification.success(response.data.result.message);
                                $uibModalInstance.close();
                            }

                        },function (error) {
                            Notification.error(error || "Something went wrong.");
                            $rootScope.showLoader(false);
                        });

                    }
                }
            }],
            resolve: {
                cost_percentage : ['configFactory', function (configFactory) {                
                    return configFactory.getConfiguration({ keys: ['cost_percentage'] }).then(function (response) {
                        return response.data.configurations.cost_percentage;
                    });
                }]
            },
            keyboard: false,
            backdrop: false
        });

        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    };
    $scope.managePaymentMaintainMode = function(){
        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: "modules/resources/templates/manage_payment_maintenance_mode.html",
            controller: ['$scope', '$uibModalInstance', 'Notification', 'apiUrl', '$http', 'configFactory', 'payment_maintenance_mode', function ($scope, $uibModalInstance, Notification, apiUrl, $http, configFactory, payment_maintenance_mode) {

                $rootScope.showLoader(false);
                $scope.isSubmitted = false;
                $scope.uibModalInstance = $uibModalInstance;
                $scope.formData = {
                    payment_maintenance_mode : payment_maintenance_mode
                };

                $scope.save = function(isValid){
                    $scope.isSubmitted = true;

                    console.log($scope.formData)
                    if(isValid){
                        $rootScope.showLoader(true);
                        configFactory.updatePaymentMaintenanceMode($scope.formData).then(function (response) {
                            $rootScope.showLoader(false);
                            if(response.data.error == true){
                                Notification.error(response.data.result.message);
                            }else{
                                Notification.success(response.data.result.message);
                                $uibModalInstance.close();
                            }

                        },function (error) {
                            Notification.error(error || "Something went wrong.");
                            $rootScope.showLoader(false);
                        });

                    }
                }
            }],
            resolve: {
                payment_maintenance_mode : ['configFactory', function (configFactory) {
                    return configFactory.getConfiguration({ keys: ['payment_maintenance_mode'] }).then(function (response) {
                        return response.data.configurations.payment_maintenance_mode;
                    });
                }]
            },
            keyboard: false,
            backdrop: false
        });

        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    };

    $scope.manageLexitasOneNotice = function(){
        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: "modules/resources/templates/manage_lexitas_one_notice.html",
            controller: ['$scope', '$uibModalInstance', 'Notification', 'apiUrl', '$http', 'configFactory', 'lexitasone_notice', function ($scope, $uibModalInstance, Notification, apiUrl, $http, configFactory, lexitasone_notice) {

                $rootScope.showLoader(false);
                $scope.isSubmitted = false;
                $scope.uibModalInstance = $uibModalInstance;
                $scope.formData = {
                    lexitasone_notice : lexitasone_notice
                };

                $scope.save = function(isValid){
                    $scope.isSubmitted = true;
                    if(isValid){
                        $rootScope.showLoader(true);
                        configFactory.updateLexitasOneNotice($scope.formData).then(function (response) {
                            $rootScope.showLoader(false);
                            if(response.data.error == true){
                                Notification.error(response.data.result.message);
                            }else{
                                Notification.success(response.data.result.message);
                                $uibModalInstance.close();
                            }

                        },function (error) {
                            Notification.error(error || "Something went wrong.");
                            $rootScope.showLoader(false);
                        });

                    }
                }
            }],
            resolve: {
                lexitasone_notice : ['configFactory', function (configFactory) {
                    return configFactory.getConfiguration({ keys: ['lexitasone_notice'] }).then(function (response) {
                        return response.data.configurations.lexitasone_notice;
                    });
                }]
            },
            keyboard: false,
            backdrop: false
        });

        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    };

}

angular
    .module('deitz')
    .controller('configCtrl', configCtrl);

angular.module('deitz').controller('geicoInvoiceLogsController', [
    '$scope',
    '$rootScope',
    '$state',
    '$http', 'apiUrl',
    'commonFactory',
    'Notification',
    '$stateParams',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    '$q',
    '$uibModal',
    '$compile',
    function (
        $scope,
        $rootScope,
        $state,
        $http, apiUrl,
        commonFactory,
        Notification,
        $stateParams,
        DTOptionsBuilder,
        DTColumnBuilder,
        $q,
        $uibModal,
        $compile
    ) {
        $scope.statusFilter = JSON.parse(localStorage.getItem('statusFilter'));
        $scope.typeFilter = localStorage.getItem('typeFilter') != "undefined" ? localStorage.getItem('typeFilter') : null;

        $scope.dateRange
        $scope.filterDate
        $scope.errors = []
        $scope.automationStatus = false;

        $scope.copyDatas = function ($event) {
            navigator.clipboard.writeText($event.target.innerText)
            var table = $('#InvLogTable').DataTable();

            table.buttons.info('Copied !', $event.target.innerText, 3000);
            // $('#datatables_buttons_info').fadeIn(800);
            // $('#datatables_buttons_info').fadeOut(800);
        }
        $scope.dtInstance = {};
        $scope.invData = [];
        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $http.get(apiUrl + '/api/get-geico-invoce-submission-automation-status').then(function (response) {
            $scope.automationStatus = response.data.result.value === '1' ? true : false; 
        });

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };
        $scope.submit_invoice_popup_suc = function () {
            Notification.success('invoice submited succesfully');// DataTables' callback
            $scope.dtInstance.rerender();
        };
        $scope.update_invoice_popup = function () {
            Notification.success('invoice updated succesfully');// DataTables' callback
            $scope.dtInstance.rerender();
        };
        $scope.send_mail_popup = function () {
            Notification.success('email sent succesfully');
        };

        $(document).ready(function () {

        });

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                localStorage.setItem('status', $scope.statusFilter);

                if ($scope.statusFilter) {
                    data.STATUS = $scope.statusFilter;
                }

                // localStorage.setItem('dateRange', $scope.dateRange);
                function isValidDate(dateObject) {
                    return new Date(dateObject).toString() !== 'Invalid Date';
                }
                if ($scope.dateRange) {

                    data.startDate = moment($scope.dateRange.startDate).format('YYYY-MM-DD'),
                        data.endDate = moment($scope.dateRange.endDate).format('YYYY-MM-DD')
                    if (isValidDate($scope.dateRange.startDate) && isValidDate($scope.dateRange.endDate)) {
                        data.filterDate = true
                    } else {
                        data.filterDate = false
                    }
                }

                // map your server's response to the DataTables format and pass it to
                if (data.search.value.length) {
                    $("#InvLogTable").addClass("blur-bg");
                } else {
                    $rootScope.showLoader(true);
                }
                commonFactory.showDataTable('/api/get-geico-invoce-submission-log', data).success(function (res) {
                    $("#InvLogTable").removeClass("blur-bg");
                    $rootScope.showLoader(false);
                    if (res.error) {
                        $scope.reloadData();
                    }
                    else { // DataTables' callback
                        $scope.invData = res.data
                        console.log('status222', localStorage.getItem('status'));

                        angular.forEach($scope.invData, function (value, key) {
                            value.claim_number = value.ClaimNumber;
                            $scope.invData.key = value;
                        });
                        callback(res);
                    }
                }).error(function (err) {
                    $("#InvLogTable").removeClass("blur-bg");
                    $rootScope.showLoader(false);
                    if (err.error)
                        $scope.reloadData();
                });
            })

            .withOption('processing', true)
            .withOption('stateSave', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [3, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withLanguage({
                "sEmptyTable": "NO ATTORNEY FIRMS AVAILABLE IN TABLE",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "",
                "sZeroRecords": "NO MATCHING ATTORNEY FIRMS FOUND",

            })
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'csv' },
                { extend: 'excel', title: 'CodetypeDetails' },
            ]);

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('invoiceNo').withOption('defaultContent', '').withTitle('Invoice Number').renderWith(function (data, type, full) {
                if (full.isDefault == "1") {
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else {
                    return "<div uib-tooltip='Double click to copy' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'" + data + "'}}</div>"
                }
            }),
            DTColumnBuilder.newColumn('claimbNumber').withOption('defaultContent', '').withTitle('Claim Number').renderWith(function (data, type, full) {
                if (full.isDefault == "1") {
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else {
                    return "<div uib-tooltip='Double click to copy' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'" + data + "'}}</div>"
                }
            }),
            DTColumnBuilder.newColumn('result').withOption('defaultContent', '').withTitle('Status').renderWith(function (data, type, full) {
                if (full.isDefault == "1") {
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else {
                    if (full.result == '1') {
                        return '<span class="text-success" >' +
                            'Success' +
                            '</span> &nbsp;';
                    } else {
                        return '<span class="text-danger" >' +
                            'Failed' +
                            '</span> &nbsp;';
                    }
                }
            }),
            DTColumnBuilder.newColumn('created_at').withOption('defaultContent', '').withTitle('Created At'),
            DTColumnBuilder.newColumn('error').withOption('defaultContent', '').withTitle('Errors').renderWith(function (data, type, full) {
                const errors = full.error && full.error !== '' ? full.error : null;
                if (full.result == '0' && errors) {
                    $scope.errors = errors.split('^^^')
                    if (full.invoice_upload_error) {
                        $scope.errors.push(full.invoice_upload_error);
                    }
                    if (full.transcript_upload_error) {
                        $scope.errors.push(full.transcript_upload_error);
                    }
                    return `&nbsp;&nbsp;<a href="javascript:void(0)" uib-popover-template="'geico_invoice_log_errors.html'" popover-placement="bottom-right" popover-title="${full.invoiceNo}" class="pull-right" popover-trigger="outsideClick" title="Show Erros"><i class="fa fa-eye fa-2x text-danger"></i></a>`;
                } else {
                    return '';
                }
            }),
            // DTColumnBuilder.newColumn('id').withTitle(' Actions ').withOption('searchable', false).notSortable()
            //     .renderWith(function (data, type, full, meta) {

            //         return ' &nbsp;&nbsp;<button class="btn btn-xs btn-default" title="Email Client for Invoice Details" >' +
            //             '<i class="fa  fa-envelope-o text-navy"></i>' +
            //             '</button> ';
            //     })
        ];

        $scope.onOffAutomation = function () {
            swal({
                title: "Are you sure ?",
                text: "Are you sure you want to submit this invoice?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1ab394",
                confirmButtonText: "Yes",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $scope.automationStatus = !$scope.automationStatus;
                        console.log('automationStatus', $scope.automationStatus);
                        $rootScope.showLoader(true);
                        //$close(JOB_NO);
                        commonFactory.post('/api/geico-invoce-submission-automation-on-off', { 'status': $scope.automationStatus })
                            .success(function (res) {
                                $rootScope.showLoader(false);
                                if (res.error) {
                                    $scope.reloadData();
                                }
                                else {
                                    Notification.success(res.result.message);// DataTables' callback
                                    // location.reload(); 
                                    $scope.reloadData();
                                }
                            }).error(function (err) {
                                $rootScope.showLoader(false);
                                if (err.error)
                                    $scope.reloadData();
                            });
                    } else {
                        // $uibModalInstance.close(false);
                    }
                });
        };

    }]);




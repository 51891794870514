angular.module('deitz')
    .controller('findCaseController', ['$scope', '$rootScope', '$http', 'apiUrl', '$state', 'firmFactory','$parent','$uibModalInstance',function($scope, $rootScope, $http, apiUrl, $state, firmFactory,$parent,$uibModalInstance) {

        $scope.title = "Check If New Case Exists"
        $scope.findCase = {};
        $scope.findCase.searchBy = "CAPTION";
        //Getting case List
        $scope.caseLists = function(val) {
            return $http.get(apiUrl + '/api/find-case', {
                params: {
                    search: val,
                    searchBy : $scope.findCase.searchBy
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.editCase = function(value){
            if($parent.addCasePopup){
                $uibModalInstance.dismiss({ isChanged : true, case : value});
            }
            else{
                $state.go('case.edit.step_one',{id : value.id })
            }
        }
        $scope.closePopupWindow = function () {
            $uibModalInstance.close(true);
        };

        $scope.addNewCase = function(){
            if($parent.addCasePopup){
                $scope.closePopupWindow();
                $parent.diaryData.job_case_id =  $scope.findCase.search
                $parent.openAddCasePopup();
            }
            else{
                $state.go('case.add.step_one');
            }
        }

    }]);

angular.module('deitz').controller('editJurisdictionController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    function (
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification
    ) {

        $scope.jurisdiction = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Job Type';
        $scope.closeModal = function () {
            $uibModalInstance.close();
        };

        //Edit Mode of jobtype.
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/jurisdiction/' + id + '/edit')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.jurisdiction) {
                        $scope.jurisdiction.name = response.result.jurisdiction.name || '';
                        $scope.jurisdiction.is_active = response.result.jurisdiction.is_active || '';
                        $scope.jurisdiction.sort_order = response.result.jurisdiction.sort_order || null;
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        }

        $scope.addJurisdiction = function (jurisdiction, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/jurisdiction/' + id, $scope.jurisdiction)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("jurisdiction updated successfully");
                                $state.go("code_tables.jurisdiction_browse");
                            }
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);

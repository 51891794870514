angular.module('deitz')
            .controller('pendingJobListController', [
                    '$scope',
                    '$rootScope',
                    '$http',
                    'apiUrl',
                    'DTOptionsBuilder',
                    'DTColumnBuilder',
                    'commonFactory',
                    '$compile',
                    '$uibModal',
                    'Notification',
                    '$filter',
                    '$timeout',
                    '$q',
                    'SweetAlert',
                    '$state',
                        function (
                            $scope,
                            $rootScope,
                            $http,
                            apiUrl,
                            DTOptionsBuilder,
                            DTColumnBuilder,
                            commonFactory,
                            $compile,
                            $uibModal,
                            Notification,
                            $filter,
                            $timeout,
                            $q,
                            SweetAlert,
                            $state
                        ) {

        $scope.pendingJobs = [];
        $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive','V':'Videographer'};
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
        $scope.totalRecords = 0;
        $scope.inProcess = true;
        $scope.lookingFor = $state.params.lookingFor && $state.params.lookingFor == 'audio' ? 'audio' : 'reporter';
        $scope.assignmentTypes = [
            {'type':'','name':'-- ALL JOBS --'},
            {'type':'audio','name':'AUDIO JOBS'},
            {'type':'geico','name':'GEICO JOBS'},
            {'type':'reporter','name':'RESOURCE JOBS'} 
        ];
        var addTomorrowDay = 1;

        if(moment().format('dddd') === 'Friday')
            addTomorrowDay = 3;

        if(moment().format('dddd') === 'Saturday')
            addTomorrowDay = 2;

        $scope.formData = {
            tomorrowsRecord: true,
            today: moment().format('MM/DD/YYYY'),
            tomorrow: moment().add(addTomorrowDay, 'days').format('MM/DD/YYYY'),
            todayText : moment().format('dddd'),
            tomorrowText : moment().add(addTomorrowDay, 'days').format('dddd')
        };
        // $http.get(apiUrl + '/api/get-user-roles-businessunit').then(function(response) { 
            
        // });
        /* Notes browse datatable*/
        $scope.dtInstancePendingJobs = {};

        $http.get(apiUrl + '/api/getJobStatus').then(function(response) { 
            $scope.JobStatusList = response.data.result.data;  
            $scope.JobStatusList.unshift({name:'All'});
        });

        $scope.selectedJobStatusIDs = [];

        $scope.selectedServiceStatusIDs = [];
        $http.get(apiUrl + '/api/get-active-assignment-status').then(function(response) { 
            $scope.ServiceStatusList = response.data.result.assignment_status;  
            $scope.ServiceStatusList.unshift({name:'All'});
        });

        $http.get(apiUrl + '/api/casetypes').then(function(response) { 
            $scope.CaseTypeList = response.data.result.casetype;  
            $scope.CaseTypeList.unshift({name:'All'});
        });

        $scope.selectedCaseTypeIDs = [];        

        $http.get(apiUrl + '/api/getJobCategory').then(function(response) { 
            $scope.JobCategoryList = response.data.result.jobcategory;  
            $scope.JobCategoryList.unshift({name:'All'});
        });

        $scope.selectedJobCategoryIDs = [];
        
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});

        });

        $http.get(apiUrl + '/api/get-biller-list').success(function(response) {
            $scope.BillerList=response.result
            $scope.BillerList.unshift({name:'All'});
        });
        $scope.selectedBillerIDs = [];

        $scope.reloadJobData = function () {
            // $scope.dtInstancePendingJobs.rerender();
            $scope.dtInstancePendingJobs.DataTable.ajax.reload();
        };
        $scope.reloadData = function () {
            // $scope.dtInstancePendingJobs.rerender();
            $scope.dtInstancePendingJobs.DataTable.ajax.reload();
        };
        $scope.resetJobSearch = function(){
            $scope.dtInstancePendingJobs.DataTable.state.clear();
            $scope.formData.todaysRecord = false;
            $scope.formData.tomorrowsRecord = true;
            $scope.lookingFor = 'reporter';
            $scope.reloadJobData();
        };
        $scope.timeoffConflicts = function(repoters){
            swal('Time Off Conflicted Job / Not Working Day', repoters+' -- They’ve applied for time off request or not worked on that day. Thank you.');
        }
        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };

        $scope.dtJobsOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                // map your server's response to the DataTables format and pass it to

                data.filterParams = $scope.formData;
                data.assignmentType = $scope.lookingFor;
                data.selectedJobStatusIDs = $scope.selectedJobStatusIDs.filter(Boolean);
                data.selectedServiceStatusIDs = $scope.selectedServiceStatusIDs.filter(Boolean);
                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                data.selectedCaseTypeIDs = $scope.selectedCaseTypeIDs.filter(Boolean);
                data.selectedJobCategoryIDs = $scope.selectedJobCategoryIDs.filter(Boolean);
                data.selectedBillerIDs = $scope.selectedBillerIDs.filter(Boolean);             
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));                $scope.inProcess = true;
                commonFactory.post('/api/pendingJobs', data)
                    .success(function(response) {
                        $scope.inProcess = false;
                        if (response.error) {
                            $scope.reloadJobData();
                        }
                        else {
                            $scope.totalRecords = response.recordsFiltered;
                            callback(response);
                        }
                    })
                    .error(function(err) {
                        $scope.inProcess = false;
                        if (err.error !== "token_not_provided") {
                            $scope.reloadJobData();
                        }
                    });

            })
            .withOption('processing', true)
            .withLanguage({

                "sEmptyTable": "NO RECORDS AVAILABLE IN TABLE",
                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ RECORDS",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL RECORDS)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ RECORDS",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "Search :",
                "sZeroRecords": "NO MATCHING RECORDS FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
            .withOption('stateSave', true)
            // .withOption('paging', false)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withDisplayLength(100)
            .withOption('lengthMenu', [
                [10, 25, 50, 100, 200, 250, 300, 500],
                [10, 25, 50, 100, 200, 250, 300, 500]
            ])
            .withOption('order', [2, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });

        $scope.dtJobColumns = [];
        var titleHtml = '<input type="checkbox" class="layout-align-xl-center-center" icheck ng-model="selectAll" ng-change="toggleAllInvoice(selectAll)">';

        $scope.dtJobColumns.push(
         
            DTColumnBuilder.newColumn('JOB_NO').withTitle('Job # <hr> Legacy Job # <hr> Link Status').withOption('searchable', true).withClass('job_legacy').withOption("width", "6%").renderWith(function(data,type,full,meta){
                var label;
                var litify_style='';
               /* var status = full.JOB_STATUS ? full.JOB_STATUS.toLowerCase() : '';
                switch (status) {
                    case 'sent':
                        label="label label-primary";
                        break;

                    case 'accepted':
                        label="label label-success";
                        break;

                    case 'declined':
                        label="label label-danger";
                        break;
                    default :
                        break;

                }*/
                
                
                legacy_job_no = '';
                if(full.legacy_job_no){
                    legacy_job_no = '<br><hr><span style="padding:5px 10px 7px 10px;font-size:12px;">'+full.legacy_job_no+'</span>';
                }
                link_status = '';
                if(full.is_zoom_link_send == 1 && full.meeting_host_type == 'Z'){
                    link_status = '<br><hr><a target="_blank" href="'+full.vc_link+'" class="label label-success">Link Sent</a>'
                }
                if(full.is_zoom_link_send == 0 && full.meeting_host_type == 'Z'){

                    link_status = '<br><hr><a target="_blank" href="'+full.vc_link+'" class="label label-danger">Link Not Sent<a>'
                }
                if(full.is_zoom_link_send == null || full.meeting_host_type != 'Z'){

                    link_status =  ''
                }


                if(!label){
                    if(full.schedule_type == 'client_form' || full.schedule_type == 'progressive_form'){
                        label="label label-warning";
                    }
                    if(full.schedule_type == 'litify' || full.schedule_type=='shulman-hill-litify'){
                        label="label";
                        litify_style="background-color:#ffc4cd;"
                    }
                    if(full.location_id=='10'){ // vanue is NEW JERSEY	
                        label="label";
                        litify_style="background-color:#8f00ff;color:#fff";
                    }
                    if(full.case_type_id=='1'){ // Case Type Medical
                        label="label";
                        litify_style="background-color:pink;"
                    }
                }
                if(full.exhibit_uploaded_filename){
                    return '<span class="'+label+'" style="padding:5px 10px 7px 10px;font-size:14px;'+litify_style+'"><a  style="'+litify_style+'" target="_blank" ui-sref="diary.editcase.step_one({id: '+ data +'})">'+data+'</a><a href="'+$rootScope.depoHost+'/upload_exhibit_by_client.php?JOB_NO='+data+'" target="_blank"><button class="btn btn-xs btn-default">'+'<i class="fa fa-file-o" aria-hidden="true"></i>'+'</button></a></span>'+legacy_job_no+link_status
                }else{

                    return '<span class="'+label+'" style="padding:5px 10px 7px 10px;font-size:14px;'+litify_style+'"><a style="'+litify_style+'" target="_blank" ui-sref="diary.editcase.step_one({id: '+ data +'})">'+data+'</a></span>'+legacy_job_no+link_status;
                }
            }),           
            DTColumnBuilder.newColumn('TIME_TAKEN').withOption('defaultContent', '').withTitle('Job Time <hr> Time Zone').withOption('searchable', true).withOption("width", "4%").renderWith(function(data, type, full, meta){
                if(data){
                    return data+'<br><hr>'+full.timezone;
                } 
            }),           
            DTColumnBuilder.newColumn('DATE_TAKEN').withOption('defaultContent', '').withTitle('Job Date').withOption('searchable', true).withOption("width", "4%").renderWith(function(data, type, full, meta){
                if(data){
                    return moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY');
                }
                return '---';
            }),
            DTColumnBuilder.newColumn('job_status_name').withTitle('Job Status').withOption('defaultContent', '').withOption('searchable', false).withOption("width", "6%").renderWith(function(data, type, full, meta){
                if (data) {
                    return data;
                } 
            }),
            DTColumnBuilder.newColumn('client_level').withOption('defaultContent', '').withTitle('Client Level').withOption('searchable', false).withOption("width", "4%").renderWith(function(data, type, full, meta){
                if (data) {
                    var client_level = data.substring(0, 8);
                    return "<div uib-tooltip='"+data+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>"+client_level+"</div>";
                    
                } else {
                    return '---';
                }
            }),
            DTColumnBuilder.newColumn('FIRM_SHORT_NAME').withOption('defaultContent', '').withTitle('Scheduling Firm').withOption('name', 'ATTORNEY_tbl.SHORT_NAME').withOption('searchable', true).withOption("width", "6%").renderWith(function(data, type, full, meta){
                if (data) {
                    data = escapeString(data);
                    var attorney_firm = "<div uib-tooltip='"+full.FIRM_NAME+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                    return attorney_firm;
                } else {
                    return '---';
                }
            }),
            DTColumnBuilder.newColumn('FILE_NO').withTitle('File Number').withOption('name', 'CASE_tbl.FILE_NO').withOption('defaultContent', '').withOption('searchable', true).withOption("width", "4%"),  
            DTColumnBuilder.newColumn('CAPTION').withOption('defaultContent', '').withTitle('Caption<br><hr>EUO / Statement').withOption('name', 'CASE_tbl.CAPTION').withOption('searchable', true).withOption("width", "10%").renderWith(function(data, type, full, meta){
                var cap = '';
                if (data) {                   
                    data = escapeString(data);
                    var caption = "<div uib-tooltip='"+data+ "' tooltip-trigger='focus mouseenter' tooltip-placement='top'>{{'"+data+"' | limitTocustom:13}}</div>";
                        caption += '<i style="color: black;">';
                        full.audio_files.forEach(function(item){
                            if(item.filetype == "mp3" || item.filetype == "mp4"){
                                caption += '<h5>'+item.duration +' - '+ $scope.niceBytes(item.filesize)+'</h5>';
                            }
                        });
                        caption +='<i>';
                        if(full.IndexNumber){
                            caption += "<a href='{{depoHost}}/autologin.php?userid={{auth_user.email}}&password={{auth_user.depo_psd}}&script=display_on_elaw.php?indexNumber="+full.IndexNumber+"' target='_blank' title='Display On Elaw'>Elaw</a></div>";
                        }
                        cap = caption;
                } else {
                    cap = '---';
                }
                if(full.euo_statement_of){
                    cap = cap+'<hr><a href="javascript:void(0);" uib-tooltip="Toggle" ng-click="toggleJobType('+full.JOB_NO+',\''+full.euo_statement_of+'\')">'+full.euo_statement_of+'</a>';
                }
                return cap;

            }),                     
            DTColumnBuilder.newColumn('jobcategory').withTitle('Job Category').withOption('searchable', false).withOption("width", "8%"),
            DTColumnBuilder.newColumn('HELD_AT').withOption('defaultContent', '').withTitle('Job Location').withOption('name','DIARY_tbl.HELD_AT1').withOption('searchable', true).withOption("width", "6%").renderWith(function(data, type, full, meta) {
                if (data) {
                    data = escapeString(data);
                    var HELD_AT = "<div uib-tooltip='"+data+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:13}}</div>";
                    return HELD_AT;
                } else {
                    return '---';
                }
            }),
            DTColumnBuilder.newColumn('job_resources').withOption('name','job_resources.resource.FIRST_NAME').withOption('defaultContent', '').withTitle('resources&nbsp;&nbsp;&nbsp;').withOption('searchable', true).withOption('class', 'reporter-remove-td').renderWith(function(data, type, full, meta){
                 
                var returnClass = "";
                full.production_queue=='CBU'  ? returnClass = "cbu_job" : returnClass = "";
                var tableCss = "";
                full.production_queue=='CBU'  ? tableCss = 'style=" background-color: #ffff6f!important; "' : tableCss = "";
               
                html='<div class="'+returnClass+'">';
                if(full.conflicted_resources != ''){
                    html += '<span style="cursor: pointer;" ng-click="timeoffConflicts(\''+full.conflicted_resources+'\')" class="text-danger"><i class="fa fa-exclamation-circle fa-2x" uib-tooltip="Timeoff - conflicted job"></i></span>';
                }
                html +='<table class="table table-bordered" '+tableCss+'>'
                data.forEach(job_resource => {
                if(job_resource.resource){
                    var label;
                    var status = '';
 
                    if(job_resource.resource.REP_TYP =='T'){
                        var type ='Typist';
                    }
                    else{
                        var type = (job_resource.additional_resource_type) ? job_resource.additional_resource_type.name :'';
                    }
    
                    if(job_resource.service_request && job_resource.service_request.assignment_status){
                            var status_color = job_resource.service_request.assignment_status.color_code;
                            var fontColor = job_resource.service_request.assignment_status.text_color;
                            html+= '<tr ><td style="text-align: left !important;width: 50%;"><a uib-tooltip="'+job_resource.resource.name+'-'+type+'"  tooltip-trigger="focus mouseenter"  tooltip-placement="top" class="label" style="background-color:'+status_color+';color:'+fontColor+'">'+  $scope.limitchar(job_resource.resource.name,15) +'</a> <span class="label label-danger" ng-click="removeResource('+ full.JOB_NO +','+job_resource.id+','+job_resource.service_request.id+')" ng-if="havePermission(\'diary\',\'update\')">  <i title="Remove Resource" style="cursor: pointer;" class="fa fa-trash" ></i></span></td><td style="text-align: left !important;width: 50%;"></a> <a class="label" style="background-color:'+status_color+';color:'+fontColor+'">'+ type+'</a> <a class="label " style="background-color:'+status_color+';color:'+fontColor+'">'+ job_resource.service_request.assignment_status.name+'</a></p> </td></tr>';    
                    }else{
                            html+= '<tr><td style="text-align: left !important;width: 50%;"><a uib-tooltip="'+job_resource.resource.name+'-'+type+'"  tooltip-trigger="focus mouseenter"  tooltip-placement="top" class="label label-warning" >'+  $scope.limitchar(job_resource.resource.name,15) +'</a> <span class="label label-danger" ng-click="removeResource('+ full.JOB_NO +','+job_resource.id+')" ng-if="havePermission(\'diary\',\'update\')">  <i title="Remove Resource" style="cursor: pointer;" class="fa fa-trash" ></i></span></td><td style="text-align: left !important;width: 50%;"></a></a> <a class="label label-warning">'+ type+'</a></p></td></tr>';    
                    }   
                    if(job_resource.typist_id){
                        var typist = job_resource.typist;
                        var typist_status =  '';
                        var index = full.pending_job_status.findIndex(function (o) {
                            return (o.resource_id == job_resource.typist_id) ;
                        });
                        var typist_status = full.pending_job_status[index].status? full.pending_job_status[index].status.toLowerCase() : '';
                        switch (typist_status) {
                            case 'sent':
                                typist_label=" label label-light-primary";
                                break;
        
                            case 'accepted':
                                typist_label="label label-success";
                                break;
        
                            case 'declined':
                                typist_label="label label-danger"; 
                                break;
                            default :
                            typist_label="label label-warning";
                                break;
                        }
                        html+= '<tr><td style="text-align: left !important;width: 50%;"><a uib-tooltip="'+typist.name+'-typist"  tooltip-trigger="focus mouseenter"  tooltip-placement="top" class="'+typist_label+'" >'+  typist.name +'</a></p></td><td style="text-align: left !important;width: 50%;"></a></a> <a class="label label-warning">Typist</a></p></td></tr>';    
                    }
                }
                });
               if(full.service_request && full.service_request.length>0){
                full.service_request.forEach(element => {
                    if(element.additional_resource_type){
                        html+= '<tr><td style="width: 50%;"><span ng-if="havePermission(\'diary\',\'update\')" ng-click="assignSingleResource('+ full.JOB_NO +','+element.id+','+full.id+')"><i title="Assign Single Resource"  class="fa fa-2x fa-plus" style="cursor: pointer;text-align: center;position: static;"></i></span></td><td style="text-align: left !important;width: 50%;"><a class="label label-default">'+ element.additional_resource_type.name+'</a>  <span class="label label-danger" ng-click="removeResorceService('+ full.JOB_NO +','+element.id+')" ng-if="havePermission(\'diary\',\'update\')">  <i title="Remove Resource Services" style="cursor: pointer;" class="fa fa-trash" ></i></span></td></tr>';
                    }
                });
                }
                if(data.length > 0){
                    html+= '<span ng-if="havePermission(\'diary\',\'update\')" ng-click="assignReporter('+ full.JOB_NO +',\'reporter\','+full.job_status_id+')"><i title="Change Resource"  class="fa fa-2x fa-pencil" style="cursor: pointer;position: absolute;right: 5px;z-index: 1;top: 12px;font-size: 20px;"></i></span>';    
                } else{
                    html+= ' </table><span ng-click="assignReporter('+ full.JOB_NO +',\'reporter\','+full.job_status_id+')" ng-if="havePermission(\'diary\',\'update\')"> <i title="Assign Resource" class="fa fa-2x fa-plus" style="cursor: pointer;"></i></span>';
                }
                return html;
            }),
            DTColumnBuilder.newColumn('JOB_STATUS').withTitle('Send SMS/Email').notSortable().withOption('searchable', false).withOption('name', 'pendingjob_status.status').withOption("width", "3%").renderWith(function (data, type, full, meta) {
                if($rootScope.havePermission('diary','manage.job.confirmation')  && full.job_resources.length ){
                    return '<a class="" ng-click="sendJobResource(\''+ full.JOB_NO +'\')">Send Job</a>';
                }
                return '';
                
            }),
            DTColumnBuilder.newColumn('businessunit').withTitle('Business Unit').withOption('searchable', false).withOption("width", "8%").withOption('class', 'hide-td businessunit'),
            DTColumnBuilder.newColumn('salesperson_name').withOption('defaultContent', '').withTitle('Account Executive').withOption('name', 'salesperson.FIRST_NAME').withOption('searchable', true).withOption("width", "6%").withOption('class', 'hide-td account_ex').renderWith(function(data, type, full, meta){
                if (data) {
                    data = escapeString(data);
                    var caption = "<div uib-tooltip='"+data+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:10}}</div>";
                    return caption;

                } else {
                    return '---';
                }
            }),  
            DTColumnBuilder.newColumn('pending_job_status').withTitle('').withOption('searchable', false).withOption("width", "5%").notSortable().renderWith(function (data, type, full, meta) {

                var bindButtons = ' <div class="btn-group btn-sm" uib-dropdown>' +
                                        '<button type="button" class="btn btn-primary btn-sm btn-outline" uib-dropdown-toggle> Action <span class="caret"></span></button>'+
                                        '<ul role="menu" uib-dropdown-menu="" style="left: -100px!important;">';
                                        if($rootScope.havePermission('diary','update',full.business_unit_id)){
                                                bindButtons +=  '<li uib-tooltip="Edit">'+
                                                                    '<a class="btn btn-xs btn-default" ui-sref="diary.editcase.step_one({id: '+ full.JOB_NO +'})"><i class="fa fa-pencil"></i> &nbsp; Edit </a>'+
                                                                '</li>';                              
                                            }else{
                                                bindButtons +=  '<li uib-tooltip="View">'+
                                                                    '<a class="btn btn-xs btn-default" ui-sref="diary.editcase.step_one({id: '+ full.JOB_NO +'})"><i class="fa fa-eye"></i> &nbsp; View Calendar</a>'+
                                                                '</li>';                        
                                            }
                    var assignedTypistId=[];
                    var assignedTypist=[];
                    angular.forEach(full.job_resources, function (value, key) {
                        if(value.resource_type=='R'){
                            var index = data.findIndex(function (o) {
                                return (o.resource_id == value.resource_id && o.resource_type_id == value.resource_type_id) ;
                            });
                        }
                        else{
                            var index = data.findIndex(function (o) {
                                return (o.resource_id == value.resource_id) ;
                            });
                        }
                        var token = data[index] ? data[index].token : '';        
                        if(value.resource_type=='R'){
                            if($rootScope.havePermission('diary','manage.job.confirmation')){
                                 bindButtons +=' <li uib-tooltip="View '+value.additional_resource_type.name+' Confirmation">'+
                                                        '<a class="btn btn-xs btn-default" target="_blank" href="'+$rootScope.depoHost+'/job_confirmation.php?job_no='+ btoa(full.JOB_NO) +'&token='+token+'&session='+btoa(value.resource_id)+'&resource_type='+btoa(value.resource_type_id)+'"><i class="fa fa-eye"></i>&nbsp; '+value.resource.name+' - View</a>'+
                                                    '</li>';
                            }else{
                                bindButtons +=' <li uib-tooltip="View '+value.additional_resource_type.name+' Confirmation">'+
                                                    '<a class="btn btn-xs btn-default" target="_blank" href="'+$rootScope.depoHost+'/view_only_confirmation.php?job_no='+ btoa(full.JOB_NO) +'"><i class="fa fa-eye"></i>&nbsp; '+value.resource.name+' - View</a>'+
                                                '</li>';                         
                            }

                        }else if(value.resource_type=='T'){
                            if($rootScope.havePermission('diary','manage.job.confirmation') && (full.assignment_type == 'audio' || full.is_transcript_ordered == 'Y')){
                                if(full.audio_files.length){
                                    bindButtons +=' <li uib-tooltip="View Typist Confirmation">'+
                                                        '<a class="btn btn-xs btn-default" target="_blank" href="'+$rootScope.depoHost+'/typist_confirmation.php?job_no='+ btoa(full.JOB_NO) +'&token='+token+'&session='+btoa(value.id)+'"><i class="fa fa-volume-up"></i>&nbsp; '+value.resource.name+' - View</a>'+
                                                    '</li>';
                                }else{
                                    bindButtons +=' <li uib-tooltip="Audio files not uploaded">'+ 
                                        '<a class="btn btn-xs btn-default" href="javascript:void(0);"><i class="fa fa-volume-up"></i><strike>&nbsp; '+value.resource.name+' - View</strike></a>'+
                                    '</li>';
                                }                              
                            }
                        }
                        if(value.typist && !assignedTypistId.includes(value.typist_id)){
                            assignedTypist.push(value.typist);
                            assignedTypistId.push(value.typist_id);
                        }
                    });
                    angular.forEach(assignedTypist, function (value, key) {
                        var index = data.findIndex(function (o) {
                            return (o.resource_id == value.id) ;
                        });
                        var token = data[index] ? data[index].token : '';                            
                        if($rootScope.havePermission('diary','manage.job.confirmation') && (full.assignment_type == 'audio' || full.is_transcript_ordered == 'Y')){
                            if(full.audio_files.length){
                                bindButtons +=' <li uib-tooltip="View Typist Confirmation">'+
                                                    '<a class="btn btn-xs btn-default" target="_blank" href="'+$rootScope.depoHost+'/typist_confirmation.php?job_no='+ btoa(full.JOB_NO) +'&token='+token+'&session='+btoa(value.id)+'"><i class="fa fa-volume-up"></i>&nbsp; '+value.name+' - View</a>'+
                                                '</li>';
                            }else{
                                bindButtons +=' <li uib-tooltip="Audio files not uploaded">'+ 
                                    '<a class="btn btn-xs btn-default" href="javascript:void(0);"><i class="fa fa-volume-up"></i><strike>&nbsp; '+value.name+' - View</strike></a>'+
                                '</li>';
                            }                              
                        }
                    });
                    if(full.launch_atic_user && full.launch_atic_user.username && full.launch_atic_user.password && full.is_transcript_ordered == 'N'){
                        var enscript = encodeURIComponent("atic_scheduling.php?job_no="+ full.JOB_NO);
                        var URL = $rootScope.depoHost +"/autologin.php?userid="+full.launch_atic_user.username+"&password="+full.launch_atic_user.password+"&script="+enscript;
                        bindButtons +=' <li uib-tooltip="Launch atic">'+ 
                                            '<a class="btn btn-xs btn-default" target="_blank" href="'+URL+'"><i class="fa fa-rocket"></i> &nbsp; Launch atic</a>'+
                                        '<li>';
                    }
                    bindButtons += '</ul></div>';

                return bindButtons;

            })
        );

        $scope.toggleDataTableColumn = function(el){
            $('.'+el).toggleClass('hide-td');
        }

        $scope.assignResource = function(jobNo, resource_type){
            var assignResource = $uibModal.open({
                templateUrl: "modules/diary/caselist/assignResource.html",
                // controller: [ '$scope',
                // '$rootScope',
                // '$state',
                // '$http',
                // 'apiUrl',
                // '$timeout',
                // '$uibModal',
                // 'commonFactory',
                // '$uibModalInstance',
                // 'Notification',
                // 'JOB',
                // 'resource_type',
                // function(
                //     $scope,
                //     $rootScope,
                //     $state,
                //     $http,
                //     apiUrl,
                //     $timeout,
                //     $uibModal,
                //     commonFactory,
                //     $uibModalInstance,
                //     Notification,
                //     JOB,
                //     resource_type) { //<-- difference
                   
                //         'assignResourceController'
                    
                // }],
                controller: 'assignsResourceController',
                resolve: {
                    JOB: function () {
                        return jobNo;
                    },
                    resource_type : function(){
                        return resource_type;
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });


            assignResource.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){

                    $scope.reloadJobData();
                    $rootScope.showLoader(false);
                }

            });
        };

        $scope.assignReporter = function(jobNo, resource_type, job_status_id = null){
            var assignResourceModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/assignReporter.html",
                controller: 'assignReporterController',
                resolve: {
                    JOB: function () {
                        return jobNo;
                    },
                    resources : function(){
                        return null;
                    },
                    formDate : function(){
                        return $scope.formData;
                    }
                },
                size: 'lg',
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });


            assignResourceModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged || (job_status_id != cbData.job_status_id)){
                    $scope.updateSingleTableRow(jobNo);
                    // $scope.reloadData();
                    $rootScope.showLoader(false);
                }

            });
        };
        $scope.removeResource = function(JOB_NO, resource_type) {

            SweetAlert.swal({
                title: "Are you sure ?",
                text: "You are about to remove a "+resource_type,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function(callback){
                if(callback){
                    $rootScope.showLoader(true);
                    return $http.post(apiUrl + '/api/removeResource', { JOB_NO : JOB_NO, resource_type : resource_type })
                        .then(function(response) {
                            Notification.success(response.data.message);
                            $scope.reloadJobData();
                            $rootScope.showLoader(false);
                        });
                }
            });
        };

        $rootScope.sendJobSms = function(jobNo, status, cellPhone, REP_NAME_KEY, reporter_id, token){
            var textMsg = 'Depositionnet assignment - confirm or decline at:';
            status = status ? status.toLowerCase() : '';
            switch (status) {
                case 'sent':
                    textMsg = 'Depositionnet assignment - you have not replied:';
                    break;

                case 'accepted':
                    textMsg = 'Depositionnet confirmed. Changes:';
                    break;

                case 'declined':
                    textMsg = 'Depositionnet declined. Changes:';
                    break;
                default :
                    break;
            }
            var sendJobSmsModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/sendJobSms.html",
                controller: function($scope, SMSDATA, $uibModalInstance){


                    $scope.title = "Send SMS";
                    $scope.formData = SMSDATA;

                    $scope.cancel = function(isChanged) {

                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };

                    $scope.sendSms = function(isValid){
                        if(isValid){
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/sendJobSms', $scope.formData)
                                .success(function(response){
                                    $rootScope.showLoader(false);
                                    if(response.error){
                                        Notification.error(response.result.message || "Something went wrong while sending SMS!");
                                    } else{
                                        $uibModalInstance.dismiss({ isChanged : true});
                                        Notification.success(response.result.message || "SMS successfully sent.");
                                    }
                                }).error(function(error){
                                    $rootScope.showLoader(false);
                                    Notification.error("Something went wrong while sending SMS!");

                                });
                        }
                    }
                },
                resolve: {
                    SMSDATA: function () {
                        return {
                            JOB_NO: jobNo,
                            status: status,
                            CELL: cellPhone,
                            REP_NAME_KEY: REP_NAME_KEY,
                            textMsg:textMsg + " \n" + $rootScope.depoHost+"/job_confirmation.php?job_no="+ btoa(jobNo)+'&token='+token+'&session='+btoa(reporter_id),
                            URL: $rootScope.depoHost+"/job_confirmation.php?job_no="+ btoa(jobNo)+'&token='+token+'&session='+btoa(reporter_id),
                            reporter_id : reporter_id,
                            token : token
                        };
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });


            sendJobSmsModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){

                    $scope.reloadJobData();
                    $rootScope.showLoader(false);
                }

            });
        };

        $scope.limitchar = function (input, limit){
            if (limit > input.length) {
                return input.slice(0, limit);
            } else {
                return input.slice(0, limit) + '...';
            }
        }

        $rootScope.sendJobEmail = function(jobNo, status, emailAddress, TYP_NAME_KEY, typist_id, token){

            var emailMsg = 'Depositionnet assignment - confirm or decline at:';
            status = status ? status.toLowerCase() : '';
            switch (status) {
                case 'sent':
                    emailMsg = 'Depositionnet assignment - you have not replied:';
                    break;

                case 'accepted':
                    emailMsg = 'Depositionnet confirmed. Changes:';
                    break;

                case 'declined':
                    emailMsg = 'Depositionnet declined. Changes:';
                    break;
                default :
                    break;
            }
            var sendJobEmailModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/sendJobEmail.html",
                controller: function($scope, email_data, $uibModalInstance, codeFactory){

                    $scope.title = "Send Email";
                    $scope.formData = email_data;
                    $scope.validations = codeFactory.validations;
                    $scope.messages = codeFactory.messages;

                    $scope.cancel = function(isChanged) {

                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };

                    $scope.sendEmail = function(isValid){
                        
                        if(isValid){
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/sendJobEmail', $scope.formData)
                                .success(function(response){
                                    $rootScope.showLoader(false);
                                    if(response.error){
                                        Notification.error(response.result.message || "Something went wrong while sending Email!");
                                    } else{
                                        $uibModalInstance.dismiss({ isChanged : true});
                                        Notification.success(response.result.message || "Email successfully sent.");
                                    }
                                }).error(function(error){
                                    $rootScope.showLoader(false);
                                    Notification.error("Something went wrong while sending Email!");

                                });
                        }
                    }
                },
                resolve: {
                    email_data: function () {
                        return {
                            JOB_NO: jobNo,
                            status: status,
                            email_address: emailAddress,
                            TYP_NAME_KEY: TYP_NAME_KEY,
                            emailMsg: emailMsg + " \n " + $rootScope.depoHost+"/typist_confirmation.php?job_no="+ btoa(jobNo)+'&token='+token,
                            URL: $rootScope.depoHost+"/typist_confirmation.php?job_no="+ btoa(jobNo)+'&token='+token,
                            typist_id : typist_id,
                            token : token
                        };
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });


            sendJobEmailModal.result.then(function (cbData) {
            }, function (cbData) {
                if(cbData.isChanged){
                    $scope.reloadJobData();
                    $rootScope.showLoader(false);
                }
            });
        };

        $scope.sendJobResource=function(job){
            var sendJobEmailModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/sendJobResource.html",
                controller:'sendJobResourceController',
                resolve: {
                    jobNo: function () {
                        return job
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });

            sendJobEmailModal.result.then(function (cbData) {
            }, function (cbData) {
                if(cbData.isChanged){ 
                    //  $scope.reloadData();
                    $scope.updateSingleTableRow(job);
                    $rootScope.showLoader(false); 
                }
            });
    };

    $scope.updateSingleTableRow = function(job_no){

        var rows = $scope.dtInstancePendingJobs.DataTable.rows().data();

        var filter = rows.filter(function(obj, index) {
            return obj.JOB_NO == job_no;
        })[0];

        var index = rows.indexOf(filter);
        if(index != -1){
            let findcase = {};
            findcase.selectedJobId = job_no;
            
            commonFactory.post('/api/pendingJobs', findcase)
            .success(function(response) {
                $rootScope.showLoader(false);
                if (response && response.data && response.data.length > 0) {
                    var temp = $scope.dtInstancePendingJobs.DataTable.row(index).data();
                    temp = response.data[0];
                    $scope.dtInstancePendingJobs.DataTable.row(index).data(temp).invalidate();
                    $scope.createdRow($scope.dtInstancePendingJobs.DataTable.row(index).nodes());        
                }else{
                    $scope.reloadData();
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });
        }else{
            $scope.reloadData();
        }
    }
        $scope.toggleJobType = function(JOB_NO, euo_statement_of){
            
            SweetAlert.swal({
                title: "Are you sure want to change?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Change it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function(callback){
                if(callback){
                    $rootScope.showLoader(true);
                    return $http.post(apiUrl + '/api/toggle-job-type', {JOB_NO : JOB_NO, euo_statement_of : euo_statement_of})
                    .then(function(response) {
                        $scope.reloadJobData();
                        $rootScope.showLoader(false);
                    });
                }
            });
        };

        $scope.niceBytes = function (x){
            
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            if(x === 1) return '1 byte';

              let l = 0, n = parseInt(x, 10) || 0;

              while(n >= 1000 && ++l){
                  n = n/1000;
              }
              //include a decimal point and a tenths-place digit if presenting
              //less than ten of KB or greater units
              return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        };
    }
]);

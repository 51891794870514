angular.module('deitz')
    .controller('AddSignatureController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'resource_signature_id',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  codeFactory,
                  $http,
                  Upload,
                  apiUrl,
                  $state,
                  commonFactory,
                  Notification,
                  $stateParams,
                  $uibModal,
                  resource_signature_id,
                  $uibModalInstance,
                  configFactory) {
            $scope.resourceSignature = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'Add Signature';
            $scope.resourceSignature.reptype_id = $state.params.id;            
            $scope.resource_signature_id = resource_signature_id;           
            if(resource_signature_id){
                $scope.title = 'Edit Signature';

                var id = resource_signature_id;
                commonFactory.get('/api/resource_signatures/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result) {                        
                        $scope.resourceSignature.reptype_id = response.result.signature.reptype_id || '';                   
                        if(response.result.signature.file_path){
                            $scope.isPdfUploaded = true;
                        }
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            }           
          

            $scope.addResourceSignature = function(resourceSignature, isValid) {
                $scope.formSubmit = true;
            
                // Check if form is valid (includes file validation)
                if (isValid) {
                    $rootScope.showLoader(true);
            
                    // Check if we're updating or adding a new signature
                    if (resource_signature_id) {
                        var id = resource_signature_id;
                        
                        // Update the resource signature
                        Upload.upload({
                            url: apiUrl + '/api/resource_signatures/' + id + '/update',
                            data: $scope.resourceSignature
                        }).then(function(response) {
                            $rootScope.showLoader(false);
                            if (response.status == 200) {
                                Notification.success("Signature updated successfully");
                                $scope.closePopupWindowSimple();
                            } else {
                                Notification.error(response.result.message);
                            }
                        }, function(err) {
                            $rootScope.showLoader(false);
                            Notification.error("An error occurred while updating the signature.");
                        });
                    } else {
                        // Add a new resource signature
                        Upload.upload({
                            url: apiUrl + '/api/resource_signatures/addnew',
                            data: $scope.resourceSignature
                        }).then(function(response) {
                            $rootScope.showLoader(false);
                            console.log(response);
                            if (response.data.status == 200) {
                                Notification.success("Signature added successfully");
                                $scope.closePopupWindowSimple();
                            } else {
                                Notification.error(response.data.result.message);
                            }
                        }, function(err) {
                            $rootScope.showLoader(false);
                            Notification.error("An error occurred while adding the signature.");
                        });
                    }
                } else {
                    // Notify user if the form is invalid
                    Notification.error("Please select files before submitting.");
                }
            };
            
            
            
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.close(true);
                setTimeout(function () {
                    document.getElementById('reloadSignature').click();
                }, 400);
            };

            $scope.viewSignature = function(id){
                if(id){
                    $http.get(apiUrl + '/api/get-signature-url', {
                        params: {
                            id: id
                        }
                    }).then(function(response) {
                        if(!response.data.error){
                            window.open(response.data.result,'_blank');
                        }else{
                            Notification.error(response.data.message);
                        }
                    });
                }
            }          
        }]);
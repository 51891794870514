angular.module('deitz').controller('editVipLevelController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    function (
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification
    ) {

        $scope.vip_level = {};
        $scope.formSubmit = false;
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.title = 'Edit VIP Level';
        $scope.closeModal = function () {
            $uibModalInstance.close();
        };

        //Edit Mode of jobtype.
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/vip_level/' + id + '/edit')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.vip_level) {
                        $scope.vip_level.name = response.result.vip_level.name || '';
                        $scope.vip_level.is_active = response.result.vip_level.is_active || '';
                        $scope.vip_level.sort_order = response.result.vip_level.sort_order || null;
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        }

        $scope.addVipLevel = function (vip_level, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/vip_level/' + id, $scope.vip_level)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("VIP Level updated successfully");
                                $state.go("code_tables.vip_level_browse");
                            }
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);

angular.module('deitz').controller('editBannerImageController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, fromModal, $uibModalInstance
    ) {

        $scope.bannerImage = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Banner Image';
        $scope.imagePreviews = [];
        $scope.selectedFiles = [];
        
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/banner-images/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.bannerImage) {
                        $scope.bannerImage.image_name = response.result.bannerImage.image_name || '';
                        $scope.bannerImage.module = response.result.bannerImage.module || null;
                        $scope.bannerImage.description = response.result.bannerImage.description || null;
                        // $scope.bannerImage.images = response.result.bannerImage.images_path || null;

                        $scope.imagePreviews = response.result.bannerImage.images_path.map(img => ({
                            src: img.url, // URL of image
                            id: img.id // Database ID
                        }));
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        $scope.add_banner_image = function(bannerImage, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                var id = $state.params.id;
                let formData = new FormData();
                formData.append("image_name", bannerImage.image_name);
                formData.append("description", bannerImage.description);
                formData.append("module", bannerImage.module);
        
                // Append multiple images
                angular.forEach($scope.selectedFiles, function(file, index) {
                    formData.append("images[]", file);
                });
        
                $rootScope.showLoader(true);
                commonFactory.postMultipart('/api/banner-images/update/'+id, formData)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200) {
                            Notification.success("Banner Image update successfully");
                            $state.go("code_tables.banner_image_browse");
                        } else {
                            Notification.error(response.result.message);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $scope.previewImages = function(input) {
            const maxFiles = 5; // Maximum number of images allowed
            const maxSize = 2 * 1024 * 1024; // 2MB size limit
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', , 'image/svg+xml'];
            
            if (input.files.length > maxFiles) {
                Notification.error(`You can only upload up to ${maxFiles} images.`);
                return;
            }

            if (input.files) {
                angular.forEach(input.files, function(file) {
                    let isvalid = true;
                    // Validate file type
                    if (!allowedTypes.includes(file.type)) {
                        Notification.error(`Invalid file type: ${file.name}. Only JPG, PNG, and GIF are allowed.`);
                        isvalid = false;
                    }

                    // Validate file size
                    if (file.size > maxSize) {
                        Notification.error(`File too large: ${file.name}. Max size is 2MB.`);
                        isvalid = false;
                    }

                    if (isvalid) {
                        let reader = new FileReader();
                        reader.onload = function(e) {
                            $scope.$apply(function() {
                                $scope.imagePreviews.push({ src: e.target.result, file: file });
                                $scope.selectedFiles.push(file);
                            });
                        };
                        reader.readAsDataURL(file);    
                    }
                });
            }
        };


        $scope.removeImage = function(index) {
            let image = $scope.imagePreviews[index];
        
            // If it's an existing image, mark it for deletion
            if (image.id) {
                // $scope.deletedImages.push(image.id);
                $scope.removeImageFromDatabase(image.id)
            }
        
            // Remove from preview list
            $scope.imagePreviews.splice(index, 1);
            if (image.id === undefined) {
                $scope.selectedFiles.splice(index, 1);
            }
        };

        $scope.removeImageFromDatabase = function(id) {
            commonFactory.delete('/api/banner-image-delete/' + id)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Image deleted successfully");
                                $state.go("code_tables.banner_image_edit({id : " + $state.params.id + "})");
                            }else if(response.status == 400){
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
        }
    }]);

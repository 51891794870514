angular.module('deitz')
    .controller('addFirmPartyController', ['$scope', '$rootScope', '$http', 'apiUrl', '$state', 'commonFactory', 'Notification', '$uibModalInstance', '$uibModal','case_id','id','$timeout', function ($scope, $rootScope, $http, apiUrl, $state, commonFactory, Notification, $uibModalInstance, $uibModal,case_id,id,$timeout) {

        $scope.party = {};
        $scope.party.job_case_id = case_id;
        $scope.closePopupWindow = function () {
            $uibModalInstance.close(true);
        };
        if (id) {
            $scope.title = "Edit Party"
        }
        else{
            $scope.title = "Add New Party"
        }

        //Getting Attorney Firm office_code
        $scope.attorneyofficedata = function (val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val
                }
            }).then(function (response) {
                $scope.foundFromElaw = false;
                if (response.data.foundFromElaw) {
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        $scope.onSelect = function ($item, $model, $label, tab) {            
            $scope.isSelectOrdFirm = true;
            if (!$scope.foundFromElaw) {
                $rootScope.showLoader(true);
                $scope.orderingFirm = $item;

                $http.post(apiUrl+'/api/get-attorney-list', { attorney_id : $item.id }).success(function(response){
                    $scope.attornay_list = response.result.data;
                });

                $rootScope.showLoader(false);
            }

        };
        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }else if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }else{
                return '$'+ '0.00';
            }
        };
        $scope.formatLabel = function (firm) {
            if(firm)
            {
                var label = firm.NAME +' | ('+ firm.PHONE +') | '+ firm.ADDR_LINE1 +' '+ firm.ADDR_LINE2 +' | '+ firm.CITY +' | '+ firm.source.name +' '+ firm.source.name_2 +' | YTD BILLED ('+ $scope.currencyFormat(firm.YTD_BILLED) + ')';

                if(firm.businessunit)
                {
                    label = label + ' | '+firm.businessunit.name;
                }

                return label;
            }
        };
        $scope.clearOrdFirm = function () {
            if (!$scope.isSelectOrdFirm) {
                $scope.party.attorney_id = null;
            }
        };
        
        $scope.resetOrdFirm = function () {
            if ($scope.isSelectOrdFirm) {
                $scope.attornay_list = [];
            }
        };

        if (id) {
            var id = id;
            commonFactory.get('/api/case-party/' + id)
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result) {
                        $scope.party = response.result.party;
                        $timeout(function () {
                            var data = { attorney_id: $scope.party.firm_id }
                            commonFactory.post('/api/getAttorneyfirmdata', data)
                                .success(function (response) {
                                    $scope.party.attorney_id = response.result.attorney_data || '';
                                })
                                .error(function (err) {
                                    $rootScope.showLoader(false);
                                });
        
                            
                            $timeout(function(){
                   
                                $http.post(apiUrl+'/api/get-attorney-list', { attorney_id : $scope.party.firm_id }).success(function(response){
                                    $scope.attornay_list = response.result.data;
                                });
                            },50);
        
        
                        }, 100);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        }

     

        $scope.editParty = function (party, isValid) {

            $scope.formSubmit = true;
     

            if (isValid) {
                $rootScope.showLoader(true);
                $scope.party.firm_id = $scope.party.attorney_id ? $scope.party.attorney_id.id : null;

                if (id) {
                    commonFactory.put('/api/case-party/' + id, $scope.party)
                        .success(function (response) {
                            $rootScope.showLoader(false);
                            if (response.status == 200 && response.result) {
                                Notification.success("Party update Sucessfully");
                                $scope.closePopupWindowSimple(true);
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                        });
                }
                else {
                    commonFactory.post('/api/case-party', $scope.party)
                        .success(function (response) {
                            $rootScope.showLoader(false);
                            if (response.status == 200 && response.result) {
                                Notification.success("Party Add Sucessfully");
                                $scope.closePopupWindowSimple(true);
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                        });
                    }
            }
        }

        $scope.closePopupWindowSimple = function (isChanged) {
            $uibModalInstance.dismiss({ isChanged: isChanged });
        };


    }]);

angular.module('deitz')
    .factory('commonFactory', ['$http', 'apiUrl', function($http, apiUrl) {

        var commonFactory = {};

        function convertParams(params) {
            var reqData = {};
            if (params) {
                reqData = params;
                reqData.skip = params.skip || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
                reqData.limit = params.limit || 10; // Number of entries showed per page.
                reqData.search = params.search ? params.search.value : '';
                reqData.orderColumn = params.order ? params.order[0].column : '';
                reqData.orderDir = params.order ? params.order[0].dir : '';
                if (params.id) {
                    reqData.id = params.id ? params.id : '';
                }

                reqData.excludeZeroBalDue = params.excludeZeroBalDue ? params.excludeZeroBalDue : false;
                reqData.where = params.where ? params.where : null;

                reqData.filter_reporter = params.filter_reporter;
                reqData.filter_typist = params.filter_typist;
                reqData.filter_salesperson = params.filter_salesperson;
                reqData.filter_selectedBusinessUnitIDs = params.filter_selectedBusinessUnitIDs;

                reqData.includeActive = params.includeActive;
                reqData.includeDeactive = params.includeDeactive;
            }
            return reqData;
        }

        commonFactory.get = function(url) {
            return $http.get(apiUrl + url);
        };

        commonFactory.getWithParams = function(url, params) {
            return $http({ "url": apiUrl + url, "method": "GET", "params": params });
        };

        commonFactory.getWithPaginate = function(url, params) {
            var reqData = convertParams(params);
            return $http({ "url": apiUrl + url, "method": "GET", "params": reqData });
        };

        commonFactory.post = function(url, data) {
            return $http.post(apiUrl + url, data);
        };

        commonFactory.put = function(url, data) {
            return $http.put(apiUrl + url, data);
        };

        commonFactory.delete = function(url) {
            return $http.delete(apiUrl + url);
        };

        commonFactory.showDataTable = function(url,data){
            return $http({
                method: 'POST',
                url: apiUrl + url,
                data: $.param(data),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });
        };

        commonFactory.postMultipart = function(url, data) {
            return $http.post(apiUrl + url, data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        }

        //Include validation helper
        var vh = validation_helper();

        return commonFactory;
    }]);

angular.module('deitz')
    .controller('firmGroupDshboardControllerBeta', 
        ['$scope',
         '$rootScope',
         '$http',
         'apiUrl',
         'configFactory',
         '$filter',
         'Notification',
         '$cookies',
         '$state',
         '$uibModal',
                function($scope,
                         $rootScope,
                         $http,
                         apiUrl,
                         configFactory,
                         $filter,
                         Notification,
                         $cookies,
                         $state,
                         $uibModal
                        ){
        
        $scope.formSubmit = false;
        $scope.viewDashboard = false;
        $scope.showData = false;
        $scope.dashboard = {};
        $scope.currentDate = moment().format('YYYY-MM-DD');
        

        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));

        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs;
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
        
        $scope.filterBUIds = $scope.selectedBusinessUnitIDs.length > 0 ? $scope.selectedBusinessUnitIDs.join(',') : '';
        $scope.selectedFirmGroup = [];
        $scope.filterFirmGroups = '';
        $scope.selectedAttorneys = [];
        $scope.filterAttorneys = '';
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });
        
        $http.get(apiUrl + '/api/get-dashboard-firm-groups').then(function(response) { 
            $scope.FirmGroupList = response.data.result;
        });
        
        $scope.dateFilter = {
            startDate: moment().startOf('month').format('MM-DD-YYYY'), // First day of the month
            endDate: moment().endOf('month').format('MM-DD-YYYY') // Last day of the month
        };   

        $scope.resetDateFilter = function() {
            $scope.dateFilter = {
                startDate: moment().startOf('month').format('MM-DD-YYYY'), // First day of the month
                endDate: moment().endOf('month').format('MM-DD-YYYY') // Last day of the month
            };  
        }
        $scope.dateFilterChange = function() {
            $scope.dateFilter = {
                startDate: moment($scope.dateFilter.startDate).format('MM-DD-YYYY'), // First day of the month
                endDate: moment($scope.dateFilter.endDate).format('MM-DD-YYYY') // Last day of the month
            };
        }
        //Getting Attorneys list
        $scope.attorneysList = function(val) {
            $scope.searchingAtt = true;
            let groupName = '';
            if ($scope.selectedFirmGroup.length > 0) {
                const selectedFirmGroup = $scope.selectedFirmGroup.map((FirmGroup) => FirmGroup.group_name);
                groupName = selectedFirmGroup.join(',');
            } 
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val,
                    group_name: groupName
                }
            }).then(function(response) {
                $scope.searchingAtt = false;
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.groupChange = function(){
            
            var groups = [];    
            angular.forEach($scope.selectedFirmGroup, function(value, key) {
               groups[key] = value.group_name;
            });
            
            // get firms of selected groups
            $rootScope.showLoader(true);
            $http.post(apiUrl +'/api/get-firms-of-selected-groups',{groups:groups})
            .success(function(response) {
                $rootScope.showLoader(false);
                $scope.selectedAttorneys = response.result;
                setTimeout(()=> {
                    $('tags-input .tags').css('height', '124px')
                    $('tags-input .tags').css('overflow-y', 'scroll')
                }, 500)
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });

        };

        $scope.applyFilter = function(){
            $scope.filterBUIds = $scope.selectedBusinessUnitIDs.filter(Boolean);
            $scope.filterBUIds = $scope.filterBUIds.length > 0 ? $scope.filterBUIds.join(',') : '';
            $scope.dateFilter = {
                startDate: moment($scope.dateFilter.startDate).format('MM-DD-YYYY'), // First day of the month
                endDate: moment($scope.dateFilter.endDate).format('MM-DD-YYYY') // Last day of the month
            };
            if ($scope.selectedFirmGroup.length > 0) {
                const FirmGroup = $scope.selectedFirmGroup.map((FirmGroup) => FirmGroup.group_name);
                $scope.filterFirmGroups = FirmGroup.join(',');
            }
            if ($scope.selectedAttorneys.length > 0) {
                const Attorneys = $scope.selectedAttorneys.map((Attorney) => Attorney.id);
                $scope.filterAttorneys = Attorneys.join(',');
            }
            $scope.showData = true;
            $scope.fetchData();
        }

        //get revenue year over year v/s budget chart data
        $scope.getRevenueData = function(){
            $scope.searchingRevenueData = true;
            $http.get(apiUrl+'/api/beta/get-revenue-data',{
                params: {
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.revenueData = res.result; 
                $scope.listRevenueChart();
                $scope.searchingRevenueData = false;
            });

            $http.get(apiUrl+'/api/beta/get-revenue-data-current-month',{
                params: {
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.revenueDataMonth = res.result; 
                $scope.listRevenueChartMonth();
            });
        }

        //get invoices data 
        $scope.getInvoices = function(){
            $scope.searchingInvoicesData = true;
            $http.get(apiUrl+'/api/get-invoices-firmGroup',{
                params: {
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    startDate: $scope.dateFilter.startDate,
                    endDate: $scope.dateFilter.endDate,
                    firmGroups: $scope.filterFirmGroups,
                    firms: $scope.filterAttorneys
                }
            }).success(function(res){
                $scope.invoicesOfDays = res.result.invoices.invoicesOfDays;
                $scope.invoicesOfMonths = res.result.invoices.invoicesOfMonths;
                $scope.invoicesOfYears = res.result.invoices.invoicesOfYears;
                $scope.invoicesOfDateFilter = res.result.invoices.invoicesOfDateFilter;

                $scope.invoicesReportUrl = "&MANUAL_daterange_TODATE="+ moment($scope.invoicesOfDays[0].dte_invoice).format('YYYY-MM-DD')+"&MANUAL_daterange_FROMDATE="+ moment($scope.invoicesOfDays[ $scope.invoicesOfDays.length-1].dte_invoice).format('YYYY-MM-DD');
                $scope.searchingInvoicesData = false;  
            });
        }

        //get jobs count ==================
        $scope.getJobsCount = function(){
            $scope.searchingJobsCount = true;
            $http.get(apiUrl+'/api/get-jobs-count-firmGroup',{
                params: {
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    startDate: $scope.dateFilter.startDate,
                    endDate: $scope.dateFilter.endDate,
                    firmGroups: $scope.filterFirmGroups,
                    firms: $scope.filterAttorneys
                }
            }).success(function(res){
                $scope.jobsCountOfDays = res.result.jobsCount.jobsCountOfDays;
                $scope.jobsCountOf60Weeks = res.result.jobsCount.jobsCountOf60Weeks;                
                $scope.jobsCountOf12Months = res.result.jobsCount.jobsCountOf12Months; 
                $scope.jobsCountOfDateFilter = res.result.jobsCount.jobsCountOfDateFilter; 
                $scope.searchingJobsCount = false;
            });
        }

        //get invoices Cost 
        $scope.getInvoicesCost = function(){
            $scope.searchingInvoicesDataCost = true;
            $http.get(apiUrl+'/api/get-invoices-cost-firmGroup',{
                params: {
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    startDate: $scope.dateFilter.startDate,
                    endDate: $scope.dateFilter.endDate,
                    firmGroups: $scope.filterFirmGroups,
                    firms: $scope.filterAttorneys
                }
            }).success(function(res){
                $scope.invoicesCostOfDays = res.result.invoices.invoicesOfDays;
                $scope.invoicesCostOfMonths = res.result.invoices.invoicesOfMonths;
                $scope.invoicesCostOfLastWeek = res.result.invoices.invoicesOfLastWeek;
                $scope.invoicesCostOfDateFilter = res.result.invoices.invoicesOfDateFilter; 
                $scope.searchingInvoicesDataCost = false;  
            });
        }

        //get pending jobs count
        $scope.getPendingJobsCount = function(){   
            $scope.searchingPendingJobsCount = true;
            $http.get(apiUrl+'/api/get-pending-jobs-count-firmGroup',{
                params: {
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    startDate: $scope.dateFilter.startDate,
                    endDate: $scope.dateFilter.endDate,
                    firmGroups: $scope.filterFirmGroups,
                    firms: $scope.filterAttorneys
                }
            }).success(function(res){
                $scope.pendingJobsCountOfDays = res.result.pendingJobsCount.pendingJobsCountOfDays;
                $scope.pendingJobsCountOf12Months = res.result.pendingJobsCount.pendingJobsCountOf12Months; 
                $scope.pendingJobsCountOfDateFilter = res.result.pendingJobsCount.pendingJobsCountOfDateFilter; 
                $scope.searchingPendingJobsCount = false;
                 
            });
        }

        $scope.askDashboardPass = function(isValid){ 
            $scope.formSubmit = true;
            if(isValid){
                $rootScope.showLoader(true);
                $http.post(apiUrl+'/api/auth-dashboard-pass',{'password' : $scope.dashboard.password}).success(function(res){
                    $rootScope.showLoader(false);
                    if(res.error){
                        Notification.error(res.result.message);
                    }else{
                        if(res.result.auth){

                            $cookies.put('dashboardUser', JSON.stringify($scope.dashboard.password));
                            $scope.viewDashboard = true;
                            $scope.fetchData();
                        
                        }else{
                            Notification.error('Something went wrong !');
                        } 
                    }
                });
            }
        }

        $scope.getOperationMetrics = function(){
            //get operation metrics average calculation data
            $scope.searchingOperationMetricsData = true;
            $http.get(apiUrl+'/api/get-operation-metrics-average-firmGroup',{
                params: {
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    startDate: $scope.dateFilter.startDate,
                    endDate: $scope.dateFilter.endDate,
                    firmGroups: $scope.filterFirmGroups,
                    firms: $scope.filterAttorneys
                }
            }).success(function(res){
                $scope.operationMetricsData = res.result;
                $scope.searchingOperationMetricsData = false; 
            });

            //get operation metrics monthly average
            $http.get(apiUrl+'/api/get-operation-metrics-monthly-average-firmGroup',{
                params: {
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    startDate: $scope.dateFilter.startDate,
                    endDate: $scope.dateFilter.endDate,
                    firmGroups: $scope.filterFirmGroups,
                    firms: $scope.filterAttorneys
                }
            }).success(function(res){
                $scope.operationMetricsMonthlyData = res.result;
            });
        }

        $scope.fetchData = function(){
            if($rootScope.havePermission('dashboard','view.invoices')){
                $scope.getInvoices();
            }
            if($rootScope.havePermission('dashboard','view.calendar')){
                $scope.getJobsCount();
            }
            if($rootScope.havePermission('dashboard','view.reporter.pay')){
                $scope.getInvoicesCost();
            }
            if($rootScope.havePermission('dashboard','view.wip')){
                $scope.getPendingJobsCount();
            }

            if($rootScope.havePermission('dashboard','view.trailing.days')){
                $scope.getOperationMetrics();
            }
        }

        if($state.params.token){
            configFactory.getConfiguration({ keys: ['dashboard_notification_token'] })
                .then(function (response) {
                    if($state.params.token === response.data.configurations.dashboard_notification_token){
                        $scope.viewDashboard = true;
                        // $scope.fetchData();      
                    }
                }); 
        }
        //check password is available for dashboard or not
        var dashboardUser = $cookies.get('dashboardUser');

        if(dashboardUser || $rootScope.havePermission('allow_access_without_password','allow.selective.metrics')){
            $scope.viewDashboard = true;
            // $scope.fetchData();
        }


        $scope.clearCache = function(){
            swal({
                title: "Are you sure ?",
                text: "You are going to refresh dashboard cache.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);
                    $http.get(apiUrl+'/api/beta/clear-dashboard-cache').success(function(res){
                        $rootScope.showLoader(false);
                        if(res.error){
                            Notification.error(res.result.message);
                        }else{
                            Notification.success('Refresh request added successfully ! Please Re-visit this page after 10 minutes');
                        }
                    });
                }
            });
            
        }


    }]);

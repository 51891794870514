angular.module('deitz').controller('addAssignmentStatusController', [

    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification',
    function ($scope, $state, $rootScope, codeFactory, commonFactory, Notification
    ) {

        $scope.assignment_status = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Assignment Status';

        $scope.add_assignment_status = function (assignment_status, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/assignment-status', $scope.assignment_status)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Assignment Status added successfully");
                                $state.go("code_tables.assignment_status_browse");
                            } else if (response.status == 400) {
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);

angular.module('deitz').controller('browseCaseNotesController', [
    '$scope',
    '$rootScope',
    '$state',
    'commonFactory',
    'Notification',
    '$stateParams',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    '$q',
    '$compile',
    '$uibModal',
    function (
        $scope,
        $rootScope,
        $state,
        commonFactory,
        Notification,
        $stateParams,
        DTOptionsBuilder,
        DTColumnBuilder,
        $q,
        $compile,
        $uibModal
    ) {
        $scope.includeAll = true;
        $scope.title ="Case Notes"
        $scope.dtInstance = {};
        $scope.createdRow = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                data.case_id = $state.params.id;
                commonFactory.showDataTable('/api/all-case-note', data).success(function (res) {
                    if (res.error) {
                        console.log(res.error);
                    }
                    else {
                        callback(res);
                    }
                }).error(function (err) {
                    if (err.error !== "token_not_provided") {
                        $scope.reloadDatacheck();
                    }
                });
            })
            .withOption('processing', true)
            .withLanguage({
                "sProcessing": "<img src='img/loading_bar.gif'/>"
            })
            .withOption('serverSide', true)
            .withOption('searchDelay', 500)
            .withOption('order', [1, 'desc'])
            .withPaginationType('simple_numbers')
            .withDisplayLength(10)
            .withOption('lengthMenu', [
                [5, 10, 25, 50],
                [5, 10, 25, 50]
            ])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });


        $scope.dtColumns = [
            DTColumnBuilder.newColumn('notes').withOption('defaultContent', '').withTitle('Note').withOption('width','60%').renderWith(function(data, type, full, meta){
                if(full.has_more=='1'){
                    return '<button  class="btn btn-primary btn-xs pull-right" tooltip-placement="left"  uib-tooltip="view full note" ng-click="viewFullNote('+full.id+')" type="button"><i class="fa fa-eye"></i></button> '+data;
                }
                else{
                    return data;
                }
            }),
            DTColumnBuilder.newColumn('note_date').withOption('defaultContent', '').withTitle('Note Date').renderWith(function (data, type, full, meta) {
                if(data) {
                    var dte = moment(data).format('MM/DD/YYYY');
                    if(dte == 'Invalid date'){
                        return '-';
                    }else{
                        return dte;
                    }
                } 
                else {
                    return '-';
                }

            }),
            DTColumnBuilder.newColumn('note_type').withOption('defaultContent', '').withTitle('Note Type'),
            DTColumnBuilder.newColumn('create_user.name').withOption('defaultContent', '').withTitle('Created By'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable',false)
            .renderWith(function(data, type, full, meta) {
                return '<button class="btn btn-primary btn-circle" ng-click="addCaseNote('+full.id+')" uib-tooltip="Edit"  >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button>&nbsp;&nbsp'+
                        '<a class="btn btn-circle btn-danger" ng-click="deleteCaseNote('+full.id+')" ><i class="fa fa-trash"></i></a>';

            })
        ];


        $scope.addCaseNote = function (id = null) {

            var invoiceModal = $uibModal.open({
                templateUrl: "modules/cases/add_notes_modal.html",
                controller: 'addCaseNotesController',
                resolve: {
                    case_id: function () {
                        return $state.params.id;
                    },
                    id: function () {
                        return id
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            invoiceModal.result.then(function (cbData) {
            }, function (cbData) {
                if (cbData.isChanged) {
                    $rootScope.showLoader(false);
                    $scope.reloadData();
                }
            });
        }

        $scope.viewFullNote = function (id) {
            $rootScope.showLoader(true);
            commonFactory.get('/api/case-note/' + id)
                .success(function (response) {
                    $scope.caseNote = response.result.note;
                    var noteModal = $uibModal.open({
                        templateUrl: "modules/cases/view_notes_modal.html",
                        controller: ['$scope', '$rootScope', '$uibModalInstance','note', function ($scope, $rootScope, $uibModalInstance,note) {
                            $rootScope.showLoader(false);
                            $scope.caseNote = note;
                            $scope.uibModalInstance = $uibModalInstance;
                            $scope.closePopup = function (){
                                $uibModalInstance.dismiss();
                            }
                        }],
                        resolve: {
                            note: function (){
                                return $scope.caseNote;
                            }
                        },
                        keyboard: true,
                        backdrop: true,
                        size: 'md',
                        windowClass: 'middle-width-model',
                    })
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });

        }

        $scope.deleteCaseNote = function (id) {

            swal({
                title: "Are you sure ?",
                text: "You are going to delete the note.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/case-note/' + id)
                            .success(function (response) {
                                if (response && response.result) {
                                    Notification.success(response.result.message || 'Note deleted successfully.');
                                    $scope.reloadData();
                                    $rootScope.showLoader(false);
                                } else {
                                    Notification.error(response.result.message || 'Something went wrong!');
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        };

    }]);

angular.module('deitz').controller('editPaymentTypeController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    'fromModal',
    'paymenttypeId',
    '$uibModalInstance',
    function(
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification,
        fromModal,
        paymenttypeId,
        $uibModalInstance
    ){

        $scope.paymentType = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Payment Type';
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };
        $scope.modalId = paymenttypeId;
        $scope.isAddMode = true; // Flag to indicate the add mode
        
        //Edit Mode of paymenttype.
        if ($state.params && $state.params.id) {
            var id = paymenttypeId ? paymenttypeId : $state.params.id;
            commonFactory.get('/api/payment-types/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.payment_type_detail) {
                        $scope.paymentType.name = response.result.payment_type_detail.name || '';
                        $scope.paymentType.display = response.result.payment_type_detail.display == 1 ? '1' : '0';
                        $scope.paymentType.allow_negative_payment = response.result.payment_type_detail.allow_negative_payment == 1 ? '1' : '0';
                        $scope.paymentType.slug = response.result.payment_type_detail.slug || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of PaymentType
        $scope.addPaymenttype = function(paymentType, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = paymenttypeId ? paymenttypeId : $state.params.id;
                commonFactory.put('/api/payment-types/' + id, $scope.paymentType)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Payment type updated successfully");

                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.paymentType_update.id);
                                }else{
                                    $state.go("code_tables.payment_type_browse");
                                }
                                
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);

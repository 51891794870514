angular.module('deitz').controller('addBannerImageController', [

    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification','fromModal','$uibModalInstance',
    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification,fromModal,$uibModalInstance
    ){

        $scope.bannerImage = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Banner Image';
        $scope.imagePreviews = [];
        $scope.selectedFiles = [];

        
        $scope.add_banner_image = function(bannerImage, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                let formData = new FormData();
                formData.append("image_name", bannerImage.image_name);
                formData.append("description", bannerImage.description);
                formData.append("module", bannerImage.module);
        
                // Append multiple images
                angular.forEach($scope.selectedFiles, function(file, index) {
                    formData.append("images[]", file);
                });
        
                $rootScope.showLoader(true);
                commonFactory.postMultipart('/api/banner-images', formData)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200) {
                            Notification.success("Banner Image added successfully");
                            $state.go("code_tables.banner_image_browse");
                        } else {
                            Notification.error(response.result.message);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $scope.previewImages = function(input) {
            $scope.imagePreviews = [];
            $scope.selectedFiles = [];

            const maxFiles = 5; // Maximum number of images allowed
            const maxSize = 2 * 1024 * 1024; // 2MB size limit
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/svg+xml'];
            
            if (input.files.length > maxFiles) {
                Notification.error(`You can only upload up to ${maxFiles} images.`);
                return;
            }
        
            if (input.files) {
                for (let i = 0; i < input.files.length; i++) {
                    let file = input.files[i];

                    let isvalid = true;
                    // Validate file type
                    if (!allowedTypes.includes(file.type)) {
                        Notification.error(`Invalid file type: ${file.name}. Only JPG, PNG, and GIF are allowed.`);
                        isvalid = false;
                    }

                    // Validate file size
                    if (file.size > maxSize) {
                        Notification.error(`File too large: ${file.name}. Max size is 2MB.`);
                        isvalid = false;
                    }

                    if (isvalid) {
                        // Create an Object URL for the image
                        let objectUrl = URL.createObjectURL(file);
            
                        $scope.$apply(function() {
                            $scope.imagePreviews.push({ src: objectUrl, file: file });
                            $scope.selectedFiles.push(file);
                        });   
                    }
                }
            }
        };
        
        // Function to remove selected image
        $scope.removeImage = function(index) {
            // Revoke the object URL to free up memory
            URL.revokeObjectURL($scope.imagePreviews[index].src);
        
            $scope.imagePreviews.splice(index, 1);
            $scope.selectedFiles.splice(index, 1);
        };
        

    }]);

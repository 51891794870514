angular.module('deitz').controller('jobByAccountExecutiveReportController', [
    '$scope','$state','$rootScope','$http','apiUrl','Notification','commonFactory','$window',
    function(
        $scope,$state,$rootScope,$http,apiUrl,Notification,commonFactory,$window
    ){

        $scope.report = {
            startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            endDate : moment().format('YYYY-MM-DD')
           
        };
        $scope.calculatedData = [];

        $scope.title = "Job By Account Executive Report ";
        
        //get business units
        $scope.selectedBusinessUnitIDs = [];
        $scope.selectedCoveringBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
     
        $scope.selectedRateGroupIDs = [];
        $scope.selectedsalesPersonsIds = [];
        var selectedsalesPersonsIds =  JSON.parse(localStorage.getItem('selectedsalesPersonsIds'));
        if(selectedsalesPersonsIds && selectedsalesPersonsIds.length){
            $scope.selectedsalesPersonsIds = selectedsalesPersonsIds
        }
        commonFactory.get('/api/rateCardGroups')
            .success(function (response) {
                if (response.result) {
                    $scope.rateCardGroups = response.result.rateCardGroup;
                }
            }).error(function (err) {}); 
      
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });
        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
            $rootScope.showLoader(false);
        });

        $scope.searchData = function(){

            localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
            localStorage.setItem('selectedsalesPersonsIds', JSON.stringify($scope.selectedsalesPersonsIds.filter(Boolean)));
            $scope.report.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            $scope.report.selectedCoveringBusinessUnitIDs = $scope.selectedCoveringBusinessUnitIDs.filter(Boolean);
            $scope.title = "Job By Account Executive Report for : "+ moment($scope.report.startDate).format('MM/DD/YYYY') + " to " + moment($scope.report.endDate).format('MM/DD/YYYY');
            $scope.report.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
            $scope.report.endDate= moment($scope.report.endDate).format('YYYY-MM-DD');

            $scope.report.selectedRateGroupIDs = $scope.selectedRateGroupIDs.filter(Boolean);
            $scope.report.selectedsalesPersonsIds = $scope.selectedsalesPersonsIds.filter(Boolean);
            $scope.report.downloadReport = false;

            $scope.report.downloadType = '';

            if ($scope.report.selectedRateGroupIDs.length === 0 &&  !$scope.report.selectedsalesPersonsIds.length === 0){
                Notification.error('Please select at least one Rate Card Group or Account Executive.');
                return;
            }

            $rootScope.showLoader(true);
            commonFactory.post('/api/job_by_ae_report', $scope.report)
                .success(function(response) {
                    $scope.jobs = [];
                    $rootScope.showLoader(false);
                    if(response.error){
                        Notification.error(response.result.message || 'Something went wrong!');
                    } else {
                        $scope.jobs =  response.result.jobs;
                        if($scope.jobs.length == 0){
                            Notification.error('No records found!');
                        }
                    }
                })
                .error(function(err)
                {
                    $scope.jobs = [];
                    Notification.error('Something went wrong!');
                    $rootScope.showLoader(false);
                });
        };

        $scope.exportData = function(download=false, type='') {
            
            localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
            localStorage.setItem('selectedsalesPersonsIds', JSON.stringify($scope.selectedsalesPersonsIds.filter(Boolean)));
            $scope.report.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            $scope.report.selectedCoveringBusinessUnitIDs = $scope.selectedCoveringBusinessUnitIDs.filter(Boolean);
            $scope.title = "Job By Account Executive Report for : "+ moment($scope.report.startDate).format('MM/DD/YYYY') + " to " + moment($scope.report.endDate).format('MM/DD/YYYY');
            $scope.report.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
            $scope.report.endDate= moment($scope.report.endDate).format('YYYY-MM-DD');

            $scope.report.selectedRateGroupIDs = $scope.selectedRateGroupIDs.filter(Boolean);
            $scope.report.selectedsalesPersonsIds = $scope.selectedsalesPersonsIds.filter(Boolean);

            if ($scope.report.selectedRateGroupIDs.length === 0 &&  !$scope.report.selectedsalesPersonsIds.length === 0){
                Notification.error('Please select at least one Rate Card Group or Account Executive.');
                return;
            }
            
            $scope.report.downloadReport = true;
            $scope.report.downloadType = type;

            $rootScope.showLoader(true);

            commonFactory.post('/api/job_by_ae_report', $scope.report)
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200) {
                            if (download && response.fileName) {
                                $window.open(apiUrl +'/api/download-AE-job-report?fileName='+response.fileName);
                            } else {
                                Notification.error('No records found!');
                            }
                    } else {
                        Notification.error(response.message);
                    }
                })
                .error(function(err)
                {
                    Notification.error('Something went wrong!');
                    $rootScope.showLoader(false);
                });
        };
}]);

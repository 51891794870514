angular.module('deitz').controller('assignmentStatusController', [
    '$scope', '$rootScope', '$state', 'commonFactory', 'Notification', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$compile',
    function (
        $scope, $rootScope, $state, commonFactory, Notification, DTOptionsBuilder, DTColumnBuilder, $q, $compile
    ) {

        $scope.dtInstance = {};
        $scope.compileDatatable = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function () {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function (sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value,
                    includeActive: (!$scope.includeAll && $scope.includeActive) ? $scope.includeActive : false,
                    includeInactive: (!$scope.includeAll && $scope.includeInactive) ? $scope.includeInactive : false,

                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/assignment-status', options)
                    .success(function (response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function (settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function () {
                $scope.inProgress = false;
            });

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withOption('defaultContent', '').withTitle('Status'),
            DTColumnBuilder.newColumn('sort_order').withOption('defaultContent', '').withTitle('Sort Order'),
            DTColumnBuilder.newColumn(null).withTitle('Color').notSortable()
                .renderWith(function (data, type, full, meta) {
                    if (data && data.id) {
                        return '<div class="col-2 p-3 mb-2" style="padding:5px;background-color:' + full.color_code + '"></div>';
                    }

                }),

            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
                .renderWith(function (data, type, full, meta) {
                    if (data && data.id) {
                        action = '<button ng-if="havePermission(\'assignment_status\',\'edit\')" class="btn btn-primary btn-circle" uib-tooltip="Edit" ui-sref="code_tables.assignment_status_edit({id : ' + data.id + '})" ><i class="fa fa-pencil"></i></button> &nbsp;';

                        var newActive = (full.is_active == '1') ? "0" : "1";
                        var btn = (full.is_active == '1') ? 'btn-success' : 'btn-danger';
                        var status = (full.is_active == '1') ? 'Active' : 'In Active'
                        return action + '<button  ng-if="havePermission(\'assignment_status\',\'edit\')" class="ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm ' + btn + '" ng-click="changeIsActiveStatus(' + full.id + ',\'' + newActive + '\')">' + status + '</button>';
                    }

                })

        ];



        $scope.changeIsActiveStatus = function (id, newStatus) {
            let status = newStatus == '1' ? 'active' : 'inactive';
            var page = $('#status_list').DataTable().page();
            swal({
                title: "Alert!",
                text: "Are you sure want to " + status + " this assignment status?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        var data = { id: id, is_active: newStatus };

                        commonFactory.put('/api/assignment-status/' + id, data).success(function (res) {
                            $rootScope.showLoader(false);
                            if (res.error) {
                                Notification.error(res.result.message);
                            } else {
                                Notification.success(res.result.message);
                            }
                            $('#status_list').DataTable().page(page).draw(false);
                        }).error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err.result.message);
                            $('#status_list').DataTable().page(page).draw(false);
                        });
                    }
                });

        };
    }]);

angular.module('deitz')
			.controller('editAttendeeController', [
    				'$scope', 
    				'$rootScope', 
    				'$state', 
    				'$timeout', 
    				'$http', 
    				'$uibModal',
    				'$uibModalInstance',
    				'commonFactory', 
    				'Notification', 
    				'apiUrl',
    				'id',
                    'attendee_type',
    					function(
        					$scope,
        					$rootScope,
        					$state,
        					$timeout,
        					$http,
        					$uibModal,
        					$uibModalInstance,
        					commonFactory,
        					Notification,
        					apiUrl,
                            id,
                            attendee_type
    					){

	    $scope.formData = {};
	    $scope.formSubmit = false;
        $scope.title = 'Edit';
        $scope.btnTitle = 'Save Changes';
	    $scope.attendeeID = id;
        $scope.lookupLoading = '';
        $scope.openAttendeeType = false;

        $rootScope.showLoader(true);
	    commonFactory.get('/api/meeting-attendee-info/'+ $scope.attendeeID).success(function (response) {
            $rootScope.showLoader(false);
            if (response.result) {
                
                var attendeeData = response.result.data;
                
                if(attendee_type){
                    $scope.attendee_type = 'attorney';
                }else if(attendeeData.attendee_type == 'witness'){
                    $scope.attendee_type = 'witness';
                }else if(attendeeData.attendee_type == 'other'){
                    $scope.attendee_type = 'other';
                }else if(attendeeData.attendee_type == 'queue'){
                    $scope.attendee_type = 'queue';
                }else if(attendeeData.attendee_type == 'lex-user'){
                    $scope.attendee_type = 'lex-user';
                }
                else if(attendeeData.attendee_type == 'trusted'){
                    $scope.attendee_type = 'trusted';
                }
                else{
                    $scope.attendee_type = 'attorney';
                }

                $scope.formData.attorney_id = attendeeData.attorney;

                if(attendeeData.attendee_type == 'attorney'){
                    if(attendeeData.attname_id){
                        $scope.formData.attname_and_admin = attendeeData.attname;
                    }else{
                        $scope.formData.attname_and_admin = attendeeData.depo_attorney;
                    }
                }

                if(attendeeData.attendee_type == 'admin'){
                    $scope.formData.attname_and_admin = attendeeData.contact;
                }

                $scope.formData.name = attendeeData.name;
                $scope.formData.email = attendeeData.email;
                $scope.formData.cell = attendeeData.cell;
            }
        });

	    $scope.cancel = function() {
            $uibModalInstance.close();
        }

        $scope.clearAll = function(){
            $scope.formData.attname_and_admin = null;
        }

        $scope.changeAttendeeType = function(){
            $scope.formData = {};
        }

        $scope.attorneyofficedata = function(val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.searchAttorneyAndAdminData = function(val) {
            return $http.get(apiUrl + '/api/search-attorney-and-admin', {
                params: {
                    name: val,
                    firmId: $scope.formData.attorney_id.id
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.onSelectAttorneyFirm = function ($item, $model, $label, $event) {
            if(!$scope.foundFromElaw){
                $scope.formData.attorney_id = $item;
            }else{
                swal({
                    title: "Are you sure?",
                    text: "You are about to add new firm!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true},
                function(isConfirm){
                    if(isConfirm){
                        $scope.addAttorneyFirm($item.id);
                    }
                    else{
                        $scope.formData.attorney_id = {};
                        $scope.$apply();
                    }
                });
            }
        };

        $scope.addAttorneyFirm = function(elawId){
            $rootScope.showLoader(true);
            var addAttorneyFirmModal = $uibModal.open({
                templateUrl: "modules/firms/add_attorney_firm_modal.html",
                data: {pageTitle: 'Add Firm'},
                controller: 'addfirmsController',
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                resolve : {
                    elawId : function(){
                        return elawId || null;
                    }
                }
            });

            addAttorneyFirmModal.result.then(function (firmData) {
                if (!angular.equals({}, firmData)) {
                    $scope.foundFromElaw = false;
                    $scope.onSelectAttorneyFirm(firmData);
                    $scope.clearAll();
                }
            });

            addAttorneyFirmModal.opened.then(function() {
                $rootScope.showLoader(false);
            });
        };

        $scope.editAttorneyFirm = function(attorneyFirmId){
            $rootScope.showLoader(true);
            var editAttorneyModal = $uibModal.open({
                templateUrl: "modules/firms/add_attorney_firm_modal.html",
                data: {pageTitle: 'Edit Firm'},
                controller: 'firmsController',
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                resolve : {
                    attorneyFirmId : function(){
                        return attorneyFirmId;
                    },
                    activeTab : function(){
                        return null;
                    }
                }
            });

            editAttorneyModal.result.then(function (firmData) {
                if (!angular.equals({}, firmData)) {
                    $scope.refreshFirm(firmData.id);
                }
            });

            editAttorneyModal.opened.then(function() {
                $rootScope.showLoader(false);
            });
        };

        $scope.refreshFirm = function (id) {
            if(id){
                $rootScope.showLoader(true);
                var data = { attorney_id: id }
                commonFactory.post('/api/getAttorneyfirmdata', data)
                    .success(function (response) {
                        $scope.formData.attorney_id = response.result.attorney_data || '';
                        $rootScope.showLoader(false);
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $scope.onSelectAttorneyAndAdmin = function ($item, $model, $label, $event) {
            $scope.formData.attname_and_admin = $item;
            $scope.formData.email = $item.email;
        }

        $scope.addAttorney = function(firmId, email){
            $rootScope.showLoader(true);
            var addAttorneyModal = $uibModal.open({
                templateUrl: "modules/addattorneytofirm/add_attorney_modal.html",
                data: {pageTitle: 'Add Attorney'},
                controller: 'addattorneytofirmController',
                keyboard: false,
                backdrop: false,
                size: 'md',
                resolve : {
                    attorneyFirmId : function(){
                        return firmId || null;
                    },
                    email : function(){
                        return email || null;
                    }
                }
            });

            addAttorneyModal.result.then(function (attorneyData) {
                if (!angular.equals({}, attorneyData) && attorneyData.id) {
                    var attorney_id = angular.isObject(attorneyData.attorney_id) ? attorneyData.attorney_id.id : attorneyData.attorney_id;
                    $scope.refreshFirm(attorney_id);
                    var attorneyObj = {
                        NAME: attorneyData.name,
                        attendee_type: "backoffice_attorney",
                        email: attorneyData.Email_Address,
                        id: attorneyData.id,
                        type: "attorney",
                        attorney_id: attorney_id
                    };
                    $scope.onSelectAttorneyAndAdmin(attorneyObj);
                }
            });
        };

        $scope.editAttorney = function(attorneyId){
            $rootScope.showLoader(true);
            var editAttorneyModal = $uibModal.open({
                templateUrl: "modules/addattorneytofirm/add_attorney_modal.html",
                data: {pageTitle: 'edit Attorney'},
                controller: 'editattorneytofirmController',
                keyboard: false,
                backdrop: false,
                size: 'md',
                resolve : {
                    attorneyId : function(){
                        return attorneyId || null;
                    }
                }
            });

            editAttorneyModal.result.then(function (attorneyData) {
                if (!angular.equals({}, attorneyData) && attorneyData.id) {
                    var attorney_id = angular.isObject(attorneyData.attorney_id)? attorneyData.attorney_id.id : attorneyData.attorney_id;
                    var attorneyObj = {
                        NAME: attorneyData.NAME,
                        attendee_type: "backoffice_attorney",
                        email: attorneyData.Email_Address,
                        id: attorneyData.id,
                        type: "attorney",
                        attorney_id: attorney_id
                    };
                    $scope.onSelectAttorneyAndAdmin(attorneyObj);
                }
            });
        };

        $scope.refreshAttorney = function (id) {
            if(id){
                $rootScope.showLoader(true);
                commonFactory.get('/api/geteditattorneydata/' + id)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200 && response.result && response.result.attorney_detail) {
                            var attorneyData = response.result.attorney_detail;
                            var attorneyObj = {
                                NAME: attorneyData.NAME,
                                attendee_type: "backoffice_attorney",
                                email: attorneyData.Email_Address,
                                id: attorneyData.id,
                                type: "attorney",
                                attorney_id: attorneyData.attorney_id
                            };
                            $scope.onSelectAttorneyAndAdmin(attorneyObj);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $scope.addAdmin = function(firmId) {
            var addEbtModal = $uibModal.open({
                templateUrl: 'modules/firms/ebt_modal.html',
                controller: 'ebtController',
                resolve: {
                    ebtdata: function() {
                        return { attorneyFirmId :firmId };
                    }
                }
            });

            addEbtModal.result.then(function (adminData) {
                if(adminData){
                    if (!angular.equals({}, adminData)) {
                        var adminObj = {
                            NAME: adminData.First_Name +' '+ adminData.Last_Name,
                            attendee_type: "admin",
                            email: adminData.Email_Address,
                            id: adminData.id,
                            type: "admin",
                            attorney_id: adminData.attorney_id
                        };
                        $scope.onSelectAttorneyAndAdmin(adminObj);
                    }
                }
            });
        };

        $scope.editAdmin = function(ebt_id, firmId) {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/firms/ebt_modal.html',
                controller: 'editebtController',
                resolve: {
                    ebt_value: function ebtFactory() {
                        return commonFactory.get('/api/editebtclerk/' + ebt_id);
                    },
                    index: function ebtIndex() {
                        return 0;
                    },
                    ebt_data: function() {
                        return { attorneyFirmId :firmId };
                    }
                }
            });

            modalInstance.result.then(function (adminData) {
                if(adminData){
                    if (!angular.equals({}, adminData)) {
                        var adminObj = {
                            NAME: adminData.First_Name +' '+ adminData.Last_Name,
                            attendee_type: "admin",
                            email: adminData.Email_Address,
                            id: adminData.id,
                            type: "admin",
                            attorney_id: adminData.attorney_id
                        };
                        $scope.onSelectAttorneyAndAdmin(adminObj);
                    }
                }
            });
        };

        $scope.refreshAdmin = function (id) {
            if(id){
                $rootScope.showLoader(true);
                commonFactory.get('/api/editebtclerk/' + id)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200 && response.result && response.result.ebt) {
                            var adminData = response.result.ebt;
                            var adminObj = {
                                NAME: adminData.First_Name +' '+ adminData.Last_Name,
                                attendee_type: "admin",
                                email: adminData.Email_Address,
                                id: adminData.id,
                                type: "admin",
                                attorney_id: adminData.attorney_id
                            };
                            $scope.onSelectAttorneyAndAdmin(adminObj);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $scope.lookupByEmail = function(){
            if(!$scope.formData.attorney_id && $scope.formData.email && $scope.attendee_type == 'attorney'){
                $scope.lookupLoading = 'Searching...';
                commonFactory.post('/api/lookup-by-email', { email : $scope.formData.email }).success(function(response){
                    if(response && response.result && response.result.data){
                        var res = response.result.data;
                        $scope.lookupLoading = '';
                        $scope.foundFromElaw = false;
                        $scope.onSelectAttorneyFirm(res.attorney);
                        $scope.formData.attname_and_admin = res;
                    }else{
                        $scope.lookupLoading = 'Match not found';
                        $timeout(function() {
                            $scope.lookupLoading = '';
                        }, 3000);
                    }
                });
            }else{
                $scope.lookupLoading = '';
            }
        }

        $scope.save = function(isValid){
	    	$scope.formSubmit = true;
	    	if(isValid){
                $rootScope.showLoader(true);
                $scope.formData.attendee_type = $scope.attendee_type;
	    		commonFactory.put('/api/meeting-attendee-info/'+$scope.attendeeID ,$scope.formData).success(function(response){
                    $rootScope.showLoader(false);
                    if(!response.error){
                        Notification.success(response.result.message);
                        $scope.cancel();
                    }else{
                        Notification.error(response.result.message);
                    }
                });
	    	}
	    }
}]);

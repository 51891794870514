angular.module('deitz')
    .controller('PreviewEmailTemplateController', [
        '$scope', 
        '$rootScope',
        'codeFactory' , 
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'RolesFactory',
        'commonFactory',
        '$compile',
        '$uibModal',
        '$filter',
        'apiUrl',
        'Notification',
        '$window',
        '$uibModalInstance',
        'previewContent',        
        '$http',
        '$sce',
        'emailType',
        'selected_template_name',
    function (
        $scope,
        $rootScope,
        codeFactory,
        DTOptionsBuilder,
        DTColumnBuilder,
        RolesFactory,
        commonFactory,
        $compile,
        $uibModal,
        $filter,
        apiUrl,
        Notification,
        $window,
        $uibModalInstance,
        previewContent,
        $http,
        $sce,
        emailType,
        selected_template_name) {            
            $scope.title = 'Preview';  
            $scope.formSubmit = false;   
            $scope.formData = {};  
            $scope.previewContenterror ='';
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.emailType = emailType.toLowerCase();             
          
            $scope.fetchProcessedHtml = function(jobNumber=null){               
                var payload = {   
                    previewdata: previewContent,                 
                    job_id : jobNumber,
                    template_name : selected_template_name
                };
                if(emailType == 'reptype'){
                    $http.post(apiUrl + '/api/reptypefetchprocessedhtml', payload).then(function(response) {
                    // Get processed HTML from the API response                    
                    $scope.previewContent = response.data.result.data;
                    }).catch(function(error) {
                    console.error('Error processing HTML:', error);
                    }); 
                }
                else if($scope.emailType == 'notary' || $scope.emailType == 'empty_input'){                    
                    payload.emailtype = $scope.emailType;
                    $http.post(apiUrl + '/api/fetch_empty_input_html', payload).then(function(response) {
                    // Get processed HTML from the API response                    
                    $scope.previewContent = response.data.result.data;
                    }).catch(function(error) {
                    console.error('Error processing HTML:', error);
                    }); 
                }else if(emailType == 'user'){
                    $http.post(apiUrl + '/api/userfetchprocessedhtml', payload).then(function(response) {
                    // Get processed HTML from the API response                    
                    $scope.previewContent = response.data.result.data;
                    }).catch(function(error) {
                    console.error('Error processing HTML:', error);
                    }); 
                }
                else if($scope.emailType == 'invoice'){                                        
                    $http.post(apiUrl + '/api/fetch_invoice_html', payload).then(function(response) {
                    // Get processed HTML from the API response                    
                    $scope.previewContent = response.data.result.data;
                    }).catch(function(error) {
                    console.error('Error processing HTML:', error);
                    }); 
                }
                else {
                    $http.post(apiUrl + '/api/fetchprocessedhtml', payload).then(function(response) {
                    // Get processed HTML from the API response                    
                    $scope.previewContent = response.data.result.data;
                    }).catch(function(error) {
                    console.error('Error processing HTML:', error);
                    });    
                }
            };

            if($scope.emailType == 'notary' || $scope.emailType == 'empty_input'){                
                $scope.fetchProcessedHtml();
            }

            // Call the function to fetch processed HTML on load
            
            
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.dismiss({ isChanged : true});
            }; 
            
            $scope.trustAsHtml = function(string) {
                return $sce.trustAsHtml(string);
            };

            $scope.sendjobnumber = function(isValid) {
                $scope.formSubmit = true;
                
                if (isValid) {
                    $rootScope.showLoader(true); // Show loader while processing
            
                    // Prepare the data to be sent, ensuring that `job_id` is part of `formData`
                    const payload = {
                        job_invoice_id: $scope.formData.JobNumber,
                        emailType: emailType
                    };
            
                    // Send POST request to check if job exists
                    $http.post(apiUrl + '/api/job_invoice_reptype_exist', payload).then(function(response) {
                        $rootScope.showLoader(false); // Hide loader once response is received
            
                        // Check if job exists based on response
                        if (response.data.result.data) {
                            // If the job exists, fetch the job number and store it in $scope.jobNumber
                            $scope.jobNumber = response.data.result.data; 
                            $scope.fetchProcessedHtml($scope.jobNumber); // Process HTML with job number if needed
                            $scope.previewContenterror ='';
                        } else {
                            // Handle case where job does not exist                            
                            $scope.previewContenterror = emailType + " not found. Please check the " +emailType+ " number and try again.";
                        }
                    }).catch(function(error) {
                        $rootScope.showLoader(false); // Hide loader on error
                        console.error('Error processing HTML:', error);
                    });
                }
            };
    }]);
angular.module('deitz').controller('accountExecutiveInvoiceReportController', [
    '$scope', '$state', '$rootScope', '$http', 'apiUrl', 'Notification', 'commonFactory', '$window',
    function (
        $scope, $state, $rootScope, $http, apiUrl, Notification, commonFactory, $window
    ) {

        $scope.report = {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            billerIds: []
        };
        $scope.selectedFirms = [];
        $scope.selectedJobs = [];
        $scope.invoices = [];

        //get business units
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs = JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if (selectedBusinessUnitIDs && selectedBusinessUnitIDs.length) {
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        } else {
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }


        $http.get(apiUrl + '/api/getBusinessUnit').then(function (response) {
            $scope.BusinessUnitsList = response.data.result.data;
            $scope.BusinessUnitsList.unshift({ name: 'All' });

        });

        $scope.report.totalItems = 0;
        $scope.report.currentPage = 1;
        $scope.report.limit = 10;
        $scope.report.skip = 0;

        $scope.searchData = function () {
            $rootScope.showLoader(true);

            $scope.report.skip = ($scope.report.currentPage - 1) * $scope.report.limit;

            localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
            $scope.report.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            $scope.report.attorneyFirmIds = $scope.selectedFirms.map(function (a) {
                return a.id;
            });

            $scope.report.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
            $scope.report.endDate = moment($scope.report.endDate).format('YYYY-MM-DD');

            $scope.report.jobNos = $scope.selectedJobs.map(function (a) {
                return a.JOB_NO;
            });

            $rootScope.showLoader(true);
            commonFactory.post('/api/get_ae_invoice_report', $scope.report)
                .success(function (response) {

                    $rootScope.showLoader(false);
                    if (response.error) {
                        Notification.error(response.result.message || 'Something went wrong!');
                    } else {
                        $scope.invoices = response.invoices;
                        $scope.report.totalItems = response.count;
                        if ($scope.invoices.length == 0) {
                            Notification.error('No records found!');
                        }                       

                    }
                })
                .error(function (err) {
                    $scope.jobs = [];
                    Notification.error('Something went wrong!');
                    $rootScope.showLoader(false);
                });
        };

        

        $scope.roundOff = function (input, limit) {
            var output = "";
            if (!limit || (limit < 0)) {
                limit = 0;
            }
            var fraction = Math.pow(10, limit);
            var output = Math.round(fraction * Number(input)) / fraction;
            return output;
        };

        //Getting Attorneys list
        $scope.attorneyslist = function (val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function (response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        //Getting Job list
        $scope.jobList = function (val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: val
                }
            }).then(function (response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };
    }]);

angular.module('deitz').controller('amtrustInvoiceController', [
    '$scope',
    '$rootScope',
    '$state',
    'commonFactory',
    'Notification',
    '$stateParams',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    '$q',
    '$uibModal',
    '$compile',
    '$cookies',
    '$timeout',
    '$http',
    'apiUrl',
    '$window',
    function(
        $scope,
        $rootScope,
        $state,
        commonFactory,
        Notification,
        $stateParams,
        DTOptionsBuilder,
        DTColumnBuilder,
        $q,
        $uibModal,
        $compile,
        $cookies,
        $timeout,
        $http,
        apiUrl,
        $window
    ) {
        $scope.statusFilter = JSON.parse(localStorage.getItem('statusFilter'));
        $scope.dateRange
        $scope.filterDate
        $scope.invoiceArray = {};
        $scope.selectAll = false;
        $scope.selectedInvoiceArray = [];
        $scope.typeFilter = localStorage.getItem('typeFilter') != "undefined" ? localStorage.getItem('typeFilter') : null;
        $scope.groupFilter = "AMTRUST"
        // $scope.ClaimNumberIsNull = JSON.parse(localStorage.getItem('ClaimNumberIsEmpty'));
        // $scope.FILE_NO_EMPTY = JSON.parse(localStorage.getItem('FILE_NO_EMPTY'));
        // $scope.Transcript_Empty = JSON.parse(localStorage.getItem('Transcript_Empty'));
        $scope.ststusList = ["Pending","Submitted","Requested","Paid"]
        $scope.groupList = ["AMTRUST","AIG","HOLLANDER","ALLSTATE"] 
        $scope.typeList   = ["All Invoice","Panel-Tag Invoice","Staff Invoice"]

        $scope.resetSearch = function(){
            $scope.statusFilter = null;
            $scope.dateRange = null;
            $scope.ClaimNumberIsNull = null;
            $scope.DOL_Empty = null;
            $scope.DOL_Exists = null;
            $scope.selectAll = false;
            $scope.selectedInvoiceArray = []
            $scope.ClaimFileNumberIsExists = null;
            $scope.typeFilter = null;
            $scope.groupFilter = "AMTRUST"
            $scope.invoiceArray = {};
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };
       
     
    
        $scope.copyDatas= function($event){
           
           
            navigator.clipboard.writeText($event.target.innerText)
            var table = $('#InvTable').DataTable();
 
            table.buttons.info( 'Copied !', $event.target.innerText, 3000 );
            // $('#datatables_buttons_info').fadeIn(800);
            // $('#datatables_buttons_info').fadeOut(800);
        }
        $scope.dtInstance = {};
        $scope.invData= [];
        $scope.reloadData = function () {
            $scope.selectedInvoiceArray = [];
            $scope.dtInstance.rerender();
        };

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };
        $scope.submit_invoice_popup_suc = function () {
            Notification.success('invoice submited succesfully');// DataTables' callback
            $scope.dtInstance.rerender();
        };
        $scope.update_invoice_popup = function () {
            Notification.success('invoice updated succesfully');// DataTables' callback
            $scope.dtInstance.rerender();
        };
        $scope.send_mail_popup = function () {
            Notification.success('email sent succesfully');
        };
        $scope.showInvoicePop= function (invoice) {

            invoice = $scope.invData.filter(function(e) {
                return e.INV_NO == invoice;
            });
            invoices = [{text: invoice}];
            if(!invoice[0].claim_number)
            {
                invoice[0].ClaimNumber = '';
            }
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/view_popup_amtrust.html",
                controller: 'viewGeicoInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoice;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }
        $scope.updateInvoicePop= function (invoice) {

            invoice = $scope.invData.filter(function(e) {
                return e.INV_NO == invoice;
            });
            invoices = [{text: invoice}];
            if(!invoice[0].claim_number)
            {
                invoice[0].ClaimNumber = '';
            }
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/update_popup_geico.html",
                controller: 'viewGeicoInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoice;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }
        $scope.missingDataEmailPop= function (invoice) {
            
            console.log('test 2');
            invoice = $scope.invData.filter(function(e) {
                return e.INV_NO == invoice;
              });
            invoice[0].isEmail = true
            invoices = [{text: invoice}];
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/email_popup_geico.html",
                controller: 'viewGeicoInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoice;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }

        $scope.submit_invoice = function(id, status, submission_id = null){
            let message = ''
            if (status == 'P') {
                message = 'Are you sure you want to change status submit to pending?';
            } else {
               message = 'Are you sure you want to submit this invoice?'
            }
            swal({
                    title: "Are you sure ?",
                    text: message,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1ab394",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        //$close(JOB_NO);
                        commonFactory.post('/api/update-geico-invoce-submisssion',{'INV_NO':id,'user_id':$rootScope.auth_user.id, status: status, submission_id: submission_id}).success(function (res) {
                            $rootScope.showLoader(false);
                            if (res.error)
                            {
                                $scope.reloadData();
                            }
                            else{ 
                                Notification.success(res.result.message);// DataTables' callback
                                // location.reload(); 
                                $scope.reloadData();
                            }
                        }).error(function (err) {
                         $rootScope.showLoader(false);
                            if (err.error)
                            $scope.reloadData();
                        });
                    } else {
                        // $uibModalInstance.close(false);
                    }
                });
        };

        $scope.submit_multiple_invoice = function(id){
            swal({
                    title: "Are you sure ?",
                    text: "Are you sure you want to submit "+$scope.selectedInvoiceArray.length+" invoice(s)?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1ab394",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        //$close(JOB_NO);
                        var reqData = [];
                        $scope.selectedInvoiceArray.forEach(element => {
                            reqData.push({'INV_NO':element.INV_NO,'user_id':$rootScope.auth_user.id});

                        });
                       
    
                        commonFactory.post('/api/update-multiple-invoce-submisssion',{"data":reqData}).success(function (res) {
                            $rootScope.showLoader(false);
                            if (res.error)
                            {
                                $scope.reloadData();
                            }
                            else{ 
                                // $scope.selectedInvoiceArray = []
                                Notification.success(res.result.message);// DataTables' callback
                                // location.reload(); 
                                $scope.reloadData();
                            }
                        }).error(function (err) {
                         $rootScope.showLoader(false);
                            if (err.error)
                            $scope.reloadData();
                        });
                    } else {
                        // $uibModalInstance.close(false);
                    }
                });
        };

        $scope.downloadSelectedLedesFile = function () {
            $rootScope.showLoader(true);
            commonFactory.post('/api/get-multiple-ledas', { "invoice_data": $scope.selectedInvoiceArray }).success(async function (res) {
                $rootScope.showLoader(false);
                sourceXml = await $scope.fetchXml(res)
                $scope.createDownloadableFile(sourceXml, 'bulk_ledes_file.xml');
               
            }).error(function (err) {
                $rootScope.showLoader(false);
               
            });


        };
        $scope.fetchXml =async function(url) {
            const response = await fetch(url);
            if (!response.ok) throw new Error('HTTP error! status: ' + response.status);
            return await response.text();
          }
          $scope.ClearMarked = function() {
                $scope.selectAll = false;
                $scope.selectedInvoiceArray = []
                $scope.invoiceArray = {};

                angular.forEach($scope.invData, function (value, key)
                {
                    $scope.invoiceArray[value.invoiceID] = value;
                    $scope.invoiceArray[value.invoiceID].is_checked = (typeof is_checked === 'undefined') ? false : true;
                    
                    if (!$scope.invoiceArray[value.invoiceID].is_checked) {
                        $scope.selectAll = false;
                    }
                });
                // $scope.$apply();
          }
         $scope.downloadInvoice= function(fileUrl){
                var a = document.createElement("a");
                var token = $cookies.get("token");
                a.href = fileUrl+'?token='+token;
                a.download = fileUrl.substring(fileUrl.lastIndexOf('/')+1);
                a.click();
         }
        
        $scope.SelectInvoice = function (is_checked, invoiceNo,ledes_invoice) {

            const invoiceObject = {INV_NO: invoiceNo,file: ledes_invoice};
            const index = $scope.selectedInvoiceArray.findIndex(o =>
                 o.INV_NO == invoiceNo
            );


            if (is_checked) {
                if (typeof index == 'undefined' || index <= -1) {
                    $scope.selectedInvoiceArray.push(invoiceObject);
                }
            }
            else {

                if (typeof index !== 'undefined' && index > -1) {
                    $scope.selectedInvoiceArray.splice(index, 1);
                }
            }
        }

        $scope.exportCounselLink = function() {
            let data = {}
            localStorage.setItem('status', $scope.statusFilter);
                
            if($scope.statusFilter){
                data.STATUS = $scope.statusFilter;
            }
            // localStorage.setItem('group', $scope.groupFilter);
            
            // if($scope.groupFilter){
            //     data.group = $scope.groupFilter;
            // }else{
            //     data.group = "AMTRUST";
            // }
            localStorage.setItem('status', $scope.statusFilter);
            
            if($scope.statusFilter){
                data.STATUS = $scope.statusFilter;
            }
            // localStorage.setItem('dateRange', $scope.dateRange);
            function isValidDate(dateObject){
                return new Date(dateObject).toString() !== 'Invalid Date';
            }
            if($scope.dateRange){
                
                data.startDate = moment($scope.dateRange.startDate).format('YYYY-MM-DD'), 
                data.endDate = moment($scope.dateRange.endDate).format('YYYY-MM-DD') 
                if(isValidDate($scope.dateRange.startDate) && isValidDate($scope.dateRange.endDate)){
                    data.filterDate = true
                }else{
                    data.filterDate = false

                }
            }
            
            //Exists
            
            localStorage.setItem('DOL_Empty', $scope.DOL_Empty);
            if($scope.DOL_Empty){
                
                data.DOL_Empty = true;
            }

            localStorage.setItem('DOL_Exists', $scope.DOL_Exists);
            if($scope.DOL_Exists){
                
                data.DOL_Exists = true;
            }

            localStorage.setItem('ClaimFileNumberIsExists', $scope.ClaimFileNumberIsExists);
            if($scope.ClaimFileNumberIsExists){
                
                data.ClaimFileNumberIsExists = true;
            }
            //Empty
            localStorage.setItem('ClaimNumberIsEmpty', $scope.ClaimNumberIsEmpty);
            if($scope.ClaimNumberIsEmpty){
                data.ClaimNumberIsEmpty = true;
            }
            localStorage.setItem('FILE_NO_EMPTY', $scope.FILE_NO_EMPTY);
            if($scope.FILE_NO_EMPTY){
                data.FILE_NO_EMPTY = true;
            }
            localStorage.setItem('typeFilter', $scope.typeFilter);
            data.InvoiceGroup = null;
            if($scope.typeFilter){
                if($scope.typeFilter == 'All Invoice'){
                    data.InvoiceGroup = 'All';
                }
                if($scope.typeFilter == 'Panel-Tag Invoice'){
                    data.InvoiceGroup = 'Tag';
                }
                if($scope.typeFilter == 'Staff Invoice'){
                    data.InvoiceGroup = 'UnTag';
                }
            }
            
            data.invoiceNumbers = $scope.selectedInvoiceArray.map(invoice => invoice.INV_NO);

            commonFactory.post('/api/invoice/export-counsel-link', data).success(function (response) {
                $rootScope.showLoader(false);
                if (response.status == 200) {
                    if (response.result.fileName) {
                        $window.open(apiUrl +'/api/download-counsel-link-report?fileName='+response.result.fileName);
                    }
                }
            }).error(function (err) {
                $rootScope.showLoader(false);
            });
        }
        
          // Function to create a temporary downloadable file
           $scope.createDownloadableFile= async function(content, filename) {
            const blob = new Blob([content], { type: 'text/xml' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
              document.body.removeChild(a);
              URL.revokeObjectURL(url);
            }, 0);
          }
        $scope.headerCompiled = false;
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                localStorage.setItem('status', $scope.statusFilter);
                if($scope.statusFilter){
                    data.STATUS = $scope.statusFilter;
                }
                localStorage.setItem('group', $scope.groupFilter);
                
                if($scope.groupFilter){
                    data.group = $scope.groupFilter;
                }else{
                    data.group = "AMTRUST";
                }
                localStorage.setItem('status', $scope.statusFilter);
                
                if($scope.statusFilter){
                    data.STATUS = $scope.statusFilter;
                }
                // localStorage.setItem('dateRange', $scope.dateRange);
                function isValidDate(dateObject){
                    return new Date(dateObject).toString() !== 'Invalid Date';
                }
                if($scope.dateRange){
                    
                    data.startDate = moment($scope.dateRange.startDate).format('YYYY-MM-DD'), 
                    data.endDate = moment($scope.dateRange.endDate).format('YYYY-MM-DD') 
                    if(isValidDate($scope.dateRange.startDate) && isValidDate($scope.dateRange.endDate)){
                        data.filterDate = true
                    }else{
                        data.filterDate = false

                    }
                }
               
                //Exists
                
                localStorage.setItem('DOL_Empty', $scope.DOL_Empty);
                if($scope.DOL_Empty){
                    
                    data.DOL_Empty = true;
                }

                localStorage.setItem('DOL_Exists', $scope.DOL_Exists);
                if($scope.DOL_Exists){
                    
                    data.DOL_Exists = true;
                }

                localStorage.setItem('ClaimFileNumberIsExists', $scope.ClaimFileNumberIsExists);
                if($scope.ClaimFileNumberIsExists){
                    
                    data.ClaimFileNumberIsExists = true;
                }
                //Empty
                localStorage.setItem('ClaimNumberIsEmpty', $scope.ClaimNumberIsEmpty);
                if($scope.ClaimNumberIsEmpty){
                    data.ClaimNumberIsEmpty = true;
                }
                localStorage.setItem('FILE_NO_EMPTY', $scope.FILE_NO_EMPTY);
                if($scope.FILE_NO_EMPTY){
                    data.FILE_NO_EMPTY = true;
                }
                localStorage.setItem('typeFilter', $scope.typeFilter);
                data.InvoiceGroup = null;
                if($scope.typeFilter){
                    if($scope.typeFilter == 'All Invoice'){
                        data.InvoiceGroup = 'All';
                    }
                    if($scope.typeFilter == 'Panel-Tag Invoice'){
                        data.InvoiceGroup = 'Tag';
                    }
                    if($scope.typeFilter == 'Staff Invoice'){
                        data.InvoiceGroup = 'UnTag';
                    }
                }
                
                // map your server's response to the DataTables format and pass it to
                if(data.search.value.length ){
                    $("#InvTable").addClass("blur-bg");
                }else{
                    $rootScope.showLoader(true);
                }
                commonFactory.showDataTable('/api/get-amtrust-invoce', data).success(function (res) {
                    $("#InvTable").removeClass("blur-bg");
                   $rootScope.showLoader(false);
                   if (res.error)
                   {
                       $scope.reloadData();
                   }
                   else{ // DataTables' callback
                    $scope.invData = res.data
                    angular.forEach($scope.invData, function (value, key)
                    {
                        value.claim_number = value.ClaimNumber;
                        $scope.invData.key = value;
                    });
                    $scope.selectAll = false;
                    $scope.invoiceArray = {};
                    callback(res);
                   }
               }).error(function (err) {
                $("#InvTable").removeClass("blur-bg");
                $rootScope.showLoader(false);
                   if (err.error)
                   $scope.reloadData();
               });
            })
            .withOption('processing', true)
            .withOption('stateSave', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [ 4, 'desc' ])
            .withOption('createdRow', $scope.createdRow)
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'CodetypeDetails' },
                { extend: 'pdf', title: 'CodetypeDetails' }, {
                    extend: 'print',
                    customize: function(win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ])
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            }); ;
        $scope.dtColumns = [];
        var titleHtml = '<input type="checkbox" class="layout-align-xl-center-center" icheck ng-model="selectAll" ng-change="toggleAllInvoice(selectAll)">';

        $scope.dtColumns.push(

            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable()
            .renderWith(function (data, type, full, meta) {

                const is_checked =$scope.selectedInvoiceArray.filter(function (o) {
                    return o.INV_NO == full.INV_NO;
                })[0];

                $scope.invoiceArray[full.invoiceID] = full;
                $scope.invoiceArray[full.invoiceID].is_checked = (typeof is_checked === 'undefined') ? false : true;
                
                if (!$scope.invoiceArray[full.invoiceID].is_checked) {
                    $scope.selectAll = false;
                } else {
                    $scope.selectAll = true;
                }

                return '<input type="checkbox" icheck ng-model="invoiceArray[' + full.invoiceID + '].is_checked" ng-change="SelectInvoice(invoiceArray[' + full.invoiceID + '].is_checked,' + full.INV_NO + ',\''+full.ledes_invoice_filename+'\')">';
            }).withOption('searchable', false),
            DTColumnBuilder.newColumn('ATTORNEY_tbl.SHORT_NAME').withOption('defaultContent', '').withOption('searchable', true).withTitle('Attorney Firm').renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return '<a ui-sref="firms.manage.step_one({id : ' + full.attorney_firms.id + '})" target="_blank">'+full.attorney_firms.SHORT_NAME + '</a><span ondblclick="copyData('+full.attorney_firms.SHORT_NAME.slice(0, 12)+')" class="m-l-md label label-primary">Default</span>';
                }
                else{
                    return "<div uib-tooltip='"+full.attorney_firms.NAME+"' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'><a ui-sref='firms.manage.step_one({id : " + full.attorney_firms.id + "})' target='_blank'>{{'"+full.attorney_firms.NAME.slice(0, 16)+"'}}</a></div>"
                }
            }),
            DTColumnBuilder.newColumn('INV_NO').withOption('defaultContent', '').withTitle('Invoice Number').renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return '<a ui-sref=" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })" target="_blank">'+data + '</a><span class="m-l-md label label-primary">Default</span>';
                }
                else{
                    return "<div uib-tooltip='Double click to copy' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'><a ui-sref='invoice.edit({invoice_id : " + full.invoiceID + " })' target='_blank'>{{'"+data+"'}}</a></div>"
                }
            }),
            DTColumnBuilder.newColumn('DIARY_tbl.LOSS_DT').withOption('defaultContent', '').withTitle('Date of Loss').renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return full.diary_data.LOSS_DT + '<span class="m-l-md label label-primary">Default</span>';
                }
                else{
                    return "<div uib-tooltip='Double click to copy' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+full.diary_data.LOSS_DT+"'}}</div>"
                }
            }),
            DTColumnBuilder.newColumn('dte_invoice').withOption('defaultContent', '').withTitle('Invoice Date').renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else{
                    return "<div uib-tooltip='Double click to copy' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"'}}</div>"
                }
            }),
            DTColumnBuilder.newColumn('INV_AMT').withOption('defaultContent', '').withTitle('Invoice Amount').renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else{
                    return "<div uib-tooltip='Double click to copy' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"'| number : 2}}</div>"
                }
            }),
            DTColumnBuilder.newColumn('INV_BAL_DUE').withOption('defaultContent', '').withTitle('Invoice Balance Due').renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else{
                    return "<div uib-tooltip='Double click to copy' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data + "'| number : 2}}</div>"
                }
            }),
             DTColumnBuilder.newColumn('original_invoice_no').withOption("width", "6%").withOption('defaultContent', '').withTitle('Original Invoice Number').renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else{
                    return "<div uib-tooltip='Double click to copy' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"'}}</div>"
                }
            }),
            DTColumnBuilder.newColumn('ClaimNumber').withOption('defaultContent', '').withTitle('Claim Number').renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                if(data){
                    var returnData =  "<div  uib-tooltip='Double click to copy' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top' ><span >{{'"+data+"'}}</span></div>"
                    if(full.invoice_submission_email_log){
                        if(full.invoice_submission_email_log.is_bad_claim_number == 1 && !full.invoice_submission_email_log.ClaimNumber){
                            returnData =  "<div style='color: darkorchid;' uib-tooltip='Requested on "+full.invoice_submission_email_log.created_at+" To "+full.invoice_submission_email_log.to_email+" (Bad Number)' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+" Requested on "+full.invoice_submission_email_log.created_at+"'}}</div>"
                        }
                        else{
                            if(full.invoice_submission_email_log.ClaimNumber == full.ClaimNumber){
                                returnData =  "<div style='color: darkorchid;' uib-tooltip='Requested on "+full.invoice_submission_email_log.created_at+" To "+full.invoice_submission_email_log.to_email+" (Bad Number)' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+" Requested on "+full.invoice_submission_email_log.created_at+"'}}</div>"
                            }else{
                                returnData =  "<div style='color: darkorchid;' uib-tooltip='Requested on "+full.invoice_submission_email_log.created_at+" To "+full.invoice_submission_email_log.to_email+" (Ok)' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+" OK'}}</div>"
                            }
                        }
                    }
                    return returnData
                }
                else{
                    if(full.invoice_submission_email_log){
                        return "<div style='color: darkorchid;' uib-tooltip='Requested on "+full.invoice_submission_email_log.created_at+" To "+full.invoice_submission_email_log.to_email+"' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>Requested on {{'"+full.invoice_submission_email_log.created_at+"'}}</div>"
                    }
                    else{
                        return "";
                    }
                }
            }),
            DTColumnBuilder.newColumn('FILE_NO').withOption('defaultContent', '').withTitle('File Number').renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else{
                    if(data){
                        return "<div  uib-tooltip='Double click to copy' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top' ><span >{{'"+data+"'}}</span></div>"
                    }
                    else{
                        if(full.invoice_submission_email_log){
                            return "<div style='color: darkorchid;' uib-tooltip='Requested on "+full.invoice_submission_email_log.created_at+" To "+full.invoice_submission_email_log.to_email+"' ng-dblclick='copyDatas($event)'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>Requested on {{'"+full.invoice_submission_email_log.created_at+"'}}</div>"
                        }
                        else{
                            return "";
                        }
                    }
                }
            }), 
            DTColumnBuilder.newColumn('STATUS').withOption('defaultContent', '').withTitle('Status').renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else{
                    if(full.STATUS == 'C'){
                        return '<button class="btn btn-danger" >' +
                        'Paid' +
                        '</button> &nbsp;';                
                    }
                    if(full.STATUS == 'P' && !full.invoice_submission){
                        return `<button class="btn btn-success" ng-click="submit_invoice(${full.INV_NO},'S')" >` +
                        'Pending' +
                        '</button> &nbsp;';                
                    }
                    if(full.STATUS == 'P' && full.invoice_submission){
                        return `<button class="btn btn-warning" ng-click="submit_invoice(${full.INV_NO},'P', ${full.invoice_submission.id})" >` +
                        'Submitted' +
                        '</button> &nbsp;';                
                    }
                }
            }),
            DTColumnBuilder.newColumn('ledes_invoice').withOption('defaultContent', '').withOption('searchable', false).withOption("width", "8%").withTitle('Ledes 2000 File(click to download)').notSortable().renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else{
                  
                    return '<a ng-click="downloadInvoice(\''+ data +'\')" id=ledes_'+full.INV_NO+'> <button class="btn btn-xs btn-default">'+'<i class="fa fa-file-o" aria-hidden="true"></i>'+'</button></a> &nbsp;';
                  
                }
            }),
         
            //DTColumnBuilder.newColumn('slug').withOption('defaultContent', '').withTitle('Slug').notSortable(),
            DTColumnBuilder.newColumn('legacy_invoice_id').withOption('defaultContent', '').withTitle('Legacy No').withOption('searchable', true).notSortable(),
            DTColumnBuilder.newColumn('diary.businessunit.name').withTitle('Business Unit').withOption('searchable', false).withOption('defaultContent', '-').withOption("width", "6%"),
            DTColumnBuilder.newColumn('id').withTitle(' Actions ').withOption('searchable', false).notSortable()
            .renderWith(function(data, type, full, meta) {
               
                    return '&nbsp;&nbsp;<button class="btn btn-xs btn-default"  title="Invoice Details"   ng-click="showInvoicePop(' + full.INV_NO + ')" >' +
                        '<i class="fa fa-eye text-navy"></i>' +
                        '</button>&nbsp;&nbsp;<button class="btn btn-xs btn-default" title="Update Invoice Details"  ng-click="updateInvoicePop(' + full.INV_NO + ')" >' +
                        '<i class="fa fa-pencil-square-o text-navy"></i>'
                        +
                        '</button> &nbsp;&nbsp;<button class="btn btn-xs btn-default" title="Email Client for Invoice Details"  ng-click="missingDataEmailPop(' + full.INV_NO + ')" >' +
                        '<i class="fa  fa-envelope-o text-navy"></i>' +
                        '</button> '
                         +'<a class="btn btn-xs btn-default" title="invoice"  ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })" target="_blank"> <i class="fa  fa-envelope text-navy"></i></a>';

            })
        );

        $scope.toggleAllInvoice = function (isChecked) {
            angular.forEach($scope.invoiceArray, function (value, key) {
                $scope.invoiceArray[key].is_checked = isChecked;
                if (isChecked) {
                    if (!$scope.selectedInvoiceArray.find((invoce) => invoce.INV_NO === value.INV_NO)) {
                        $scope.selectedInvoiceArray.push({ INV_NO: value.INV_NO, file: value.ledes_invoice_filename });
                    }
                } else {
                    const index = $scope.selectedInvoiceArray.findIndex(o =>
                        o.INV_NO == value.INV_NO
                    );
                    if (typeof index !== 'undefined' && index > -1) {
                        $scope.selectedInvoiceArray.splice(index, 1);
                    }
                }
            });
        };
    }]);   



   
angular.module('deitz')
    .controller('editUserController', ['$scope', '$rootScope', '$http','apiUrl', 'UsersFactory', '$state', '$stateParams', 'RolesFactory', '$timeout', 'Notification', 'loginFactory','$uibModal', function ($scope, $rootScope, $http,apiUrl,UsersFactory, $state, $stateParams, RolesFactory, $timeout, Notification, loginFactory,$uibModal) {

        $scope.validations = loginFactory.validations;
        $scope.messages = loginFactory.messages;

        $scope.page_title = $state.current.data.pageTitle;
        $scope.user = {
            role: null
        };
        $scope.UserId = $stateParams.id;
        $scope.btnText = "Update";

        $scope.selectedBusinessUnitIDs = [];
        $scope.roles = [{ id: null, name: "Select Role" }];
        $scope.models = {
            selected: null,
            BussinesUnit: [],
            Role: [],
            SelectedBussinesUnit: [],
            SelectedRole: []
            
        };   
        $scope.depo_user = null;
        $scope.lastActivatedDate = null;
        $scope.lastInActivatedDate = null;
        $scope.isResourceAvailable = false;

        $scope.auditUserPopup = {
            isOpen: true,
            open: function open() {
              $scope.auditUserPopup.isOpen = true;
              $scope.auditUserPopup.data = 'Hello!';
            },
            close: function close() {
              $scope.auditUserPopup.isOpen = false;
            }
          };
    
          
          
        $http.get(apiUrl + '/api/additionalinfo_list').then(function(response) { 
            $scope.additionalinfo_list = response.data.result.data;  
        });
        // Generate initial model
        // for (var i = 1; i <= 3; ++i) {
        //     $scope.models.lists.A.push({label: "Item A" + i});
        //     $scope.models.lists.B.push({label: "Item B" + i});
        // }
        
        // Model to JSON for demo purpose
        $scope.$watch('models', function(model) {
            $scope.modelAsJson = angular.toJson(model, true);
        }, true);
  
        $http.post(apiUrl +'/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
        });

        $scope.markAsResource = function () {
        
            swal({
                title: "Mark As Resource",
                text: "First We Need To Add Resource",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Add",
                closeOnConfirm: true
            },
                function (isConfirm) {
                    // $state.go('firms.add.step_one_add', { elawId : $item.id});
                    if (isConfirm) {
                        params = {
                            user_id : $state.params.id,
                            email : $scope.user.email,
                            name  : $scope.user.name
                        };
                        localStorage.setItem('representative_user_id',$state.params.id);
                        localStorage.setItem('representative_email',$scope.user.email);
                        localStorage.setItem('representative_name',$scope.user.name);
                       
                        var url = $state.href('representative.reporter.step_one');
                       
                        window.open(url, '_blank');
                    }
                    else {
                        $scope.user.mark_resource = false;
                       
                        
                    }
                });

                
        }

        // RolesFactory.getAllRole().success(function (res) {
        //     if (res) {
        //         res.forEach(function (e) {
        //             $scope.roles.push(e);
        //             $scope.models.Role.push(e)
        //         });
        //     }
        // }).error(function (err) {
        //     $scope.roles = [];
        // });

        // $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
        //     $scope.BusinessUnits = response.data.result.data;  
        //     $scope.models.BussinesUnit =  $scope.BusinessUnits         
        //     // console.log($scope.BusinessUnits,response);           
        //  }); 

        UsersFactory.getUser($scope.UserId).success(function (response) {
            $rootScope.showLoader(true);
            $scope.user = response.data;
            $scope.user.is_production_person= (response.data.is_production_person) ? true :false;
            $scope.user.is_sales_manager= (response.data.is_sales_manager) ? true :false;
            if($scope.user.resourceWithSameEmail){
                $scope.isResourceAvailable = true;
            }
            if($scope.user.resources && $scope.user.resources.additionalinfo.length){
                           
                           
                            
                $scope.user.additionalinfoIds = []
                inx = 0
                $scope.user.resources.additionalinfo.forEach(element => {
                    $scope.user.additionalinfoIds[inx] =element.additional_info_id
                    inx++
                });
                
                
            }
            // $scope.models.SelectedRole = $scope.user.roles;
       //assigne role and business unit
            RolesFactory.getAllRole().success(function (res) {
                if (res) {
                    res.forEach(function (e) {
                        console.log($scope.user.role_business_unit.findIndex(x => x.role_id == e.id))
                       
                        $scope.roles.push(e);
                        $scope.models.Role.push(e)
                        
                    });
                    $scope.user.role_business_unit.forEach(function (e) {
                        
                            $scope.models.SelectedRole.push(res[res.findIndex(x => x.id == e.role_id)])
                       
                        
                    });
                }
            }).error(function (err) {
                $scope.roles = [];
            });

            var req_data = {};
            req_data.email = $scope.user.email;
            // getting depo user api
            $http.post(apiUrl + '/api/users/depositionnet-user', req_data)
            .success(function(depo_user) { 
                $scope.depo_user = depo_user.user;    
         
                if($scope.depo_user.can_add_edit_other_user == null || $scope.depo_user.can_add_edit_other_user == ''){
                    $scope.depo_user.can_add_edit_other_user = 'Y';
                }

                if($scope.depo_user.is_reset_password_enabled == null || $scope.depo_user.is_reset_password_enabled == ''){
                    $scope.depo_user.is_reset_password_enabled = 'Y';
                }

                if($scope.depo_user.allow_download_transcripts == null || $scope.depo_user.allow_download_transcripts == ''){
                    $scope.depo_user.allow_download_transcripts = 'N';
                }

                if($scope.user != null){
                    // getting all depo permission lists
                    $http.get(apiUrl + '/api/depositionnet-permissions')
                        .success(function(depo_perms) { 
                            $scope.depo_permissions = depo_perms.data;
                        });

                    $http.get(apiUrl + '/api/usa-timezones')
                        .success(function(depo_tz) { 
                            $scope.depo_timezones = depo_tz.data;
                        });
                }
            
            });

            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnits = response.data.result.data;  
                // $scope.models.BussinesUnit =  $scope.BusinessUnits
                if ($scope.BusinessUnits) {
                    $scope.BusinessUnits.forEach(function (e) {
                       
                        if($scope.user.role_business_unit.findIndex(x => x.business_unit_id == e.id)!= -1){
                            // $scope.models.SelectedBussinesUnit.push(e)
                           
                        }
                        else{
                            $scope.models.BussinesUnit.push(e)
                        }
                    });
                    $scope.user.role_business_unit.forEach(function (e) {
                        
                        $scope.models.SelectedBussinesUnit.push($scope.BusinessUnits[$scope.BusinessUnits.findIndex(x => x.id == e.business_unit_id)])
                   
                    
                });
                }        
                // console.log($scope.BusinessUnits,response);           
            }); 
            $rootScope.showLoader(false);

        }).error(function (error) {
            Notification.error(error.error);
            // notify({
            //     message: error.error,
            //     classes: 'alert alert-danger',
            //     templateUrl: 'views/common/notify.html',
            //     duration: 100
            // });
            $state.go('users.browse');
        });

        $scope.redirectToResource = function(){
            if($scope.user.resourceWithSameEmail){
                var resource = $scope.user.resourceWithSameEmail;
                var repType = 'reporter';
                if(resource.REP_TYP == 'R'){
                    repType = 'reporter';
                }else if (resource.REP_TYP == 'S'){
                    repType = 'sales';
                }else if(resource.REP_TYP == 'T'){
                    repType = 'typist';
                }else if (resource.REP_TYP == 'SC'){
                    repType = 'scopist';
                }
                
                $state.go('representative.'+ repType +'.step_one', {id : $scope.user.resourceWithSameEmail.id});
            }
        }

        $scope.submitRole = function (valid) {

            if (valid && $scope.models.SelectedBussinesUnit.length != 0 && $scope.models.SelectedBussinesUnit.length == $scope.models.SelectedRole.length) {
                $scope.loadingDemo = true;
                $scope.user.SelectedBussinesUnit = $scope.models.SelectedBussinesUnit
                $scope.user.SelectedRole = $scope.models.SelectedRole
                $scope.user.password_expiration_date = moment($scope.user.password_expiration_date).format('YYYY-MM-DD');;
                if($scope.depo_user != null){
                    $scope.user.is_depouser = true;
                    $scope.user.uid = $scope.depo_user.uid;
                    $scope.user.SelectedDepoPermission = $scope.depo_user.perms;
                    $scope.user.send_job_confirmation = $scope.depo_user.send_job_confirmation;
                    $scope.user.can_add_edit_other_user = $scope.depo_user.can_add_edit_other_user;
                    $scope.user.is_reset_password_enabled_depo = $scope.depo_user.is_reset_password_enabled;
                    $scope.user.timezone = $scope.depo_user.timezone;
                    $scope.user.password_expiration_date = moment($scope.depo_user.password_expiration_date).format('YYYY-MM-DD');;
                    $scope.user.allow_download_transcripts = $scope.depo_user.allow_download_transcripts;
                }
                
                UsersFactory.updateUser($scope.UserId, $scope.user).success(function (response) {
                    Notification.success(response.success);
                    // notify({
                    //     message: response.success,
                    //     classes: 'alert alert-success',
                    //     templateUrl: 'views/common/notify.html',
                    //     duration: 100
                    // });
                    $state.go('users.browse');
                }).error(function (error) {
                    $scope.loadingDemo = false;
                    Notification.error(error.error);
                    // notify({
                    //     message: error.error,
                    //     classes: 'alert alert-danger',
                    //     templateUrl: 'views/common/notify.html',
                    //     duration: 100
                    // });
                });
            }
            else if($scope.models.SelectedBussinesUnit.length == 0){
                Notification.error('Please Add At least 1 Business Unit');
            }
            else if($scope.models.SelectedBussinesUnit.length < $scope.models.SelectedRole.length){
                Notification.error('Please Assign Business Unit For Role');
            }
            else if($scope.models.SelectedBussinesUnit.length > $scope.models.SelectedRole.length){
                Notification.error('Please Assign Role For Business Unit');
            }
        };
        $scope.removeBussinesUnit = function (index) {
            console.log(index);
           
            if($scope.models.SelectedBussinesUnit.length > index){
                $scope.models.BussinesUnit.push($scope.models.SelectedBussinesUnit[index])
            $scope.models.SelectedBussinesUnit.splice(index,1)}
            $scope.models.SelectedRole.splice(index,1)
        };


        $scope.getUserAuditLogs = function(){
            $scope.auditloading=true;
            $scope.userAuditLogs = [];
            $http.get(apiUrl + '/api/users/get-user-audit-logs/'+$state.params.id).success(function (response) {
                $scope.userAuditLogs = response.result.data;
                let lastActivated = $scope.userAuditLogs.find(o => o.description === 'User status changed from InActive to Active');
                if(lastActivated){
                    $scope.lastActivatedDate=$scope.formatDate(lastActivated.updated_date);
                }
                let lastInActivated = $scope.userAuditLogs.find(o => o.description === 'User status changed from Active to InActive');
                if(lastInActivated){
                    $scope.lastInActivatedDate=$scope.formatDate(lastInActivated.updated_date);
                }
                $scope.auditloading=false;
            });
        }
        $scope.getUserAuditLogs();
        $scope.formatDate = function(date){
            return moment(date).format('MM/DD/YYYY hh:mm:ss a');
        }

        $scope.userAuditParamsModal = function(params){
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/diary/addcase/diary_logs.html',
                controller: function($scope, params, $uibModalInstance){
                    $scope.modal_title = 'Updated Fields';
                    $scope.updated_fields_list = JSON.parse(params);
                    $scope.cancel = function(isChanged) {
                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };
                },
                resolve : {
                    params : function(){
                        return params;
                    }
                },
                size: 'lg',
            });
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });
        };
    }]);
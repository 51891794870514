angular.module('deitz')
    .controller('dailyCalendarAuditController', [
        '$scope',
        '$rootScope',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'commonFactory',
        '$compile',
        '$state',
        '$window',
        'Notification',
        '$http',
        function (
            $scope,
            $rootScope,
            apiUrl,
            DTOptionsBuilder,
            DTColumnBuilder,
            commonFactory,
            $compile,
            $state,
            $window,
            Notification,
            $http,
        ) {

            $scope.dtInstance = {};
            $scope.createdDateRange
            $scope.title = $state.current.data.pageTitle;
            $scope.downloadExcel = false;
            $scope.selectedAttorneyFirms = [];
            $scope.selectedAttorneyFirmIds = [];
            $scope.selectedAttorneys = [];
            $scope.selectedAttorneyIds = [];
            $scope.selectedResources = [];
            $scope.selectedResourceIds = [];
            $scope.selectedCreatedBys = [];
            $scope.selectedCreatedByIds = [];

            $scope.resetSearch = function () {
                $scope.createdDateRange = null;
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };
            $scope.lookingFor = '';
            $scope.assignmentTypes = [
                {'type': '', 'name': '-- ALL JOBS --'},
                {'type': 'audio', 'name': 'AUDIO JOBS'},
                {'type': 'geico', 'name': 'GEICO JOBS'},
                {'type': 'reporter', 'name': 'RESOURCE JOBS'}
            ];

            $scope.report = {
                createdDateRange: {
                    endDate: moment().format('MM-DD-YYYY'),
                    startDate: moment().format('MM-DD-YYYY')
                }
            }


            commonFactory.get('/api/additionalinfo_list').then(function (response) {
                $scope.service_type_list = response.data.result.data;
            });

            $http.get(apiUrl + '/api/getBusinessUnit').then(function (response) {
                $scope.BusinessUnitsList = response.data.result.data;
                $scope.BusinessUnitsList.unshift({name: 'All'});
            });

            $http.get(apiUrl + '/api/getJobStatus').then(function (response) {
                $scope.JobStatusList = response.data.result.data;
                $scope.JobStatusList.unshift({name: 'All'});
            });

            $scope.selectedJobStatusIDs = [];

            $http.get(apiUrl + '/api/casetypes').then(function (response) {
                $scope.CaseTypeList = response.data.result.casetype;
                $scope.CaseTypeList.unshift({name: 'All'});
            });

            $scope.selectedCaseTypeIDs = [];

            $http.get(apiUrl + '/api/getJobCategory').then(function (response) {
                $scope.JobCategoryList = response.data.result.jobcategory;
                $scope.JobCategoryList.unshift({name: 'All'});
            });

            $scope.selectedJobCategoryIDs = [];

            $scope.selectedJobTypeIDs = [];
            $http.get(apiUrl + '/api/jobtypes').then(function (response) {
                $scope.JobTypeList = response.data.result.jobtype;
                $scope.JobTypeList.unshift({name: 'All'});
            });
            $scope.selectedResourceTypeIDs = [];
            $http.get(apiUrl + '/api/additionalinfo_list').then(function (response) {
                $scope.ResourceTypeList = response.data.result.data;
                $scope.ResourceTypeList.unshift({name: 'All'});
            });

            $scope.selectedBusinessUnitIDs = [];
            var selectedBusinessUnitIDs = JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if (selectedBusinessUnitIDs && selectedBusinessUnitIDs.length) {
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            } else {
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                    $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }


            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
                // $scope.dtInstance.DataTable.page(0).draw(true);
                // $scope.dtInstance.DataTable.page(0).draw('page');
            };

            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);

            };

            $scope.downloadExcelFile = function () {
                $scope.downloadExcel = true;
                $scope.reloadData();
            }

            //Getting Attorney Firms list
            $scope.attorneyFirmsList = function (val) {
                $scope.searchingAttFirm = true;
                return $http.get(apiUrl + '/api/attorneySearchByName', {
                    params: {
                        name: val
                    }
                }).then(function (response) {
                    $scope.searchingAttFirm = false;
                    return response.data.result.map(function (item) {
                        return item;
                    });
                });
            };

            //Getting Attorneys list
            $scope.attorneysList = function (val) {
                $scope.searchingAtt = true;
                return $http.get(apiUrl + '/api/attorneyByName', {
                    params: {
                        name: val
                    }
                }).then(function (response) {
                    $scope.searchingAtt = false;
                    return response.data.result.map(function (item) {
                        return item;
                    });
                });
            };

            //Getting Attorneys list
            $scope.getResourceList = function (val) {
                $scope.searchingResource = true;
                return $http.get(apiUrl + '/api/search-resource', {
                    params: {
                        keyword: val
                    }
                }).then(function (response) {
                    $scope.searchingResource = false;
                    return response.data.result.map(function (item) {
                        return item;
                    });
                });
            };

            //Getting Attorneys list
            $scope.createdByList = function (val) {
                $scope.searchingCreatedBy = true;
                return $http.get(apiUrl + '/api/createdByName', {
                    params: {
                        name: val
                    }
                }).then(function (response) {
                    $scope.searchingCreatedBy = false;
                    return response.data.result.map(function (item) {
                        return item;
                    });
                });
            };

            // get firms groups
            commonFactory.get('/api/get-firm-groups')
                .success(function (response) {
                    $scope.firmGroups = response.result;
                });

            //Getting all resources by type.
            commonFactory.post('/api/resource-list-by-type', {rep_typ: 'S'})
                .success(function (response) {
                    if (response.error) {
                        Notification.error(response.result.message || 'Something went wrong!');
                    } else {
                        $scope.salesPersonsList = response.result.resources;
                    }
                });

            $scope.groupChange = function () {
                $scope.reloadData();
            };

            $scope.onSelectSalesperson = function () {
                $scope.reloadData();
            };


            $scope.tagRemoved = function () {
                $scope.selectedAttorneyFirmIds = [];
                angular.forEach($scope.selectedAttorneyFirms, function (value, key) {
                    $scope.selectedAttorneyFirmIds.push(value.id);
                });
                $scope.reloadData();
            };

            $scope.tagAttorneyRemoved = function () {
                $scope.selectedAttorneyIds = [];
                angular.forEach($scope.selectedAttorneys, function (value, key) {
                    $scope.selectedAttorneyIds.push(value.attorney_no);
                });
                $scope.reloadData();
            };

            $scope.tagResourceRemoved = function () {
                $scope.selectedResourceIds = [];
                angular.forEach($scope.selectedResources, function (value, key) {
                    $scope.selectedResourceIds.push(value.id);
                });
                $scope.reloadData();
            };

            $scope.tagCreatedByRemoved = function () {
                $scope.selectedCreatedByIds = [];
                angular.forEach($scope.selectedCreatedBys, function (value, key) {
                    $scope.selectedCreatedByIds.push(value.id);
                });
                $scope.reloadData();
            };

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    if ($scope.downloadExcel) {
                        $rootScope.showLoader(true);
                    }
                    data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                    localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                    $scope.inProcess = true;
                    data.downloadExcel = $scope.downloadExcel;
                    data.assignmentType = $scope.lookingFor;
                    data.selectedJobStatusIDs = $scope.selectedJobStatusIDs.filter(Boolean);
                    data.selectedCaseTypeIDs = $scope.selectedCaseTypeIDs.filter(Boolean);
                    data.selectedJobCategoryIDs = $scope.selectedJobCategoryIDs.filter(Boolean);
                    data.selectedJobTypeIDs = $scope.selectedJobTypeIDs.filter(Boolean);
                    data.selectedResourceTypeIDs = $scope.selectedResourceTypeIDs.filter(Boolean);
                    data.createdStartDate = moment($scope.report.createdDateRange.startDate).format('YYYY-MM-DD');
                    data.createdEndDate = moment($scope.report.createdDateRange.endDate).format('YYYY-MM-DD');
                    data.selectedAttorneyFirmIds = $scope.selectedAttorneyFirmIds;
                    data.selectedAttorneyIds = $scope.selectedAttorneyIds;
                    data.selectedResourceIds = $scope.selectedResourceIds;
                    data.selectedGroups = $scope.selectedGroups;
                    data.selectedServiceTypeIds = $scope.selectedServiceTypeIds;
                    data.selectedCreatedByIds = $scope.selectedCreatedByIds;
                    data.reportingSalesIds = $scope.reportingSalesIds;
                    commonFactory.showDataTable('/api/get-daily-calendar-audit', data).success(function (res) {
                        if (!res.error) {
                            $rootScope.showLoader(false);
                            $scope.selectAll = true;
                            $scope.invoiceArray = {};
                            callback(res);
                        }
                        if ($scope.downloadExcel) {
                            $window.open(apiUrl + '/api/download-daily-calender-audit-report', '_blank');
                        }
                        $scope.downloadExcel = false;
                    }).error(function (err) {
                        Notification.error(err.error)
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO JOB AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ JOBS",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 JOBS",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL JOBS)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ JOBS",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "",
                    "sZeroRecords": "NO MATCHING JOBS FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                })
                .withOption('serverSide', true)
                .withPaginationType('simple_numbers')
                .withOption('lengthMenu', [
                    [10, 25, 50, 100, 200, 250, 300, 500],
                    [10, 25, 50, 100, 200, 250, 300, 500]
                ])
                .withOption('searching', false)
                .withOption('searchDelay', 500)
                .withOption('order', [2, 'asc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                });

            $scope.dtColumns = [];

            $scope.dtColumns.push(
                DTColumnBuilder.newColumn('JOB_NO').withTitle('Job #').withOption('searchable', true),
                DTColumnBuilder.newColumn('DATE_TAKEN').withTitle('Job Date').withOption('defaultContent', '').withOption('searchable', false).renderWith(function (data, type, full, meta) {
                    if (data) {
                        var dte = moment(data).format('MM/DD/YYYY');
                        if (dte == 'Invalid date') {
                            return '-';
                        } else {
                            return dte;
                        }
                    } else {
                        return '-';
                    }

                }),
                DTColumnBuilder.newColumn('TIME_TAKEN').withTitle('Start Time').withOption('searchable', true),
                DTColumnBuilder.newColumn('End_Time').withTitle('End Time').withOption('searchable', true),
                DTColumnBuilder.newColumn('job_status').withTitle('Job Status').withOption('searchable', true),
                DTColumnBuilder.newColumn('CAPTION').withTitle('Case').withOption('searchable', true),
                DTColumnBuilder.newColumn('scheduling_firm').withTitle('Scheduling Firm').withOption('searchable', true),
                DTColumnBuilder.newColumn('attorney_name').withTitle('Attorney').withOption('searchable', true),
                DTColumnBuilder.newColumn('scheduled_by_name').withTitle('Scheduled by').withOption('searchable', true),
                DTColumnBuilder.newColumn('resources').withTitle('Resource(s)').withOption('searchable', true),
                DTColumnBuilder.newColumn('resource_type').withTitle('Resource Type').withOption('searchable', true),
                DTColumnBuilder.newColumn('requested_service').withTitle('Requested Service(s)').notSortable().withOption('searchable', true),
                DTColumnBuilder.newColumn('group_name').withTitle('Group').withOption('searchable', true),
                DTColumnBuilder.newColumn('business_unit').withTitle('Business Unit').withOption('searchable', true),
                DTColumnBuilder.newColumn('created_by').withTitle('Created By').withOption('searchable', true),
                DTColumnBuilder.newColumn('created_at').withTitle('Created On').withOption('defaultContent', '').withOption('searchable', false).renderWith(function (data, type, full, meta) {
                    if (data) {
                        var dte = moment(data).format('MM/DD/YYYY');
                        if (dte == 'Invalid date') {
                            return '-';
                        } else {
                            return dte;
                        }
                    } else {
                        return '-';
                    }

                }),
                DTColumnBuilder.newColumn('salesperson').withTitle('Account Executive(s)').notSortable().withOption('searchable', true),
            );
        }
    ]);

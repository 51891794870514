angular.module('deitz')
    .controller('deleteDeliverableDocumentsController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'JOB_NO',
        'description',
        'type',
        'title',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
            $rootScope,
            codeFactory,
            $http,
            Upload,
            apiUrl,
            $state,
            commonFactory,
            Notification,
            $stateParams,
            $uibModal,
            JOB_NO,
            description,
            type,
            title,
            $uibModalInstance,
            configFactory) {

            $scope.job_transcripts = [];
            $scope.title = 'DELETE DELIVERABLES OF ' + description;
            if(title){
                $scope.title  = title +' '+description ;
            }
            $scope.JOB_NO = JOB_NO;
            $scope.description = description;

            $scope.getDeliverableDocument = function () {
                $rootScope.showLoader(true);
                commonFactory.getWithParams('/api/get-deliverable-documents-by-witness', {
                    JOB_NO: $scope.JOB_NO,
                    description: $scope.description,
                    type : type
                })
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        $scope.job_transcripts = response.result.transcripts;
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }

            $scope.getDeliverableDocument();

            $scope.downloadVideo = function(document_no){
                $http.get(apiUrl + '/api/get-deliverable-document-url', {
                    params: {
                        document_no: document_no
                    }
                }).then(function (response) {
                    if (!response.data.error) {
                        window.open(response.data.result, '_blank');
                    } else {
                        Notification.error(response.data.message);
                    }
                });
            }

            $scope.deleteDeliverableDocument = function (document_no) {
                swal({
                    title: "Are You Sure Want To Delete This File?",
                    type: "warning",
                    html: true,
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                    function (isConfirm) {
                        if (isConfirm) {
                            $rootScope.showLoader(true);
                            commonFactory.delete('/api/deliverable-documents/'+document_no)
                                .success(function(response) {
                                    $rootScope.showLoader(false);
                                    if (response && response.result) {
                                        Notification.success(response.result.message || 'File deleted successfully.');
                                        $scope.getDeliverableDocument();
                                    }else{
                                        Notification.error(response.result.message || 'Something went wrong!');
                                        $rootScope.showLoader(false);
                                    }
                                })
                                .error(function(err) {
                                    $rootScope.showLoader(false);
                                });
                        }
                    })


            }

            $scope.closePopupWindowSimple = function () {
                $uibModalInstance.dismiss({ isChanged: true });
            };
        }]);
angular.module('deitz')
    .controller('addCaseNotesController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        'commonFactory',
        'Notification',
        'case_id',
        'id',
        '$uibModalInstance',
        function ($scope,
            $rootScope,
            codeFactory,
            commonFactory,
            Notification,
            case_id,
            id,
            $uibModalInstance
        ) {
            $scope.caseNote = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'Add Note';
            $scope.caseNote.note_date = moment();
            $scope.caseNote.notes = '';
            $scope.caseNote.case_id = case_id;

            if (id) {
                $scope.title = 'Edit Note';
            }

            if (id) {
                var id = id;
                commonFactory.get('/api/case-note/' + id)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200 && response.result) {
                            $scope.caseNote = response.result.note;
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }



            $scope.validateDate = function () {
                if ($scope.caseNote.note_date) {
                    is_valid_check = moment($scope.caseNote.note_date).isValid();
                    if (!is_valid_check) {
                        $scope.caseNote.note_date = '';
                    }
                }
            }

            $scope.editcaseNote = function (caseNote, isValid) {

                $scope.formSubmit = true;
                if (typeof caseNote.note_date != "undefined" && caseNote.note_date != "") {
                    caseNote.note_date = moment(caseNote.note_date).format('YYYY-MM-DD');
                }

                if (isValid) {
                    $rootScope.showLoader(true);

                    if (id) {
                        commonFactory.put('/api/case-note/' + id, $scope.caseNote)
                            .success(function (response) {
                                $rootScope.showLoader(false);
                                if (response.status == 200 && response.result) {
                                    Notification.success("Note update Sucessfully");
                                    $scope.closePopupWindowSimple(true);
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }
                    else {
                        commonFactory.post('/api/case-note', $scope.caseNote)
                            .success(function (response) {
                                $rootScope.showLoader(false);
                                if (response.status == 200 && response.result) {
                                    Notification.success("Note Add Sucessfully");
                                    $scope.closePopupWindowSimple(true);
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }
                }
            }

            $scope.closePopupWindowSimple = function (isChanged) {
                $uibModalInstance.dismiss({ isChanged: isChanged });
            };

        }]);

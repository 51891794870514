angular.module('deitz').controller('addadditionalChargesController', [
    '$scope',
    '$state',
    '$http',
    'apiUrl',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    function(
        $scope,
        $state,        
        $http,
        apiUrl,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification
    ) {

        $scope.additional_charge = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add';
        $scope.additional_charge.expense_code = $scope.additional_charge.expense_code?.trim()
        $scope.line_item_report_level1_list = ["Remote Court Reporting (Audio Transcription)","In Person Court Reporting (Real-time)","In Person Court Reporting (Non Real-time)","Same Day Expedited Transcript Delivery","Next Day (Daily) Expedited Transcript Delivery","2 Day Expedited Transcript Delivery","3 Day Expedited Transcript Delivery","4 Day Expedited Transcript Delivery","5 Day Expedited Transcript Delivery","Bust Fee- Same Day","CART Captioning","Web Repository","E-Transcript","Copying/Scanning- Color Copies","Copying/Scanning- Black & White","Mileage or transportation cost","Domestic Shipping- Same Day","Domestic Shipping- Next Day","Domestic Shipping- 2 Day","Domestic Shipping- 3 Day","Domestic Shipping- 4 Day","Domestic Shipping- 5 Day","Disk","Language Interpretation/translation","Online Scheduling","Conference Rooms","Videoconferencing","Video Recording/ Videography","Other","Reporter Appearance Fees - with transcript order","Reporter Appearance Fees - without a transcript order","Technician","Adminstrative Fee (Archival Fe, Transcript E-Filing )","Standard Shipping"]
        $scope.line_item_report_level2_list = ["Standard Full Transcript","Condensed Transcript","Keyword Index","Video-Text Synchronization","Exhibits (B/W)","Statement on the Record","Rough ASCII","Exhibits","Transcript","Litigation Support Package","Other"]
        $scope.line_item_report_unit_type_list = ["Hours", "Pages" ,"Flat Fee" ,"Package"]
        $http.get(apiUrl + '/api/get-group-charge').then(function(response) { 
            $scope.GroupChargesList = response.data.result.group_charges;                      
          });
        $scope.addExtraCharges = function(additional_charge, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                $scope.additional_charge.TYPE = 'X';
                commonFactory.post('/api/additional-charges', $scope.additional_charge)
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response) {
                        if (response.status == 200) {
                            Notification.success("Additional Charge added successfully.");
                            $state.go("rate_tables.additional_charges_browse");
                        }
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            }
        }

        $scope.refreshAdditionalChargesGroup = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/additionalChargesGroups').success(function (response) {
                if (response.result) {
                    $scope.additionalChargesGroup = response.result.additionalChargesGroup;
                }
                $rootScope.showLoader(false);
            }).error(function (err) {
                $rootScope.showLoader(false);
            });
        }
        $scope.refreshAdditionalChargesGroup();

    }]);

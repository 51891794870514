angular.module('deitz').controller('jurisdictionController', [
    '$scope',
    '$rootScope',
    '$state',
    'commonFactory',
    'Notification',
    '$stateParams',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    '$q',
    '$compile',
    function(
        $scope,
        $rootScope,
        $state,
        commonFactory,
        Notification,
        $stateParams,
        DTOptionsBuilder,
        DTColumnBuilder,
        $q,
        $compile
    ) {
        $scope.includeAll = true;
        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var table = $('#jurisdiction_list').DataTable();
            table.ajax.reload();
        };
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    dataTable:true,
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value,
                    includeActive: (!$scope.includeAll && $scope.includeActive) ? $scope.includeActive : false,
                    includeInactive: (!$scope.includeAll && $scope.includeInactive) ? $scope.includeInactive : false,
                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/jurisdiction', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            })
            .withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            })
            .withOption('order', [1, 'asc'])
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'jurisdiction' },
                { extend: 'pdf', title: 'jurisdiction' }, {
                    extend: 'print',
                    customize: function(win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withOption('defaultContent', '').withTitle('Jurisdiction').renderWith(function(data,type,full){
                    return data;
            }),
            DTColumnBuilder.newColumn('sort_order').withOption('defaultContent', '').withTitle('Sort Order'),
            DTColumnBuilder.newColumn('is_active').withOption('defaultContent', '').withTitle('Status').renderWith(function(data, type, full, meta) {
                if (full && full.id) {
                    var newActive = (full.is_active == '1') ? "0" : "1";
                    var btn = (full.is_active == '1') ? 'btn-success' : 'btn-danger';
                    var status =  (full.is_active =='1') ? 'Active' : 'In Active'
                    return '<button ng-if="havePermission(\'jurisdiction\',\'edit\')" class="ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm '+btn+'" ng-click="changeIsActiveStatus(' + full.id + ',\''+ newActive +'\')">' + status+ '</button>';
                }
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().renderWith(function(data, type, full, meta) {
                if (data && data.id) {
                    return '<button class="btn btn-primary btn-circle" ng-if="havePermission(\'jurisdiction\',\'edit\')" uib-tooltip="Edit" ui-sref="code_tables.jurisdiction_edit({id : ' + data.id + '})" >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button> &nbsp;';
                }

            })
        ];


        $scope.changeIsActiveStatus = function (id, newStatus) {
            let status = newStatus == '1' ? 'active' : 'inactive';
            var page = $('#jurisdiction_list').DataTable().page();
            swal({
                title: "Alert!",
                text: "Are you sure want to "+status+" this jurisdiction?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);
                    var data = {id : id, is_active : newStatus};

                    commonFactory.put('/api/jurisdiction/'+id, data).success(function(res){
                        $rootScope.showLoader(false); 
                        if(res.error){
                            Notification.error(res.result.message);    
                        }else{
                            Notification.success(res.result.message);
                        }
                        $('#jurisdiction_list').DataTable().page(page).draw(false);
                    }).error(function (err) {
                        $rootScope.showLoader(false);
                        Notification.error(err.result.message);
                        $('#jurisdiction_list').DataTable().page(page).draw(false);
                    });
                }
            });

        };

    }]);

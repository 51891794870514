angular.module('deitz').controller('batchInvoicePaymentController', [
    '$scope',
    '$state',
    '$stateParams',
    '$rootScope',
    '$http',
    'apiUrl',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    'invoiceFactory',
    '$compile',
    '$uibModal',
    'Notification',
    'commonFactory',
    'cashReceiptFactory',
    '$filter',
    '$timeout',
    '$location',
    '$q',
    function (
        $scope,
        $state,
        $stateParams,
        $rootScope,
        $http,
        apiUrl,
        DTOptionsBuilder,
        DTColumnBuilder,
        invoiceFactory,
        $compile,
        $uibModal,
        Notification,
        commonFactory,
        cashReceiptFactory,
        $filter,
        $timeout,
        $location,
        $q) {

            $scope.title = "Browse Invoice";
            $scope.dtInstance = {};
            $scope.invoiceArray = {};
            $scope.transactionComplete = true;
            $scope.validations = cashReceiptFactory.validations;
            $scope.messages = cashReceiptFactory.messages;
            if($stateParams.type){
                $scope.selectedValue = $stateParams.type
            }else{
                $scope.selectedValue = 'attorney'
            }
            $scope.adjustmentTypes = cashReceiptFactory.adjustmentTypes;
            $scope.paymentTypes = $rootScope.paymentTypes;

            if (!$rootScope.paymentTypes || $rootScope.paymentTypes.length === 0) {
                $rootScope.getPaymentTypes().then(function (){
                    $scope.paymentTypes = $rootScope.paymentTypes;
                });
            }

            $scope.cashReceiptData = {
                total_ar_paid_amt : 0,
                check_amt : 0,
                DUE_AMT:0,
                // rem_amt:0,
                adj_typ: "none",
                PAY_TYPE: "check",
                over_under_amt : 0,
                RECD_TYP : 'X'

            };
            $scope.assignedFirm = false;

            $http.get(apiUrl + '/api/check/get-current-batch-invoice-check-session').then(function(response) { 
                if(response.data.session.length==0){
                    swal({
                        title: "Are you sure ?",
                        text: "<small>You are running Invoice Operations (PO) and will have exclusive control of PO for a 30 minute session to complete the process.<br> Before accepting control, be sure your reports tie-out to the Batch Totals. <br>  Your User information will be tracked and any OTHER USER attempting to run invoice during your session will see your User information.<br> Once your update is completed your exclusive session will automatically be released.<small>",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes, Do it",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true,
                        html:true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $http.post(apiUrl+'/api/check/start-batch-invoice-check-session')
                            .success(function(response){
                            if(!response.success){
                                    swal({
                                        title:response.user.name +"  is already using the invoice operation. If you need to contact them, please email "+response.user.email,
                                        html:true
                                    },function(isConfirm){
                                        $location.path('/home');
                                    });
                            }
                            else{
                                    Notification.success("Your invoice session start successfully");
                                    $timeout(function () {
                                        Notification.error("Your invoice session is expired");
                                        $location.path('/home');
                                    }, response.session.session_remain_time);
                            }
                            });
                        }
                        else{
                            // $location.path('/home');
                            $state.go('index.home');
                        }
                    });
                }
                else{
                    if(response.data.session[0].user_id!=$rootScope.auth_user.id){
                        swal({
                            title:response.data.session[0].user.name +"  is already using the invoice operation. If you need to contact them, please email "+response.data.session[0].user.email,
                            html:true
                        });
                        $location.path('/home');
                    }
                    else{
                        $timeout(function () {
                            Notification.error("Your invoice session is expired");
                            $location.path('/home');
                        }, response.data.session[0].session_remain_time);
                    }
                }
            });
            $scope.closeInvoiceSession = function(){
                $http.post(apiUrl+'/api/check/stop-batch-invoice-check-session')
                .success(function(response){
                    Notification.success("Invoice session end successfully");
                    $location.path('/home');
                });
            }
            
            $scope.reloadData = function () {
                $scope.calculateAmount();
                $scope.dtInstance.rerender();
            };

            $scope.resetSearch = function(){
                invoiceFactory.searchData = [];
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };

            $scope.resetSearch = function(){
                invoiceFactory.searchData = [];
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };
            $scope.resetType = function(type){
                $scope.invoiceNos = '';
                $scope.invNos = [];
                invoiceFactory.searchData = [];
                $scope.assignedFirm = false
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
                var parmes = {
                    type : type
                };
                $state.go('.',parmes,{reload:true});
                $scope.unSelectFirm();
    
            }
            $scope.calculateAmount = function(){
                $rootScope.showLoader(true);
                $scope.amountError=false;
                $scope.underPaidError=false;
                $scope.selectedInvoices = [];
                $scope.cashReceiptData.total_ar_paid_amt = 0;
                if(isNaN($scope.cashReceiptData.check_amt)){
                    $scope.cashReceiptData.check_amt = 0;
                }
                $scope.selectedInvoiceAccounts=[];
                
                angular.forEach($scope.invoiceArray, function (value, key) {
                    if($scope.invoiceArray[key].is_checked){
                        $scope.invoiceArray[key].is_over_under_amt = false;
                        
                        $scope.selectedInvoices.push({
                            invoiceID: value.invoiceID,
                            INV_AMT : value.INV_AMT,
                            INV_NO: value.INV_NO,
                            DUE_AMT: value.DUE_AMT,
                            invoice_paying_amount : value.invoice_paying_amount,
                            oldAdjustment: value.adjustment,
                            adjustment: value.adjustment,
                            ATTORNEY:{
                                id:value.attorney_firms.id,
                                NAME_KEY :value.attorney_firms.NAME_KEY,
                                name :value.attorney_firms.firmNameWithAddress,
                                credit_amount : value.attorney_firms.credit_amount
                            }
                        });
                        const filteredArray = $scope.selectedInvoiceAccounts.filter(element => {
                            if (element.id === value.attorney_firms.id) {
                              return true;
                            }
                            return false;
                          });
                        
                        if (filteredArray.length == 0) {
                            var firm ={
                                id:value.attorney_firms.id,
                                NAME_KEY :value.attorney_firms.NAME_KEY,
                                name :value.attorney_firms.firmNameWithAddress,
                                credit_amount : value.attorney_firms.credit_amount
                            }
                            $scope.selectedInvoiceAccounts.push(firm)
                        }
                        
                        if(!value.invoice_paying_amount || value.invoice_paying_amount==0){
                            $scope.amountError=true;
                        }
                        if(!isNaN(parseFloat(value.invoice_paying_amount))){
                            $scope.invoiceArray[key].invoice_due_after_paid= parseFloat(value.DUE_AMT) - parseFloat(value.invoice_paying_amount);
                            $scope.cashReceiptData.total_ar_paid_amt = parseFloat($scope.cashReceiptData.total_ar_paid_amt) + parseFloat(value.invoice_paying_amount);
                        }

                        $scope.cashReceiptData.over_under_amt = parseFloat($scope.cashReceiptData.check_amt).toFixed(2) - parseFloat($scope.cashReceiptData.total_ar_paid_amt).toFixed(2);
                        if($scope.cashReceiptData.over_under_amt < 0){
                            $scope.underPaidError=true;
                        }

                        if (!$scope.underPaidError) {
                            $scope.invoiceArray[key].is_over_under_amt = true;
                            if ($scope.cashReceiptData.RECD_TYP_X !== undefined && $scope.cashReceiptData.RECD_TYP_M !== undefined && $scope.cashReceiptData.RECD_TYP_M) {
                                $scope.cashReceiptData.RECD_TYP_M = false; 
                            }
                        }
                    } else{
                        $scope.invoiceArray[key].is_over_under_amt = false;
                    }
                });
                $scope.paidOverAmmontForBatchInvoice();
                // $scope.cashReceiptData.rem_amt = parseFloat($scope.cashReceiptData.attorney_firms.credit_amount) + parseFloat($scope.cashReceiptData.check_amt) - parseFloat($scope.cashReceiptData.total_ar_paid_amt) ;
                // $scope.cashReceiptData.over_under_amt = parseFloat($scope.cashReceiptData.check_amt).toFixed(2) - parseFloat($scope.cashReceiptData.total_ar_paid_amt).toFixed(2);
                // if($scope.cashReceiptData.over_under_amt < 0){
                //     $scope.underPaidError=true;
                // }
                $timeout(function () {
                    $rootScope.showLoader(false);
                }, 2000);
            };

            //Getting Attorneys list
            $scope.attorneyslist = function(val) {
                $rootScope.showLoader(true);
                return $http.get(apiUrl + '/api/attorneySearchByName', {
                    params: {
                        name: val
                    }
                }).then(function(response) {
                    $rootScope.showLoader(false);
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };

            $scope.headerCompiled = false;

            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);

            };

            $scope.dtOptions = DTOptionsBuilder.fromFnPromise(function() {
                var defer = $q.defer();
                    defer.resolve($scope.cashReceiptData);
                    return defer.promise;
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO CASH RECEIPTS AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ CASH RECEIPTS",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL CASH RECEIPTS)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ INVOICES",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "Search :",
                    "sZeroRecords": "NO MATCHING CASH RECEIPT FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                })
                .withOption('serverSide', false)
                .withOption('stateSave', false)
                // .withOption('paging', false)
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500)
                .withOption('order', [4, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                });

            $scope.dtColumns = [];
            var titleHtml = '<input type="checkbox" class="layout-align-xl-center-center" icheck ng-model="selectAll" ng-change="toggleAllInvoice(selectAll)">';

            $scope.dtColumns.push(
                DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                        $scope.invoiceArray[full.invoiceID] = full;
                        $scope.invoiceArray[full.invoiceID].invoice_paying_amount = full.DUE_AMT.toFixed(2);
                        $scope.invoiceArray[full.invoiceID].invoice_due_after_paid = 0;
                        $scope.invoiceArray[full.invoiceID].is_checked = (typeof is_checked === 'undefined') ? false : true;
                        $scope.invoiceArray[full.invoiceID].is_over_under_amt = (typeof is_over_under_amt === 'undefined') ? false : true;
                        if (!$scope.invoiceArray[full.invoiceID].is_checked) {
                            $scope.selectAll = false;
                        }

                    return '<input type="checkbox" icheck ng-model="invoiceArray[' + full.invoiceID + '].is_checked" ng-change="toggleBatchInvoice(invoiceArray[' + full.invoiceID + '].is_checked,' + full.INV_NO + ')">';
                    }).withOption('searchable', false),
                DTColumnBuilder.newColumn(null).withTitle('Invoice Number').renderWith(function(data, type, full, meta){
                    return data.original_invoice_no ? data.original_invoice_no : data.INV_NO;
                }).withOption('searchable', true),
                DTColumnBuilder.newColumn('dte_invoice').withTitle('Invoice Date').withOption('searchable', true).renderWith(function (data, type, full, meta) {
                    if (data) {
                        return $filter('formatDate')(data);

                    } else if (full.CreatedDate) {
                        return $filter('formatDate')(full.CreatedDate);
                        ;
                    }
                    else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn('JOB_NO').withTitle('Job No').withOption('searchable', true),
                DTColumnBuilder.newColumn('INV_AMT').withTitle('Invoice Amount').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('PAID_AMT').withTitle('Paid Amt').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('adjustment').withTitle('Adjustment').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }
                    // return '<span ng-bind="invoiceArray['+full.invoiceID+'].adjustment | currency"></span>';

                }),
                DTColumnBuilder.newColumn('DUE_AMT').withTitle('Due Amt').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('PAID_DT').withTitle('Paid Date').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
                    if (data && $filter('formatDate')(data) != 'Invalid Date') {
                        return $filter('formatDate')(data);
                    }
                    else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn('legacy_invoice_id').withTitle('Legacy No').withOption('searchable', true).withOption("width", "6%"),
                DTColumnBuilder.newColumn('diary.businessunit.name').withTitle('Business Unit').withOption('searchable', true).withOption('defaultContent', '-').withOption("width", "6%"),
                // DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith(function (data, type, full, meta) {
                //     var data = '';
                //     return data;
                // })
                DTColumnBuilder.newColumn(null).withTitle('Amount Being Paid').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
                    return '<input   ng-if="invoiceArray['+full.invoiceID+'].is_checked" type="text" class="form-control" ng-keypress="restrictRange($event,'+full.DUE_AMT.toFixed(2)+');" ng-change="calculateAmount()" ng-model="invoiceArray['+full.invoiceID+'].invoice_paying_amount" > <small class="error-message  fadeInDown red"  ng-if="!invoiceArray['+full.invoiceID+'].invoice_paying_amount" >Amount is required</small><small class="error-message  fadeInDown red"  ng-if="invoiceArray['+full.invoiceID+'].invoice_paying_amount && invoiceArray['+full.invoiceID+'].invoice_paying_amount == 0" >Amount can\'t be zero</small>';
                }),
                DTColumnBuilder.newColumn(null).withTitle('Due After paid').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
                    return '<span ng-if="invoiceArray['+full.invoiceID+'].is_checked" ng-bind="invoiceArray['+full.invoiceID+'].invoice_due_after_paid | currency"></span>';
                }),
                DTColumnBuilder.newColumn(null).withTitle('Paid Over Amount').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
                    return '<input ng-if="invoiceArray['+full.invoiceID+'].is_checked && !underPaidError && invoiceArray['+full.invoiceID+'].paid_over_amount > 0" type="text" class="form-control col-1"  ng-model="invoiceArray['+full.invoiceID+'].paid_over_amount" readonly>';
                }),
                DTColumnBuilder.newColumn(null).withTitle('Accept Payment').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
                    return '<div ng-if="invoiceArray['+full.invoiceID+'].is_over_under_amt && !underPaidError"> <div class="row"> '+ $scope.acceptPaymet(full.invoiceID) +' </div> </div>';
                }),
            );
            $scope.acceptPaymet = function (invoiceID) {
                let html = '';
                $scope.invoiceArray[invoiceID].RECD_TYP = 'X';
                html+='<div class="col-sm-12" style="padding: 2px 10px; width: 145px;">';
                    html+='<div class="form-group leave_on_account_box">';
                        html+='<input icheck type="radio" value="M" id="leave_on_account_'+invoiceID+'" ng-model="invoiceArray['+invoiceID+'].RECD_TYP" name="RECD_TYP_'+invoiceID+'" ng-change="onRadioChange('+invoiceID+', 1)"/>';
                        html+='<label class="radio-inline" for="leave_on_account_'+invoiceID+'"> Leave On Account </label>';
                    html+='</div>';
                html+='</div>';
                html+='<div class="col-sm-12 " style="padding: 0 10px;">';
                    html+='<div class="form-group">';
                        html+='<input icheck type="radio" value="X" id="write_off_'+invoiceID+'" ng-model="invoiceArray['+invoiceID+'].RECD_TYP" name="RECD_TYP_'+invoiceID+'"  ng-change="onRadioChange('+invoiceID+', 2)" />';
                        html+='<label class="radio-inline" for="write_off_'+invoiceID+'" > Write Off </label>';
                    html+='</div>';
                html+='</div>';
                return html;
            }

            $scope.paymentAccountType = function (type) {
                if (type === 'M') {
                    $scope.cashReceiptData.RECD_TYP_X = false;
                }
                if (type === 'X') {
                    $scope.cashReceiptData.RECD_TYP_M = false;
                }
                angular.forEach($scope.invoiceArray, function (value, key) {
                    if($scope.invoiceArray[key].is_checked && $scope.invoiceArray[key].is_over_under_amt){
                        if ($scope.cashReceiptData.RECD_TYP_M) {
                            let leave  = document.getElementById('leave_on_account_'+key);
                            leave.parentElement.classList.add('checked');
                            let write  = document.getElementById('write_off_'+key);
                            write.parentElement.classList.remove('checked');
                            $scope.onRadioChange(key, 1);
                        }
                        if ($scope.cashReceiptData.RECD_TYP_X || (!$scope.cashReceiptData.RECD_TYP_M && !$scope.cashReceiptData.RECD_TYP_X)) {
                            const write = document.getElementById('write_off_'+key);
                            write.parentElement.classList.add('checked');
                            let leave  = document.getElementById('leave_on_account_'+key);
                            leave.parentElement.classList.remove('checked');
                            $scope.onRadioChange(key, 2);
                        }
                    }
                });
            }

            $scope.onRadioChange = function(invoiceID, type) {
                type = type === 1 ? 'M' : 'X';
                $scope.invoiceArray[invoiceID].RECD_TYP = type;
                $scope.paidOverAmmontForBatchInvoice();
            }


            $scope.paidOverAmmontForBatchInvoice = function() {
                $scope.addedRateTypeintoBachPayment();
                $scope.selectedInvoicesCount = $scope.selectedInvoices.filter(Invoice => Invoice.RECD_TYP === 'M').length;
                
                const paidOverAmt = parseFloat($scope.cashReceiptData.over_under_amt)/parseFloat($scope.selectedInvoicesCount)
                angular.forEach($scope.selectedInvoices, function (value, key) {            
                    if ($scope.cashReceiptData.over_under_amt > 0 && $scope.invoiceArray[value.invoiceID].RECD_TYP === 'M') {
                        $scope.invoiceArray[value.invoiceID].paid_over_amount =  paidOverAmt.toFixed(2);
                        value.paid_over_amount =  paidOverAmt.toFixed(2);
                        $scope.invoiceArray[value.invoiceID].invoice_due_after_paid = parseFloat($scope.invoiceArray[value.invoiceID].DUE_AMT) - parseFloat( $scope.invoiceArray[value.invoiceID].invoice_paying_amount);
                        $scope.invoiceArray[value.invoiceID].adjustment = $scope.invoiceArray[value.invoiceID].oldAdjustment;
                    }else if($scope.invoiceArray[value.invoiceID].RECD_TYP === 'M') {
                        $scope.invoiceArray[value.invoiceID].invoice_due_after_paid = parseFloat($scope.invoiceArray[value.invoiceID].DUE_AMT) - parseFloat( $scope.invoiceArray[value.invoiceID].invoice_paying_amount);
                        console.log('$scope.invoiceArray[value.invoiceID].oldAdjustment', $scope.invoiceArray[value.invoiceID].oldAdjustment);
                        $scope.invoiceArray[value.invoiceID].adjustment = $scope.invoiceArray[value.invoiceID].oldAdjustment;
                    }else {
                        $scope.invoiceArray[value.invoiceID].paid_over_amount = 0
                        value.paid_over_amount = 0
                        if ($scope.cashReceiptData.over_under_amt >= 0) {
                            $scope.invoiceArray[value.invoiceID].invoice_due_after_paid = 0;
                        }
                        $scope.invoiceArray[value.invoiceID].adjustment = parseFloat($scope.invoiceArray[value.invoiceID].DUE_AMT) - parseFloat( $scope.invoiceArray[value.invoiceID].invoice_paying_amount);
                    }
                });
            }
            
            $scope.toggleAllInvoice = function (isChecked) {
                angular.forEach($scope.invoiceArray, function (value, key) {
                    $scope.invoiceArray[key].is_checked = isChecked;
                    // $scope.toggleBatchInvoice(isChecked, $scope.invoiceArray[key].INV_NO);
                });
                $scope.calculateAmount();
            };

            $scope.toggleBatchInvoice = function (is_checked, invoiceNo) {
                $scope.calculateAmount();
            };


            $scope.onSelectFirm = function(item, model, label){
                $rootScope.showLoader(true);
                $scope.assignedFirm = true;
                commonFactory.post('/api/find-cash-reciept', {
                        searchValue: item,
                        selectedValue: "FIRM_FOR_BATCH"
                    })
                    .success(function(response) {
                        $scope.invoiceNos = '';
                        $scope.cashReceiptData = [];
                        if (response && response.result && response.result.cashReceipts) {
                            $rootScope.showLoader(false);
                            $scope.cashReceiptData = response.result.cashReceipts;
                            response.result.cashReceipts.forEach(element => {
                                $scope.invoiceNos = $scope.invoiceNos + element.original_invoice_no+','
                                
                            });
                            $scope.parseInvoiceNos();
                        } else {
                            $rootScope.showLoader(false);
                            if (response && response.result && response.result.message) {
                                Notification.error(response.result.message);
                            }
                        }
                        $scope.cashReceiptData.total_due_amt = response.result.totalDue || 0;
                        $scope.cashReceiptData.adj_typ= "none";
                        $scope.cashReceiptData.PAY_TYPE= "check";
                        //$scope.cashReceiptData.attorney_firms= item;
                        $scope.cashReceiptData.RECD_TYP = 'X';
                        $scope.reloadData();
                        $scope.calculateAmount();
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            };

            $scope.unSelectFirm = function(){
                if($scope.assignedFirm){
                    $scope.cashReceiptData = [];
                    $scope.invoiceArray = [];
                    $scope.attorneyData = '';
                    $scope.assignedFirm = false;
                    $scope.is_check_ref_no_validate = false;
                    $scope.ref_no_validate_message = '';
                    $scope.check_no_validate_message = '';
                    $scope.reloadData();
                }
            };

            $scope.change_payment_type = function(){
                //commenting this line b'cus check number or ref number filed should be blank when payment type will chenge.
                // if($scope.cashReceiptData.PAY_TYPE !== 'check'){
                    $scope.cashReceiptData.checkNumber = '';
                // }
                //make check number, validation message of check number or ref number blank and also set validation false.
                $scope.is_check_ref_no_validate = false;
                $scope.ref_no_validate_message = '';
                $scope.check_no_validate_message = '';
            };

            $scope.payBatchCashReceipt = function(isValid){
                
                if(parseFloat($scope.cashReceiptData.check_amt) <= 0){
                    $scope.is_zero_check_amt = true;
                    $timeout(function() {
                        $scope.is_zero_check_amt = false;
                    }, 3000);
                    return false;
                }

                if($scope.amountError){
                    return false;
                }
                
                if(isValid){

                    swal({
                            title: "Are you sure ?",
                            text: "You are about to pay "+ $scope.selectedInvoices.length +" invoice(s).",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes, Do it",
                            cancelButtonText: "No!",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                accountCreditFirm=null;
                                if($scope.cashReceiptData.RECD_TYP=='M'){
                                    const index = $scope.selectedInvoiceAccounts.findIndex(element => {
                                        if (element.id === $scope.cashReceiptData.accountCredit) {
                                          return true;
                                        }
                                        return false;
                                      });
                                      if(index !== -1){
                                        accountCreditFirm=$scope.selectedInvoiceAccounts[index];
                                      }
                                }
                                // $scope.addedRateTypeintoBachPayment();
                                var reqParams = {
                                    selectedInvoices: $scope.selectedInvoices,
                                    checkNumber: $scope.cashReceiptData.checkNumber,
                                    PAY_TYPE:$scope.cashReceiptData.PAY_TYPE,
                                    payment_operation : 'payment',  // Need to set the har code the payment operation because we not doing any adjustment in batch
                                    ATTORNEY:$scope.cashReceiptData.attorney_firms,
                                    total_ar_paid_amt:$scope.cashReceiptData.total_ar_paid_amt,
                                    over_under_amt : $scope.cashReceiptData.over_under_amt,
                                    RECD_TYP:$scope.cashReceiptData.RECD_TYP,
                                    check_amt:$scope.cashReceiptData.check_amt,
                                    PAID_DT : moment($scope.cashReceiptData.PAID_DT).format('MM/DD/YYYY'),
                                    // reason:$scope.cashReceiptData.reason
                                    accountCreditFirm : accountCreditFirm
                                };

                                $rootScope.showLoader(true);
                                $scope.transactionComplete = false;
                                commonFactory.post('/api/batchInvoicePayment', reqParams)
                                    .success(function (response) {
                                        $rootScope.showLoader(false);
                                        $scope.transactionComplete = true;
                                        if(response && response.error == true){
                                            Notification.error('Something went wrong while batch payment!');
                                        } else{
                                            $scope.invoiceArray = [];
                                            Notification.success(response.result.message || 'Payment has been done successfully.');
                                            //$scope.cashReceiptData.attorney_firms.credit_amount = response.result.newCurrentBalance;
                                            //$scope.onSelectFirm($scope.cashReceiptData.attorney_firms);
                                            $scope.fetchInvoices();
                                        }
                                    })
                                    .error(function (err) {
                                        $rootScope.showLoader(false);
                                    });
                            }
                        });
                }

            };

            $scope.addedRateTypeintoBachPayment = function(){
                angular.forEach($scope.selectedInvoices, function (value, key) {
                    value.RECD_TYP = $scope.invoiceArray[value.invoiceID].RECD_TYP
                });
            }
            $scope.isCheckOrRefNoUsed = function(type, value){
                if(type && value && $scope.cashReceiptData.attorney_firms){
                    $scope.check_no_validate_message = '';
                    $scope.ref_no_validate_message = '';
                    $scope.isLoading = true;
                    $http.post(apiUrl+'/api/validate-check-or-reference-number',{ATTORNEY : $scope.cashReceiptData.attorney_firms, type : type, checkOrRefNo : value}).success(function(response){
                        if(response.error){
                            Notification.error(response.result.message);
                        }else{
                            if(response.result.isCheckNoUsed){
                                $scope.check_no_validate_message = 'Check number already taken.';
                                $scope.is_check_ref_no_validate = true;
                            }else if(response.result.isRefNoUsed){
                                $scope.ref_no_validate_message = 'Reference number already taken.';
                                $scope.is_check_ref_no_validate = true;
                            }else{
                                $scope.is_check_ref_no_validate = false;
                            } 
                        }
                        $scope.isLoading = false;
                    });
                }
            }
            $scope.onChange=function(){
                if($scope.cashReceiptData.checkNumber){
                    $scope.is_check_ref_no_validate = true;
                }else{
                    $scope.is_check_ref_no_validate = false;
                    $scope.ref_no_validate_message = '';
                    $scope.check_no_validate_message = '';
                }
            }

            $scope.restrictRange = function(evt,max){
                var charCode = (evt.which) ? evt.which : event.keyCode;
                var regex=   /^\d+(\.\d{0,2})?$/
                if (!regex.test(evt.target.value+String.fromCharCode(charCode))) {
                    evt.preventDefault();
                    evt.stopPropagation();
                    return false;
                }
                if (
                    //0~9
                    charCode >= 48 && charCode <= 57 ||
                   //number pad 0~9
                   charCode >= 96 && charCode <= 105 ||
                    //backspace
                   charCode == 8 ||
                    //tab
                    charCode == 9 ||
                    //enter
                    charCode == 13 || 
                    //left, right, delete..
                    charCode >= 35 && charCode <= 46
                )
                {
                    if(parseFloat(evt.target.value+String.fromCharCode(charCode), 10) <= max) 
                        return true;
                }
                evt.preventDefault();
                evt.stopPropagation();
                return false;
            }

            $scope.parseInvoiceNos = function(data=''){
                if(data){
                    $scope.invoiceNos = data;
                }
                $scope.invNos=$scope.invoiceNos.split('\n').join(',').split(';').join(',').split(',').filter(Number);
            }
            $scope.fetchInvoices = function(){
                $scope.invoiceArray =[];
                if($scope.invNos.length == 0){
                    Notification.error('Please Enter Invoices Data In Valid Format');
                }
                else{
                    $rootScope.showLoader(true);
                    $scope.assignedFirm = true; 
                    commonFactory.post('/api/find-cash-reciept', {
                            searchValue: $scope.invNos,
                            selectedValue: "INVOICES_FOR_BATCH"
                        })
                        .success(function(response) {
                            $scope.cashReceiptData = [];
                            if (response && response.result && response.result.cashReceipts) {
                                $rootScope.showLoader(false);
                                $scope.cashReceiptData = response.result.cashReceipts;
                            } else {
                                $rootScope.showLoader(false);
                                if (response && response.result && response.result.message) {
                                    Notification.error(response.result.message);
                                }
                            }
                            $scope.cashReceiptData.total_due_amt = response.result.totalDue || 0;
                            $scope.cashReceiptData.adj_typ= "none";
                            $scope.cashReceiptData.PAY_TYPE= "check";
                            //$scope.cashReceiptData.attorney_firms= item;
                            $scope.cashReceiptData.RECD_TYP = 'X';
                            $scope.reloadData();
                            $scope.calculateAmount();
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                        });
                }
            }
        }
    ]);

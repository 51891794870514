angular.module('deitz')
    .controller('addCaseDocumentsController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'case_id',
        'id',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  codeFactory,
                  $http,
                  Upload,
                  apiUrl,
                  $state,
                  commonFactory,
                  Notification,
                  $stateParams,
                  $uibModal,
                  case_id,
                  id,
                  $uibModalInstance,
                  configFactory) {
            $scope.proofOfRequestDocument = {};
            $scope.diaryData = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'Manage Case Document';
            $scope.isPdfUploaded = false;
            $scope.proofOfRequestDocument.case_id = case_id;
            $scope.id =id;
            if (id) {
                $http.get(apiUrl + '/api/case-documents/' + id + '/edit')
                    .success(function (response) {
                        if (response.status !== 200){
                            Notification.error(response.result.message);
                            $scope.closePopupWindowSimple();
                            return false;
                        }
                        $scope.proofOfRequestDocument.note = response.result.case_document.note;
                        $scope.proofOfRequestDocument.filename = response.result.case_document.file_name;

                    }).error(function (err) {
                    if (err.error) {
                        Notification.error(err.error);
                    }
                });
            }

                
            $scope.clickUpload = function () {
                $("#fileProofOfRequestDoc").trigger('click');
            }

            $scope.fileChange = function () {
                var fileInput = $('#fileProofOfRequestDoc')[0];
                var files = fileInput.files[0];
                var filePath = fileInput.value;
                // Allowing file type
                var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx|\.csv|\.txt)$/i;
                if (!allowedExtensions.exec(filePath)) {
                    Notification.error("Invalid file type. \nPlease select Image, PDF, Doc, Excel, CSV, Text file.");
                    $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
                    fileInput.value = '';
                    return false;
                }
                if (files && files.name) {
                    $(".upload_h1").html(files.name + " file selected. <br> Provide a below information and press the 'save' button.");
                } else {
                    $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
                }
            }

            $scope.addProofOfRequestDocument = function (proofOfRequestDocument, isValid) {
                $scope.formSubmit = true;
                
                if (isValid) {
                    $rootScope.showLoader(true);
                    if(id){
                        Upload.upload({
                            url: apiUrl+'/api/case-documents/' + id + '/update',
                            data: $scope.proofOfRequestDocument
                        }).then(function (response) {
                            $rootScope.showLoader(false);
                            if (response) {
                                if (response.data.status == 200) {
                                    Notification.success(response.data.result.message);
                                    $scope.closePopupWindowSimple();
                                }else if(response.data.status == 400){
                                    Notification.error(response.data.result.message);
                                }
                            }
                        },function(err){
                            $rootScope.showLoader(false);
                        });
                    }
                    else{
                        Upload.upload({
                            url: apiUrl + '/api/case-documents',
                            data: $scope.proofOfRequestDocument
                        }).then(function (response) {
                            $rootScope.showLoader(false);
                            if (response) {
                                if (response.data.status == 200) {
                                    Notification.success(response.data.result.message);
                                    $scope.closePopupWindowSimple();
                                } else if (response.data.status == 400) {
                                    Notification.error(response.data.result.message);
                                }
                            }
                        }, function (err) {
                            $rootScope.showLoader(false);
                        });
    
                    }
                 
                } else {
                    if ($scope.proofOfRequestDocumentCreateForm.file.$invalid) {
                        Notification.error('Please upload accepted file only.');
                    } else {
                        Notification.error('File, Attorney Firm and Note - must be required.');
                    }
                }
            }

            $scope.closePopupWindowSimple = function () {
                $uibModalInstance.dismiss({isChanged: true});
            };
        }]);
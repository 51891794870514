angular.module('deitz').controller('findcaseController', [
    '$scope', '$rootScope', 'diaryFactory', 'commonFactory', '$state', '$timeout', 'Notification', '$http', 'apiUrl',
    function(
        $scope, $rootScope, diaryFactory, commonFactory, $state, $timeout, Notification, $http, apiUrl
    ){

        $scope.caseDetails = [];

        $scope.validations = diaryFactory.validations;
        $scope.messages = diaryFactory.messages;
        $scope.searchFields = diaryFactory.searchFields;
        $scope.formSubmit = false;
        $scope.findcase = {};
        $scope.lookingFor = '';
        $scope.assignmentTypes = [
            {'type':'','name':'-- ALL JOBS --'},
            {'type':'audio','name':'AUDIO JOBS'},
            {'type':'geico','name':'GEICO JOBS'},
            {'type':'reporter','name':'RESOURCE JOBS'} 
        ];

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});

        });
        /*$scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }*/

            $scope.selectedBusinessUnitIDs = [];
        $http.get(apiUrl + '/api/getUserBusinessUnit', {
            params: {
                user_id: $rootScope.auth_user.id
            }
        }).then(function (response) {
            if(response.data.status == 200){
                response.data.result.data.forEach(function (e) {
                    $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }                        
            if ($scope.selectedBusinessUnitIDs && $scope.selectedBusinessUnitIDs.length) {                
                $scope.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs;
            } else {                
                var selectedBusinessUnitIDs = JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
                if (selectedBusinessUnitIDs && selectedBusinessUnitIDs.length) {
                    $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs;
                } else {                                        
                    $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                        $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                    });
                }
            }
        });


        //Getting Case by case caption.
        $scope.getcasebycaption = function(val) {
            return $http.get(apiUrl + '/api/getcasebycaption', {
                params: {
                    caption: val
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting all locations from locations table.
        commonFactory.get('/api/getLocation')
        .success(function(response) {
            if (response.result && response.result.data) {
                $scope.locations = response.result.data || '';
            }
        })
        .error(function(err) {});

        //Getting Attorney Firm office_code
        $scope.attorneyofficedata = function(val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting Attorneys list
        $scope.attorneyslist = function(val) {
            return $http.get(apiUrl + '/api/getattorneys', {
                params: {
                    name: val
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting witness name in witness_tbl.
        $scope.getcasebywitnessname = function(val) {
            return $http.get(apiUrl + '/api/getcasebywitnessname', {
                params: {
                    name: val
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting assignment created byn ame.
        $scope.getcasebyassignmentcreatedbyname = function(val) { 
            return $http.get(apiUrl + '/api/getcasebyassignmentcreatedbyname', {
                params: {
                    name: val
                }
            }).then(function(response) { 
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };
        //Assign default date to daterange filter.
        $scope.onOptionChange = function(type){
            if(type == "created_at"){
                $scope.findcase.searchValue = {
                    startDate : moment().format('MM-DD-YYYY'),
                    endDate :  moment().format('MM-DD-YYYY')
                };
            }
            else if(type == "AssignmentCreatedDate"){
                $scope.findcase.searchValue = {
                    startDate : moment().format('MM-DD-YYYY'),
                    endDate :  moment().format('MM-DD-YYYY')
                };
            } else if(type == 'all_open_jobs'){
                $scope.findcase.searchValue = 'all_open_jobs';   
            }
            else{
                $scope.findcase.searchValue = '';
            }
            $scope.resetTodayTomorrow();
        }

        $scope.findcase.diaryCreatedDate = null
        $scope.setdiaryCreatedDate = function () {
            if (!$scope.findcase.diaryCreatedDate) {
                $scope.findcase.diaryCreatedDate = {
                    startDate : moment().format('MM-DD-YYYY'),
                    endDate :  moment().format('MM-DD-YYYY')
                };   
            }
        }
        var addTomorrowDay = 1;
        if(moment().format('dddd') === 'Friday'){
            addTomorrowDay = 3;
        }
        if(moment().format('dddd') === 'Saturday'){
            addTomorrowDay = 2;
        }

        $scope.formData = {
            todaysRecord: false,
            tomorrowsRecord: false,
            today: moment().format('MM/DD/YYYY'),
            tomorrow: moment().add(addTomorrowDay, 'days').format('MM/DD/YYYY'),
            todayText : moment().format('dddd'),
            tomorrowText : moment().add(addTomorrowDay, 'days').format('dddd')
        };

        $scope.resetTodayTomorrow = function(){
            $scope.formData.todaysRecord = false;
            $scope.formData.tomorrowsRecord = false;
        }

        $scope.pendingJobs = function(type){
            $scope.findcase.searchValue = type;
            $scope.findcase.selectedValue = 'pending_jobs';
            $scope.resetTodayTomorrow();
            if(type == 'todays_pending_jobs'){
                $scope.formData.todaysRecord = true;
            }else if(type == 'tomorrows_pending_jobs'){
                $scope.formData.tomorrowsRecord = true;
            }
            $scope.findCase($scope.findcase, true);
        }

        $scope.findCase = function(data, isValid) {
            let findcase = Object.assign({}, data);

            $scope.formSubmit = true;
            let isForWitness = false;
            if (isValid) {
                diaryFactory.selectedOption = findcase.selectedValue;
                $rootScope.showLoader(true);
                if (findcase.selectedValue && (findcase.selectedValue === 'created_at' || findcase.selectedValue === 'INV_DT' ) && findcase.searchValue == undefined) {
                    findcase.searchValue = moment();
                }

                if (findcase != undefined && findcase.selectedValue && findcase.selectedValue === 'CAPTION' && findcase.searchValue != undefined) {
                    //findcase.searchValue = findcase.searchValue.LAST_JOB ? findcase.searchValue.LAST_JOB : '';                    
                }
                if (findcase != undefined && findcase.selectedValue && findcase.selectedValue === 'CASE_tbl.attorney_id' && findcase.searchValue != undefined) {
                    findcase.searchValue = findcase.searchValue ? findcase.searchValue.id : '';
                }
                if (findcase != undefined && findcase.selectedValue && (findcase.selectedValue === 'witness_name' || findcase.selectedValue === 'job_resources') && findcase.searchValue != undefined) {
                    
                    const witness_name = findcase.searchValue ? findcase.searchValue : '';
                    findcase.searchValue = {};
                    
                    if(findcase.selectedValue === 'job_resources'){
                        findcase.searchValue.resource_name = witness_name;
                    }else{
                        findcase.searchValue.witness_name = witness_name;    
                    }
                }
                if (findcase != undefined && findcase.selectedValue && findcase.selectedValue === 'CreatedBy' && findcase.searchValue != undefined) {
                    findcase.searchValue = findcase.searchValue.id ? findcase.searchValue : '';
                }
                if (findcase != undefined && findcase.selectedValue && findcase.selectedValue === 'created_at' && findcase.searchValue != undefined) {
                    findcase.searchValue.startDate =  moment($scope.findcase.searchValue.startDate).format('YYYY-MM-DD');
                    findcase.searchValue.endDate =  moment($scope.findcase.searchValue.endDate).format('YYYY-MM-DD');
                }
                if (findcase != undefined && findcase.selectedValue && findcase.selectedValue === 'AssignmentCreatedDate' && findcase.searchValue != undefined) {
                    findcase.searchValue.startDate =  moment($scope.findcase.searchValue.startDate).format('YYYY-MM-DD');
                    findcase.searchValue.endDate =  moment($scope.findcase.searchValue.endDate).format('YYYY-MM-DD');
                }
                if (findcase != undefined && findcase.selectedValue && findcase.selectedValue === 'INV_DT' && findcase.searchValue != undefined) {
                    findcase.searchValue = findcase.searchValue ? moment(findcase.searchValue).format('MM/DD/YYYY') : '';
                }

                findcase.searchDate = {};
                findcase.searchDate.diaryCreatedDate = {};
                if ($scope.findcase.diaryCreatedDate) {
                    findcase.searchDate.diaryCreatedDate.startDate = moment($scope.findcase.diaryCreatedDate.startDate).format('MM/DD/YYYY');
                    findcase.searchDate.diaryCreatedDate.endDate = moment($scope.findcase.diaryCreatedDate.endDate).format('MM/DD/YYYY');
                }
                console.log('findcase.searchDate.diaryCreatedDate', findcase.searchDate.diaryCreatedDate);
                findcase.filterParams = $scope.formData;
                findcase.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
               
                localStorage.setItem('selectedCourtIndexNumber', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));

                findcase.assignmentType = $scope.lookingFor;
                
                findcase.countOnly = true;
                findcase.user_id = $rootScope.auth_user.id;
                commonFactory.post('/api/casexists', findcase)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response && response.result && response.result.recordCount) {
                            // if(response.result.recordCount === 1){
                            //     $state.go('diary.editcase.step_one', { id : response.result.data.LAST_JOB});
                            //     return;
                            // }
                            $timeout(function() {
                                localStorage.setItem('searchString', JSON.stringify(findcase));
                                localStorage.setItem('lookingFor', $scope.lookingFor);
                                $state.go('diary.caselist');
                            }, 500);
                        } else {
                            if (response && response.result && !isForWitness) {
                                Notification.error('Case Detail Not Found');
                            }else{
                                Notification.error('Please select the Witness');
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }


    }]);

angular.module('deitz').controller('salespersonController', [
    '$scope','$rootScope','$http', 'apiUrl','$state','representativeFactory', 'commonFactory', 'Notification','$sce',
    
    function(
        $scope,$rootScope,$http, apiUrl,$state,representativeFactory,commonFactory, Notification,$sce
    ){

        //Model object that contains model data        
        $scope.validations = representativeFactory.validations;
        $scope.messages = representativeFactory.messages; 
        $scope.salesData = {};
        $scope.salesForm = {};
        $scope.openChat = false;
        $scope.showPrivateTab = false;
        $scope.privateTabAuthenticated = false;
        $scope.salesData.start_date = moment().format('MM/DD/YYYY');
        $scope.alternateEmailValid = true;

        if (localStorage.getItem('representative_email')) {
            $scope.salesData.Emai_Address = localStorage.getItem('representative_email')
            localStorage.removeItem("representative_email");
        }
        if (localStorage.getItem('representative_name')) {
            $scope.salesData.first_name = localStorage.getItem('representative_name')
            localStorage.removeItem("representative_name");
        }
        if (localStorage.getItem('representative_last_name')) {
            $scope.salesData.last_name = localStorage.getItem('representative_last_name')
            localStorage.removeItem("representative_last_name");
        }
        if (localStorage.getItem('representative_phone')) {
            $scope.salesData.phone = localStorage.getItem('representative_phone')
            localStorage.removeItem("representative_phone");
        }

        $scope.validateEmails = function(data) {
            var emails = data.target.value.split(/,|;/);
            if(emails == ""){
                $scope.alternateEmailValid = true;
                $scope.alternate_emails_error = '';
                return
            }
            
            var isValid = true;
            for (var i = 0; isValid && i < emails.length; i++) {
              if (!validateEmail(emails[i])) {
                isValid = false;
                $scope.alternateEmailValid = false;
                $scope.alternate_emails_error = 'Email ' + emails[i] + ' does not appear to be a proper email';
                console.log($scope.alternate_emails_error,"E");
              }
              else{
                $scope.alternateEmailValid = true;
                $scope.alternate_emails_error = '';
              }
            }
          
          }
          function validateEmail(email) {
            var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
            return EMAIL_REGEXP.test(email.trim());
          }

        $scope.tooltip = $sce.trustAsHtml('In Order To Print A Physical Check Print Check Needs To be Set To - YES'+'<br>'+ '-------------------'+'<br>' +'In Order To Create An Electronic Payment Print Check Needs To be Set To - NO');

         //get all BussinesUnit 
         $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
        });
        if ($state.params && $state.params.id) {
            $scope.title = 'Edit Account Executive';
        } else {
            $scope.title = 'Add Account Executive';
            $http.get(apiUrl + '/api/get-user-roles-businessunit').then(function(response) { 
                $scope.rolesWithBusinessUnits = response.data.data;  
                $scope.salesData.business_unit_id = $scope.rolesWithBusinessUnits[0].business_unit_id
            });
        }

        //Previous and Next page button control
        if ($state.params && $state.params.id) {
            $scope.showPrivateTab = true;
            $scope.goto_rate = function() {
                $state.go('representative.sales.step_two', { id: $state.params.id });
            }
            $scope.goto_address = function() {
                $state.go('representative.sales.step_one', { id: $state.params.id });
            }
        } else {
            $scope.goto_rate = function() {
                $state.go('representative.sales.step_two');
            }
            $scope.goto_address = function() {
                $state.go('representative.sales.step_one');
            }

            if($state.current.name !== 'representative.sales.step_one' && ( typeof $scope.salesData.first_name == 'undefined' || $scope.salesData.first_name=='' )){
                $state.go('representative.sales.step_one');
            }

        }

        //Save method for saving (SALES) data into database.
        //UPdate method for updating (SALES) data into DB if get ID.
        if ($state.params && $state.params.id) {
            $scope.save = function() {
                $scope.signupForm = true;
                //Add Representative type 'S' For Representative type SALES into DB
                $scope.salesData.rep_typ = 'S';
                $scope.salesData.curr_paydt = moment($scope.salesData.curr_paydt).format('YYYY-MM-DD');
                $scope.salesData.dep_attorney.perms = 'sales';

                var id = $state.params ? $state.params.id : '';
                $rootScope.showLoader(true);
                commonFactory.put('/api/reptype/' + id, $scope.salesData)
                    .success(function(response) {
                        if (response && response.result && response.result.reptypeUpdate) {
                            $rootScope.showLoader(false);
                            $scope.privateTabAuthenticated = false;
                                
                            Notification.success("Account Executive updated successfully.");
                            $state.go("representative.browse");
                        } else {
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            }
        }
        //Add method for adding (SALES) data into DB if not Getting ID.
        else {
            $scope.save = function() {
                $scope.salesForm = true;
                //Add Representative type 'S' For Representative type SALES into DB
                $scope.salesData.rep_typ = 'S';
                $scope.salesData.curr_paydt = moment($scope.salesData.curr_paydt).format('YYYY-MM-DD');
                $scope.salesData.dep_attorney.perms = 'sales';
                $scope.salesData.dep_attorney.view_enotary = 0;
                $rootScope.showLoader(true);
                commonFactory.post('/api/reptype', $scope.salesData)
                    .success(function(response) {
                        if (response && response.result && response.result.reptype) {
                            $rootScope.showLoader(false);
                            Notification.success("Account Executive added successfully.");
                            $state.go("representative.browse");
                        } else {
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            }
        }

        //Get Method for Getting (Account Executive) data into EDIT MODE.
        var id = $state.params ? $state.params.id : '';
        if (id) // Make sure we are in edit mode.
        {
            commonFactory.get('/api/reptype/' + id + '/edit')
                .success(function(response) {
                    if (response && response.result && response.result.reptypeInfo) {
                        $scope.salesData.title = response.result.reptypeInfo.TITLE;
                        $scope.salesData.NAME_KEY = response.result.reptypeInfo.NAME_KEY;
                        $scope.salesData.first_name = response.result.reptypeInfo.FIRST_NAME;
                        $scope.salesData.last_name = response.result.reptypeInfo.LAST_NAME;
                        $scope.setPageTitle();
                        $scope.salesData.person_first_name = response.result.reptypeInfo.person_first_name;
                        $scope.salesData.person_last_name = response.result.reptypeInfo.person_last_name;
                        $scope.salesData.Emai_Address = response.result.reptypeInfo.Emai_Address;
                        $scope.salesData.alternate_emails = response.result.reptypeInfo.alternate_emails;
                        $scope.salesData.phone = response.result.reptypeInfo.PHONE;
                        $scope.salesData.addr_line1 = response.result.reptypeInfo.ADDR_LINE1;
                        $scope.salesData.city = response.result.reptypeInfo.CITY;
                        $scope.salesData.state = response.result.reptypeInfo.STATE;
                        $scope.salesData.zip = response.result.reptypeInfo.ZIP;
                        $scope.salesData.soc_sec_no = response.result.reptypeInfo.SOC_SEC_NO;
                        $scope.salesData.cell = response.result.reptypeInfo.CELL;
                        $scope.salesData.addr_line2 = response.result.reptypeInfo.ADDR_LINE2;

                        $scope.salesData.beeper = response.result.reptypeInfo.BEEPER;
                        $scope.salesData.payment_type = response.result.reptypeInfo.payment_type;
                        $scope.salesData.curr_pay = response.result.reptypeInfo.CURR_PAY;
                        $scope.salesData.prev_pay = response.result.reptypeInfo.PREV_PAY;
                        $scope.salesData.date_added = response.result.reptypeInfo.DATE_ADDED;
                        $scope.salesData.last_chgd = response.result.reptypeInfo.LAST_CHGD;
                        $scope.salesData.notes_1 = response.result.reptypeInfo.NOTES_1;
                        $scope.salesData.p1099 = response.result.reptypeInfo.P1099;
                        $scope.salesData.curr_paydt = response.result.reptypeInfo.CURR_PAYDT;
                        $scope.salesData.ytd_pay = response.result.reptypeInfo.YTD_PAY;
                        $scope.salesData.status = response.result.reptypeInfo.STATUS;
                        $scope.salesData.notes_2 = response.result.reptypeInfo.NOTES_2;
                        $scope.salesData.slsm_comm = response.result.reptypeInfo.SLSM_COMM;
                        $scope.salesData.overhead_amt = response.result.reptypeInfo.overhead_amt;
                        $scope.salesData.RoutingNumber = response.result.reptypeInfo.RoutingNumber;
                        $scope.salesData.AccountNumber = response.result.reptypeInfo.AccountNumber;
                        $scope.salesData.business_unit_id = response.result.reptypeInfo.business_unit_id;
                        $scope.salesData.start_date = response.result.reptypeInfo.created_at ? moment(response.result.reptypeInfo.created_at).format('MM/DD/YYYY hh:mm:ss a') : '--/--/---';
                        $scope.salesData.commission_type = response.result.reptypeInfo.commission_type;

                        $scope.salesData.availableafter = response.result.availableafter;
                        $scope.salesData.time = response.result.time;
                        $scope.salesData.timeZone = response.result.timeZone;
                        $scope.salesData.dep_attorney = response.result.reptypeInfo.dep_attorney;

                        //dep_attorney default values 
                        $scope.salesData.dep_attorney.send_job_confirmation_sms = $scope.salesData.dep_attorney.send_job_confirmation_sms ?? "Y";
                        $scope.salesData.dep_attorney.send_job_confirmation = $scope.salesData.dep_attorney.send_job_confirmation ?? "Y";
                        
                    } else {
                        console.log("Not get any response")
                    }
                })
                .error(function(err) {
                    console.log(err);
                });
        }else{
            commonFactory.get('/api/reptype/create')
            .success(function(response) {
                if (response && response.result) {  
                    $scope.salesData.availableafter = response.result.availableafter;
                    $scope.salesData.time = response.result.time;
                    $scope.salesData.timeZone = response.result.timeZone;
                    $scope.salesData.dep_attorney = {
                        active : "Y",
                        availableafter :  Object.keys($scope.salesData.availableafter)[0],
                        time : Object.keys($scope.salesData.time)[0],
                        send_job_confirmation_sms : "Y",
                        send_job_confirmation : "Y",
                        can_see_ar : "Y",
                    };
                    $scope.salesData.dep_attorney.password_expiration_date = moment().add(180, 'days').format('MM-DD-YYYY');

                } else {
                    console.log("Not get any response")
                }
            })
            .error(function(err) {
                console.log(err);
            });
        }

        $scope.setPageTitle = function () {
            if($scope.salesData?.first_name){
                $state.$current.data.pageTitle = $scope.salesData.first_name + " " + $scope.salesData.last_name;
                $rootScope.$broadcast('changeTitle', $state);
            }
        };
        $scope.stepOneInit = function () {
            $scope.setPageTitle();
        };
        $scope.stepTwoInit = function () {
            $scope.setPageTitle();
        };
        $scope.stepThreeInit = function () {
            $scope.setPageTitle();
        };

        $scope.askPrivateTabPass = function(){
            
            if($rootScope.havePermission('allow_access_without_password','allow.resource.private.info')){
                $scope.setPageTitle();
                $scope.privateTabAuthenticated = true;
                $state.go("representative.sales.step_three", {id : $state.params.id});
                return;

            }else if($scope.privateTabAuthenticated){
                $state.go("representative.sales.step_three", {id : $state.params.id});
                return;
            } else{
                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                    // text: "Please enter password to unlock fields.",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                    // inputPlaceholder: "password"
                }, function(inputValue) {
                    if (inputValue === false){
                        $state.go("representative.sales.step_one", {id : $state.params.id});
                        return false;
                    }
                    if (inputValue === "") {
                        swal.showInputError("You need to write something!");
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                            .success(function(response) {
                                if (response['result']) {
                                    if (response['result']['auth']) {
                                        $state.go("representative.sales.step_three", {id : $state.params.id});
                                        $scope.privateTabAuthenticated = true;
                                        $rootScope.showLoader(false);
                                    } else {
                                        $rootScope.showLoader(false);
                                        $state.go("representative.sales.step_one", {id : $state.params.id});
                                        swal("Error !", "Wrong Password!", "error");
                                    }
                                } else {
                                    $rootScope.showLoader(false);
                                    swal("Oops !", "Something went wrong!", "error");
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                swal("Oops !", "Something went wrong!", "error");
                            });
                    }
                });
            }
        }

        $(window).bind('keydown', function(event) {

            if (event.ctrlKey || event.metaKey) {

                switch (String.fromCharCode(event.which).toLowerCase()) {

                    case 's':

                        event.preventDefault();

                        if($state.current.name == "representative.sales.step_one" && !$scope.salesForm.$invalid ){
                            $scope.goto_rate();
                        }
                        if($state.current.name == "representative.sales.step_two" && !$scope.salesForm.$invalid ){
                            $scope.save();
                        }
                        // $scope.invoiceGenerateForm.$setSubmitted();
                        $scope.$apply();
                        // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,false,false);
                        break;

                    case 'p':
                        event.preventDefault();

                        if($state.current.name == "representative.sales.step_two" && !$scope.salesForm.$invalid ){
                            $scope.goto_address();
                        }
                        // $scope.invoiceGenerateForm.$setSubmitted();
                        $scope.$apply();
                        // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,true,false);
                        break;
                }
            }
        });

    }]);

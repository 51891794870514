angular.module('deitz').controller('editSsoGroupsController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, fromModal, $uibModalInstance
    ) {

        $scope.sso_groups = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit SSO Groups';
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/sso-groups/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.sso_groups) {
                        $scope.sso_groups.sso_name = response.result.sso_groups.sso_name || '';
                        $scope.sso_groups.sso_url = response.result.sso_groups.sso_url || '';
                        $scope.sso_groups.sso_domain_name = response.result.sso_groups.sso_domain_name || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        $scope.addSsoGroups = function(sso_groups, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/sso-groups/' + id, $scope.sso_groups)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("SSO Group updated successfully");
                                $state.go("code_tables.sso_groups_browse");
                            }else if(response.status == 400){
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);

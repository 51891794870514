angular.module('deitz')
    .controller('browseCasesController',
        ['$scope',
            '$rootScope',
            '$state',
            'commonFactory',
            'Notification',
            'DTOptionsBuilder',
            'DTColumnBuilder',
            '$q',
            '$compile',
            '$uibModal',
            '$http',
            'apiUrl',
            '$window',
            function ($scope,
                $rootScope,
                $state,
                commonFactory,
                Notification,
                DTOptionsBuilder,
                DTColumnBuilder,
                $q,
                $compile,
                $uibModal,
                $http,
                apiUrl,
                $window
            ) {

                $scope.selectedFirmsIds = [];
                $scope.selectedAttorneysIds = [];
                $scope.selectedSchedulersIds = [];
                $scope.selectedWitnessesIds = [];
                $scope.firmSearching = false;
                $scope.searchFilter = {};
                $scope.filterObj = {};
                $scope.selectedBusinessUnitIDs = [];
                $scope.inv_age = "";
                $scope.inv_age_filter = "";

                /*Getting case groups */
                $http.get(apiUrl + '/api/case-group')
                    .success(function (response) {
                        if (response.result) {
                            $scope.caseGroups = response.result || '';
                        }
                    });

                $http.get(apiUrl + '/api/casetypes')
                    .success(function (response) {
                        if (response && response.result && response.result.casetype) {
                            $scope.casetypes = response.result.casetype || '';
                        }
                    });

                $http.get(apiUrl + '/api/vip_level')
                    .success(function (response) {
                        if (response.result) {
                            $scope.vipLevels = response.result || '';
                        }
                    });

                $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                    $scope.BusinessUnitsList = response.data.result.data;  
                });

                $scope.filterByJobDate = false;
                $scope.dateObj = {
                    jobDateRange: {
                        startDate : null,
                        endDate : null
                    }
                };

                $scope.isFilterApplied = false;

                //Getting Attorneys list
                $scope.attorneyslist = function (val) {
                    $scope.firmSearching = true;
                    return $http.get(apiUrl + '/api/attorneySearchByName', {
                        params: {
                            name: val
                        }
                    }).then(function (response) {
                        $scope.firmSearching = false;
                        return response.data.result.map(function (item) {
                            return item;
                        });
                    });
                };


                //Getting all resources by type.
                commonFactory.post('/api/resource-list-by-type', { rep_typ: 'S', fields: ['id', 'FIRST_NAME', 'LAST_NAME', 'REP_TYP', 'NAME_KEY', 'reptypeID', 'business_unit_id'] })
                    .success(function (response) {
                        if (response.error) {
                            Notification.error(response.result.message || 'Something went wrong!');
                        } else {
                            $scope.salesPersonsList = response.result.resources;
                        }
                        $rootScope.showLoader(false);
                    });


                $scope.dtInstance = {};
                $scope.compileDatatable = function (row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                };
                $scope.reloadData = function () {
                    var resetPaging = true;
                    $scope.dtInstance.rerender();
                };
                $scope.resetSearch = function () {
                    $scope.dtInstance.DataTable.state.clear();
                    $scope.reloadData();
                };

                $scope.initDateFilter = function(){
                    if (!$scope.dateObj.jobDateRange.startDate){
                        $scope.dateObj = {
                            jobDateRange: {
                                startDate : moment().format('MM-DD-YYYY'),
                                endDate : moment().format('MM-DD-YYYY')
                            }
                        };
                    }
                }

                $scope.searchData = function () {
                    jobDateRange ={};
                    if ($scope.dateObj.jobDateRange.startDate && $scope.dateObj.jobDateRange.endDate) {
                        jobDateRange = {
                            startDate: moment($scope.dateObj.jobDateRange.startDate).format('MM-DD-YYYY'),
                            endDate: moment($scope.dateObj.jobDateRange.endDate).format('MM-DD-YYYY')
                        }
                    }
                    $scope.searchFilter = {
                        caseName: $scope.caseName,
                        selectedCaseGroups: $scope.selectedCaseGroups,
                        caseNumber: $scope.caseNumber,
                        internalCaseNumber: $scope.internalCaseNumber,
                        selectedCaseTypes: $scope.selectedCaseTypes,
                        selectedCaseStatues: $scope.selectedCaseStatues,
                        selectedVipLevels: $scope.selectedVipLevels,
                        firmName: $scope.firmName,
                        attorneyName: $scope.attorneyName,
                        schedulerName: $scope.schedulerName,
                        witnessName: $scope.witnessName,
                        selectedSalesPersonsIds: $scope.selectedSalesPersonsIds,
                        filterByJobDate: $scope.filterByJobDate ? $scope.filterByJobDate : false,
                        jobDateRange: jobDateRange,
                        selectedBusinessUnitIDs:$scope.selectedBusinessUnitIDs
                    };

                    let data = $scope.searchFilter;

                    if ((data.selectedCaseGroups && data.selectedCaseGroups.length > 0) ||
                        (data.selectedCaseTypes && data.selectedCaseTypes.length > 0) ||
                        (data.selectedCaseStatues && data.selectedCaseStatues.length > 0) ||
                        (data.selectedSalesPersonsIds && data.selectedSalesPersonsIds.length > 0) ||
                        data.filterByJobDate ||
                        $scope.dateObj.jobDateRange.startDate ||
                        $scope.dateObj.jobDateRange.endDate ||
                        data.caseName ||
                        data.internalCaseNumber ||
                        data.caseNumber ||
                        data.firmName ||
                        data.attorneyName ||
                        data.schedulerName ||
                        (data.selectedBusinessUnitIDs && data.selectedBusinessUnitIDs.length > 0) ||
                        data.witnessName ||
                        (data.selectedVipLevels && data.selectedVipLevels.length > 0)) {
                        $scope.isFilterApplied = true;
                    } else {
                        $scope.isFilterApplied = false;
                        swal('Error!', 'Please use atleast one search criteria to filter the result.', 'error');
                    }
                    //localStorage.setItem('case_search_filter', JSON.stringify($scope.searchFilter));
                    setTimeout(function () {
                        $scope.reloadData();
                    }, 100);
                };
                
                $(document).on('click', '.minimalize-styl-2', function() {
                    $('#attfirm').DataTable().columns.adjust().responsive.recalc();
                });

                $scope.clear = function () {
                    $scope.caseName = "";
                    $scope.selectedCaseGroups = [];
                    $scope.caseNumber = "";
                    $scope.selectedCaseTypes = [];
                    $scope.selectedCaseStatues = [];
                    $scope.firmName = '',
                        $scope.attorneyName = '',
                        $scope.schedulerName = '',
                        $scope.witnessName = '',
                        $scope.selectedVipLevels = [];
                    $scope.selectedSalesPersonsIds = [];
                    $scope.internalCaseNumber = "";
                    $scope.filterByJobDate = false;
                    $scope.dateObj = {
                        jobDateRange: {
                            startDate : null,
                            endDate :null
                        }
                    };
                    $scope.selectedBusinessUnitIDs=[];
                    $scope.isFilterApplied = false;
                }

                $scope.getSearchFilter = function () {
                    var filter = JSON.parse(localStorage.getItem('case_search_filter'));
                    if (filter) {

                        $scope.caseName = filter.caseName;
                        $scope.internalCaseNumber = filter.internalCaseNumber;
                        $scope.selectedCaseGroups = filter.selectedCaseGroups;
                        $scope.caseNumber = filter.caseNumber;
                        $scope.selectedCaseTypes = filter.selectedCaseTypes;
                        $scope.selectedCaseStatues = filter.selectedCaseStatues;
                        $scope.firmName = filter.firmName,
                            $scope.attorneyName = filter.attorneyName,
                            $scope.schedulerName = filter.schedulerName,
                            $scope.witnessName = filter.witnessName,
                            $scope.selectedVipLevels = filter.selectedVipLevels;
                        $scope.selectedSalesPersonsIds = filter.selectedSalesPersonsIds;
                        $scope.selectedBusinessUnitIDs = filter.selectedBusinessUnitIDs;
                        $scope.filterByJobDate = filter.filterByJobDate;
                        if (filter.filterByJobDate) {
                            $scope.dateObj.jobDateRange = filter.jobDateRange;
                        };
                    }
                }
                //$scope.getSearchFilter();

                $scope.dtOptions = DTOptionsBuilder.newOptions()
                    .withDataProp('data')
                    .withOption('ajax', function (data, callback, settings) {
                        data.firmName = $scope.firmName,
                            data.attorneyName = $scope.attorneyName,
                            data.schedulerName = $scope.schedulerName,
                            data.witnessName = $scope.witnessName,
                            data.caseName = $scope.caseName;
                        data.selectedCaseGroups = $scope.selectedCaseGroups;
                        data.selectedCaseGroups = $scope.selectedCaseGroups;
                        data.caseNumber = $scope.caseNumber;
                        data.internalCaseNumber = $scope.internalCaseNumber;
                        data.selectedCaseTypes = $scope.selectedCaseTypes;
                        data.selectedCaseStatues = $scope.selectedCaseStatues;
                        data.selectedVipLevels = $scope.selectedVipLevels;
                        data.selectedSalesPersonsIds = $scope.selectedSalesPersonsIds;
                        data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs;
                        data.filterByJobDate = $scope.filterByJobDate ? $scope.filterByJobDate : false;
                        if ($scope.dateObj.jobDateRange.startDate && $scope.dateObj.jobDateRange.endDate) {
                            data.startDate = moment($scope.dateObj.jobDateRange.startDate).format('YYYY-MM-DD');
                            data.endDate = moment($scope.dateObj.jobDateRange.endDate).format('YYYY-MM-DD');
                        }
                        angular.forEach(data.columns, function (value, key) {
                            data.columns[key].search.value = data.columns[key].search.value.replace(/ /g, "%");
                        });
                        if ($scope.isFilterApplied) {
                            commonFactory.showDataTable('/api/case/show-datatable', data)
                                .success(function (res) {
                                    if (res) {
                                        $rootScope.showLoader(false);
                                        $scope.filterObj = data;
                                        callback(res);
                                        $('#attfirm').find('thead input').attr('autocomplete', 'off');
                                    }
                                })
                                .error(function (err) {
                                    $rootScope.showLoader(false);
                                });
                        }

                    })
                    .withLanguage({
                        "sEmptyTable": "NO CASES AVAILABLE IN TABLE",
                        "sProcessing": "<img src='img/loading_bar.gif'/>",
                        "sSearch": "",
                        "sZeroRecords": "NO MATCHING CASES FOUND",
                    })
                    .withOption('processing', true)
                    .withOption('serverSide', true)
                    .withOption('stateSave', false)
                    .withOption('scrollX', true)   
                    .withOption('scrollY', '100vh') 
                    .withOption('scrollCollapse',true) 
                    .withOption('autoWidth', true)    
                    .withOption('lengthMenu', [[10, 25, 50, 100, 250, 500], [10, 25, 50, 100, 250, 500]])
                    .withPaginationType('simple_numbers')
                    .withOption('searchDelay', 500)
                    .withOption('order', [1, 'asc'])
                    .withOption('createdRow', $scope.compileDatatable)
                    .withLightColumnFilter({

                        '0': {
                            type: 'text',
                            time: 600
                        },
                        '1': {
                            type: 'text',
                            time: 600
                        },
                        '2': {
                            type: 'text',
                            time: 600
                        },
                        '3': {
                            type: 'text',
                            time: 600
                        },
                        '4': {
                            type: 'select',
                            time: 600,
                            values: [{
                                value: 'active', label: "Active"
                            }, {
                                value: 'inactive', label: "Inactive"
                            }, {
                                value: 'unknown', label: "Unknown"
                            }
                            ]
                        },
                        '5': {
                            type: 'text',
                            time: 600
                        },
                        '6': {
                            type: 'text',
                            time: 600
                        },
                        '7': {
                            type: 'text',
                            time: 600
                        },

                    });



                $scope.dtColumns = [
                    DTColumnBuilder.newColumn('short_name').withOption('defaultContent', '').withTitle('Case Name').renderWith(function (data, type, full, meta) {
                        if (data) {
                            let html = '<a ui-sref="case.edit.step_one({id : ' + full.id + '})">' + data + '</a>';
                            return html;
                        }
                    }),
                    DTColumnBuilder.newColumn('IndexNumber').withOption('defaultContent', '').withTitle('Court Case Number').renderWith(function (data, type, full, meta) {
                        if (data) {
                            let html = '<a ui-sref="case.edit.step_one({id : ' + full.id + '})">' + data + '</a>';
                            return html;
                        }
                    }),
                    DTColumnBuilder.newColumn('jurisdiction.name').withOption('defaultContent', '').withTitle('Jurisdiction').withOption('name', 'jurisdiction.name'),
                    DTColumnBuilder.newColumn('case_type.name').withOption('defaultContent', '').withTitle('Case Type').withOption('name', 'case_type.name'),
                    DTColumnBuilder.newColumn('case_status').withOption('defaultContent', '').withTitle('Case Status'),
                    DTColumnBuilder.newColumn('selesmans').withOption('defaultContent', '').withTitle('Commissionable Rep <span class="small">(s)</span>')
                        .renderWith(function (data, type, full, meta) {
                            if (full.selesmans.length) {
                                var name = [];
                                angular.forEach(full.selesmans, function (value, key) {
                                    if (value.reptype && value.reptype.name) {
                                        name[key] = '<u>' + escapeString(value.reptype.name) + '</u>';
                                    }
                                });
                                return name.join('<br>');
                            }
                        }).withOption("width", "10%").withOption('name', 'selesmans.reptype.FIRST_NAME'),
                    DTColumnBuilder.newColumn('vip_level.name').withOption('defaultContent', '').withTitle('VIP Level').withOption('name', 'vip_level.name'),
                    DTColumnBuilder.newColumn('businessunit.name').withOption('defaultContent', '').withTitle('Business Unit').withOption('name', 'businessunit.name'),
                    DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
                        .renderWith(function (data, type, full, meta) {
                            return '<button ng-if="havePermission(\'case\',\'edit\')"  ui-sref="case.edit.step_one({id : ' + full.id + '})" class="btn btn-default btn-xs"><i style="color:#104A7B;" class="fa fa-eye"></i></button>' + 
                            '<a target="_blank" ng-if="havePermission(\'case\',\'edit\')"  ui-sref="diary.addcase.step_one({case_id: '+ full.id +'})" class="btn btn-primary">Add A Job To Calendar</a>'
                        })
                ];

            }]);

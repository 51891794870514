angular.module('deitz').controller('addOpsToOrderController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', '$http', 'apiUrl',
    function (
        $scope, $state, $rootScope, codeFactory, commonFactory, Notification, $http, apiUrl
    ) {
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Job Sheet Order Type';

        $scope.submitOpsToOrder = function (isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/ops-to-order', $scope.opsToOrder)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Job sheet order types added successfully");
                                $state.go("code_tables.ops_to_order_browse");
                            }
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

    }]);

angular.module('deitz').controller('emailLogsController', [
    '$scope', '$rootScope', 'DTOptionsBuilder', 'DTColumnBuilder', 'commonFactory', 'invoiceFactory','$compile','$state','$uibModal',
    function(
        $scope, $rootScope, DTOptionsBuilder, DTColumnBuilder, commonFactory, invoiceFactory, $compile, $state, $uibModal,
    ){

        $scope.dtInstance = {};
        $scope.mail_type = 1;
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };
        $scope.resetSearch = function(){  
            $scope.excludeZeroBalDue = null;
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        }; 

        $scope.selectMailType = function() {
            $scope.mail_type = $scope.mail_type 
            console.log('$scope.mail_type', $scope.mail_type);
            $scope.reloadData();
        };
        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }else if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }else{
                return '$'+ '0.00';
            }
        };

        $scope.downloadAttachment = function(attachmentPath, bucket, job_no) {
            console.log('AttachmentPath: ', attachmentPath);

            commonFactory.post('/api/email-logs-attachment', {
                attachmentPath: attachmentPath,
                bucket: bucket,
                mail_type: $scope.mail_type,
                job_no: job_no
            })
            .success(function(response) {
                if(!response.error){
                    window.open(response.result,'_blank');
                }else{
                    Notification.error(response.message);
                }
            })
        }
        $scope.viewEventLog = function(id){

            var viewEventModal = $uibModal.open({
                templateUrl: "modules/accountReceivableNotes/ar_notes_email_events.html",
                controller : function($scope, $uibModalInstance,$http,apiUrl){
                    $scope.closePopupWindow = function(){
                        $uibModalInstance.close();
                    }
                    $scope.getDateFormat= function(date){
                        var dte = moment(date).format('MM/DD/YYYY hh:mm:ss A');
                        if (dte == 'Invalid date') {
                            return '-';
                        } else {
                            return dte;
                        }
                    }
                    $scope.getEmailEvent = function(){
                        $rootScope.showLoader(true);
                        $http.get(apiUrl + '/api/get-email-events/'+id)
                            .then(function(response) {
                                $rootScope.showLoader(false);
                                if(!response.data.error){
                                    $scope.events=response.data.results.events;
                                }else{
                                    Notification.error(response.data.message);
                                }
                            });
                    }
                    $scope.getEmailEvent();
                },
                resolve: {
                    id: function () {
                        return id;
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });
        }

        $scope.mailTypeOption = [
            { id: 1, name: "Resource Notification - Job Cancelled" },
            { id: 2, name: "Resource Notification - Job Cancelled - No Coverage" },
            { id: 3, name: "Resource Notification - Job Cancelled - Declined Remote" },
            { id: 4, name: "Resource Notification - Job Cancelled - Declined Digital" },
            { id: 5, name: "Resource Notification - Job Bust - No Bill" },
            { id: 6, name: "Resource Notification - Job Late Cancellation - But - To Be Billed" },
            { id: 7, name: "Resource Notification - Job Confirmed" },
            { id: 8, name: "Resource Notification - Job Portal Entry" },
            { id: 9, name: "Resource Notification - Job Scheduled" },
            { id: 10, name: "Request For Confirmation - Job Pending Confirmation" },
            { id: 11, name: "Confirmation - Job Confirmed" },
            { id: 12, name: "Resource Assignment - Job Pending Confirmation and Select Resource from Popup" },
            { id: 13, name: "Resource Assignment - Job Calendar List Screen, Job Status Column, Link for Send Email" },
            { id: 14, name: "New Assignment Receipt - Job Pending Confirmation" },
            { id: 15, name: "New Assignment Receipt - Job Screen, Step 3, Button 'Save Link & Send Email'" },
            { id: 16, name: "Notice of Cancellation - Job Cancelled" },
            { id: 17, name: "Notice of Cancellation - Job Cancelled - No Coverage" },
            { id: 18, name: "Notice of Cancellation - Job Cancelled - Declined Remote" },
            { id: 19, name: "Notice of Cancellation - Job Cancelled - Declined Digital" },
            { id: 20, name: "Notice of Cancellation - Job Bust - No Bill" },
            { id: 21, name: "Notice of Cancellation - Job Late Cancellation - But - To Be Billed" },
            { id: 22, name: "Scheduling Acknowledgement - Job Portal Entry" },
            { id: 23, name: "Scheduling Acknowledgement - Job Schedule" },
            { id: 24, name: "Covering BU Email - Job Update Job" },
            { id: 25, name: "Covering BU Email - Job Update BU" },
            { id: 26, name: "Order Transcript - Job" },
            { id: 27, name: "Send Email Reminder - Job" },
            { id: 28, name: "Send us Email Reminder - Job" },
            { id: 29, name: "Send Transcript Email Reminder - Job" },
        ];

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('stateSave',true)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withOption('order', [[5, 'desc']])
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value,
                    excludeZeroBalDue : $scope.excludeZeroBalDue,
                    mail_type: $scope.mail_type
                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/email-logs', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            })
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                // { extend: 'copy' },
                // { extend: 'csv' },
                // { extend: 'excel', title: 'ClaimrepDetails' },
                // { extend: 'pdf', title: 'ClaimrepDetails' }, {
                //     extend: 'print',
                //     customize: function(win) {
                //         $(win.document.body).addClass('white-bg');
                //         $(win.document.body).css('font-size', '10px');
                //         $(win.document.body).find('table')
                //             .addClass('compact')
                //             .css('font-size', 'inherit');
                //     }
                // }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('job_no').withOption('defaultContent', '').withTitle('Job Number').renderWith(function (data, type, full, meta) {
                return data != 0 ? data : '-'; 
            }),,
            DTColumnBuilder.newColumn('subject').withOption('defaultContent', '').withTitle('Subject'),
            DTColumnBuilder.newColumn('created_by').withOption('defaultContent', '').withTitle('Created By').renderWith(function (data, type, full, meta) {
                return data && data.email ? data.email : '-'; 
            }),
            DTColumnBuilder.newColumn('received_by').withOption('defaultContent', '').withTitle('Received By').renderWith(function (data, type, full, meta) {
                if(full.emails.length !=0){
                    html='';
                    angular.forEach(full.emails, function(value, key){
                        html+='<span>'+value.type+' : '+value.received_by+'</span> ';
                        var textclass='warning';
                        if(value.type=='TO'){
                            if(value.last_event){
                                switch(value.last_event.event){
                                    case 'delivered':
                                    case 'transmitting_page':
                                        textclass='warning'
                                        break;
                                    case 'open':
                                    case 'fax_completed':
                                        textclass='success'
                                        break;
                                    case 'bounce':
                                        textclass='danger'
                                        break;
                                    case 'spamreport':
                                    case 'retry_scheduled':
                                        textclass='secondary'
                                        break;
                                    break;
                                    default:
                                        textclass='warning'
                                        break;
                                }
                                html+='<td><a class="text-'+textclass+'" href="javascript:void(0);" ng-click="viewEventLog('+value.id+')"><b><i class="fa fa-eye"></i></b></a><td>';
                            }

                        }
                        if(full.emails.length !==(key+1)){
                            html+='<hr style="margin:5px;border-top:1px solid #e7e7e7;">';
                        }
                    });
                    return html;    
                }
                else if(data != null){
                    return "<span style='display:block;max-width:100%;word-break:break-all;'>"+data+"</span>";
                }
                return "";
            }),
            // DTColumnBuilder.newColumn('type').withOption('defaultContent', '').withTitle('Email Send To'),
            DTColumnBuilder.newColumn('send_by_cron').withOption('defaultContent', '').withTitle('send By Cron').notSortable().renderWith(function (data, type, full, meta) {
                return data == 1 ? 'Yes' : 'No'; 
            }),
            DTColumnBuilder.newColumn('created_at').withOption('defaultContent', '').withTitle('Created At'),
            DTColumnBuilder.newColumn(null).withTitle('Attachment').notSortable()
            .renderWith(function(data, type, full, meta) {
                if (data && data.attachment_filepath) {
                    return `<button class="btn btn-info btn-circle" uib-tooltip="View Statements" ng-click="downloadAttachment('${data.attachment_filepath}', '${data.s3_bucket_name}', ${data.job_no})">` +
                        '<i class="fa fa-file"></i>' +
                        '</button> &nbsp;';
                }
                return '';
            })
        ];
    }]);